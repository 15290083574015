<div class="container-fluid">
    <div class="row">
      <div class="col-2 border border-secundary bg-white">
        <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                  <li class="nav-item mb-2 py-2">
                    <a href="/coor" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>
            
                    <!-- <li class="nav-item mb-2 py-2">
                      <a  href="/coor/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>
                    </li> -->
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li> -->
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/registro" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Registro
                      </a>
                    </li>

                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>
          
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li> -->
          
                    
                      <li class="border-top border-light d-block my-3 mx-0"></li>
                    
                </ul>
              </div>
          </nav>          
        </div>
      </div>
      <div class="col-10">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
            <div class="container">
              <div class="card-body rounded-3">
                <h3 class="text-start font-semibold" style="font-size: 1.3rem;">
                  Bienvenido, {{ content.user.fname }} {{ content.user.lname }}
                </h3>
                  <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
              </div>
            </div>
          </nav>
          <div class="row pt-3">
            <div class="col-md-8">
              <div class="row me-3">
                <div class="col">
                  <div class="row d-flex">
                    <div class="col-sm mb-sm-0">
                        <div class="">
                          <div class="card-title">
                            <span class="text-start font-semibold text-sm py-2 px-2">Dashboard - Panel de Control</span>
                          </div>
                          <div class="card-body mx-3 rounded-3">
                            <div class="d-flex justify-content-end p-2">
                              <button class="btn btn-light mx-2 border border-1">Funciones</button>
                              <button class="btn btn-light mx-2 border border-1">Funciones</button>
                              <button class="btn btn-light mx-2 border border-1">Funciones</button>
                            </div>
                            <div class="row gap-2">

                              <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/coor/evaluacion" style="background: #f48020f7;">
                                <p class="fw-semibold">Evaluaciones</p>
                                <span class="fw-bold fs-3">{{content.totalE}}</span>
                                <p  style="font-size: 0.6rem;font-family: sans-serif;">Resultado de Evaluaciones de Docentes & Coordinador</p>
                              </a>

                              <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/coor/registro" style="background: #f48020f7;">
                                <p class="fw-semibold">Registros</p>
                                <span class="fw-bold fs-3">{{content.totalR}}</span>
                                <p  style="font-size: 0.6rem;font-family: sans-serif;">Registro: Calificaciones Insertadas</p>
                              </a>

                              <a href="/coor/estudiantes" class="col btn btn-light rounded-2 p-3 text-dark text-start" style="background: #f48020f7;">
                                <p class="fw-semibold">Estudiantes</p>
                                <span class="fw-bold fs-3">{{content.totalEST}}</span>
                                <p  style="font-size: 0.6rem;font-family: sans-serif;">Agregar: Calificaciones & Registrar Estudiantes Nuevos</p>
                              </a>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div class="col-md-4">
              <div class="card">
                <div class="card-title">
                    <span class="text-start font-semibold text-sm py-2 px-2">Maestría</span>
                </div>
                  <div class="card-body rounded-3">
                    <div class="d-block mb-2">
                      <p class="text-center" style="font-size: 0.9rem;">{{content.JsonData.maestria}}</p>
                      <p class="text-center fw-bold" style="font-size: 0.9rem;">{{content.user.periodo}}</p>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row">      
              <div class="col-md-8" style="width: max-content;">
                <!-- <span class="text-start font-semibold text-sm py-2 px-2 pb-2">Asignaturas Activas</span> -->
                <div class="content py-0 my-0">
                          
                  <!--
                  <div class="card-body rounded-3">
                            <div class="row">
                              <div class="col">
                                <a class="card rounded-4" href="#" style="width: 16rem;">
                                  <div class="text-center rounded-3" style="background-color: #868e96;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="180" fill="#fff" class="bi bi-image-fill" viewBox="0 0 16 16">
                                      <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                                    </svg>
                                  </div>
                                  <div class="card-body">
                                    <h5 class="card-title">Dirección Estratégica y Gestión de la Innovación</h5>
                                    <p class="card-text">Giselle Duverge</p>
                                    <div class="progress">
                                      <div class="progress-bar" role="progressbar" style="width: 15%; background: #F47B20;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="50"></div>
                                    </div>
                                    <div class="row">
                                      <div class="d-flex">
                                        <p class="card-text">35/50 asignaturas</p>
                                        <div class="col 2 d-flex justify-content-end">
                                          <p style="color: #F47B20;">65%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              
                            </div>
                            
                          </div>

                        -->
                  </div>
              </div>
          </div>
          </div>
      </div>
    </div>
  </div>