import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




import { authInterceptorProviders,RoleInterceptor,AuthInterceptor } from './_helpers/auth.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
// import { AdminBoardModule } from './components/admin-board/admin-board.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button'
import {MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';




import {MatCommonModule} from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlanEstudiosComponent } from './components/plan-estudios/plan-estudios.component';
import { LoginComponent } from './components/login/login.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { RegisterComponent } from './components/register/register.component';

import { IndexComponent } from './components/index/index.component';
import { AdmisionesComponent } from './components/admisiones/admisiones.component';
import { ProestadisticoComponent } from './components/proestadistico/proestadistico.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ComprobanteComponent } from './components/comprobante/comprobante.component';
import { AdsenseModule } from 'ng2-adsense';
import { OfertasComponent } from './components/ofertas/ofertas.component';
import { AltaGerenciaComponent } from './maestrias/alta-gerencia/alta-gerencia.component';
import { MaestriaAdministracionEmpresasComponent } from './maestrias/maestria-administracion-empresas/maestria-administracion-empresas.component';
import { MaestriaDireccionEstrategicaComponent } from './maestrias/maestria-direccion-estrategica/maestria-direccion-estrategica.component';
import { GerenciaFinancieraComponent } from './maestrias/gerencia-financiera/gerencia-financiera.component';
import { RecursosHumanosComponent } from './maestrias/recursos-humanos/recursos-humanos.component';
import { GestionPublicaComponent } from './maestrias/gestion-publica/gestion-publica.component';
import { SegurosFianzasComponent } from './maestrias/seguros-fianzas/seguros-fianzas.component';
import { GestionLogisticaComponent } from './maestrias/gestion-logistica/gestion-logistica.component';
import { FinanzasMercadoComponent } from './maestrias/finanzas-mercado/finanzas-mercado.component';
import { NegociosRelacionesEconomicasComponent } from './maestrias/negocios-relaciones-economicas/negocios-relaciones-economicas.component';
import { MasterGlobalizacionComponent } from './maestrias/master-globalizacion/master-globalizacion.component';
import { AuditoriaInternaComponent } from './maestrias/auditoria-interna/auditoria-interna.component';
import { ContabilidadGubernamentalComponent } from './maestrias/contabilidad-gubernamental/contabilidad-gubernamental.component';
import { ContabilidadTributariaComponent } from './maestrias/contabilidad-tributaria/contabilidad-tributaria.component';
import { MarketingEstrategicoComponent } from './maestrias/marketing-estrategico/marketing-estrategico.component';
import { EstrategiaCibermarketingComponent } from './maestrias/estrategia-cibermarketing/estrategia-cibermarketing.component';
import { SociologiaEducacionComponent } from './maestrias/sociologia-educacion/sociologia-educacion.component';
import { TrabajoSocialComponent } from './maestrias/trabajo-social/trabajo-social.component';
import { EstadisticaEspecializadaComponent } from './maestrias/estadistica-especializada/estadistica-especializada.component';
import { AdministracionProyectosComponent } from './maestrias/administracion-proyectos/administracion-proyectos.component';
import { DesarrolloLiderazgoComponent } from './maestrias/desarrollo-liderazgo/desarrollo-liderazgo.component';
import { GestionAnalisisEstadisticasComponent } from './maestrias/gestion-analisis-estadisticas/gestion-analisis-estadisticas.component';
import { SociologiaUrbanaComponent } from './maestrias/sociologia-urbana/sociologia-urbana.component';



import { AudiBoardComponent } from './auditorias/audi-board/audi-board.component';
import { EmpalmeComponent } from './auditorias/empalme/empalme.component';
import { ProveedorComponent } from './auditorias/proveedor/proveedor.component';
import { GestionFinanzasCoorporativaComponent } from './maestrias/gestion-finanzas-coorporativa/gestion-finanzas-coorporativa.component';
import { RelacionDeLosDestinosComponent } from './maestrias/relacion-de-los-destinos/relacion-de-los-destinos.component';
import { MenubarComponent } from './components/menubar/menubar.component';

import { EquipoComponent } from './administrador/equipo/equipo.component';
import { EvaluationComponent } from './administrador/evaluation/evaluation.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProgramaComponent } from './administrador/programa/programa.component';

import { ResultAdmisionComponent } from './administrador/result-admision/result-admision.component';
import { ResultServiceComponent } from './administrador/result-service/result-service.component';
import { ResultStudentsComponent } from './administrador/result-students/result-students.component';
import { ResultNotificationComponent } from './administrador/result-notification/result-notification.component';
import { ResultCoorComponent } from './administrador/result-coor/result-coor.component';
import { InscripcionesComponent } from './administrador/inscripciones/inscripciones.component';

import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { PublicacionComponent } from './components/publicacion/publicacion.component';
import { EducacionContinuadaComponent } from './components/educacion-continuada/educacion-continuada.component';
import { ModalContentComponent } from './usuarios/user-evaluacion/ModalContentComponent.component';
import { ModalContentMesyt } from './dashboard/user-board/ModalContentMesyt.component';

import { InsertStudentModal } from './administrador/result-students/insertStudentModal';
import { InsertCoorModal } from './administrador/result-coor/insertCoorModal';
import { ViewStudentModal } from './administrador/result-students/viewStudentModal';
import { ViewCoorStudentModal } from './coordinador/coor-students/viewCoorStudentModal';

// import { InsertRecordStudentModal } from './administrador/result-students/insertRecordStudentModal';
// import { ResultAdmisionModal } from './administrador/result-admision/resultAdmisionModal';
import { DialogAdmision} from './administrador/result-admision/dialogAdmision';
import { ResultRecordComponent } from './administrador/result-record/result-record.component';
import { CoorStudentsComponent } from './coordinador/coor-students/coor-students.component';
import { CoorEvalComponent } from './coordinador/coor-eval/coor-eval.component';
import { ViewEvalModal } from './coordinador/coor-eval/viewEvalModal';

import {ViewInscripcionModal} from './administrador/inscripciones/ViewInscriptionModal'
// import { PdfEvalModal } from './coordinador/coor-eval/pdfEvalModal';
//  import { CoorRegitroComponent } from './coordinador/coor-registro/coor-registro.component';

@NgModule({
  declarations: [
    AppComponent,
    PlanEstudiosComponent,
    RegisterComponent,
    LoginComponent,
    PagenotfoundComponent,
    EvaluationComponent,
    IndexComponent,
    AdmisionesComponent,
    ProestadisticoComponent,
    ServiciosComponent,
    ComprobanteComponent,
    OfertasComponent,
    AltaGerenciaComponent,
    MaestriaAdministracionEmpresasComponent,
    MaestriaDireccionEstrategicaComponent,
    GerenciaFinancieraComponent,
    RecursosHumanosComponent,
    GestionPublicaComponent,
    SegurosFianzasComponent,
    GestionLogisticaComponent,
    FinanzasMercadoComponent,
    NegociosRelacionesEconomicasComponent,
    MasterGlobalizacionComponent,
    AuditoriaInternaComponent,
    ContabilidadGubernamentalComponent,
    ContabilidadTributariaComponent,
    MarketingEstrategicoComponent,
    EstrategiaCibermarketingComponent,
    SociologiaEducacionComponent,
    TrabajoSocialComponent,
    EstadisticaEspecializadaComponent,
    AudiBoardComponent,
    EmpalmeComponent,
    ProveedorComponent,
    GestionFinanzasCoorporativaComponent,
    RelacionDeLosDestinosComponent,
    AdministracionProyectosComponent,
    DesarrolloLiderazgoComponent,
    GestionAnalisisEstadisticasComponent,
    SociologiaUrbanaComponent,
    MenubarComponent,
    EquipoComponent,
    LogoutComponent,
    ProgramaComponent,
    ResultAdmisionComponent,
    ResultServiceComponent,
    ResultStudentsComponent,
    ResultNotificationComponent,
    ResultCoorComponent,
    InscripcionesComponent,
    EstudiantesComponent,
    PublicacionComponent,
    EducacionContinuadaComponent,
    ModalContentComponent,
    ModalContentMesyt,
    InsertStudentModal,
    InsertCoorModal,
    ViewStudentModal,
    ViewCoorStudentModal,    
    DialogAdmision,
    ResultRecordComponent,
    CoorStudentsComponent,
    CoorEvalComponent,
    ViewEvalModal,
    ViewInscripcionModal   


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatCommonModule,
    MatIconModule,
    MatMenuModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatRadioModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    CommonModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    MatCardModule
    /* AdsenseModule.forRoot({
       adClient: 'ca-pub-7430440888225076',
       adSlot: 3730595512,
     })*/
  ],
  providers: [authInterceptorProviders,RoleInterceptor,AuthInterceptor],
  bootstrap: [AppComponent]
})
export class AppModule { }
