import { Component } from '@angular/core';

@Component({
  selector: 'app-marketing-estrategico',
  templateUrl: './marketing-estrategico.component.html',
  styleUrls: ['./marketing-estrategico.component.css']
})
export class MarketingEstrategicoComponent {

}
