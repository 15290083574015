<div class="container-fluid" >
  <h2 style="text-align: center"><strong>Oferta de Grado</strong></h2>
  <p class="small" style="text-align: center; font-size: 0.65rem;">UNIDAD DE POSTGRADO</p>
<div class="row my-3">
    <div class="col-md-4 mt-lg-0  ps-lg-4">
      <div class="d-block mx-auto rounded-3 border-0">
        <div class="d-flex-fill">
          <ul class="list-group; list-unstyled">
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ADMP&plan=000013&nivel=GR"  class="btn btn-primary rounded-1 text-white fw-lighter fs-6 text-white fw-lighter fs-6">Licenciatura en Administración Pública</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ADME&plan=000013&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6 text-white fw-lighter fs-6">Licenciatura en Administración de Empresas</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-CONT&plan=000012&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6 text-white fw-lighter fs-6">Licenciatura en Contabilidad</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ADCE&plan=000014&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Aduanas y Comercio Exterior</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ECON&plan=000013&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Economía</a>
            </li>
            <li class="list-group-item; border-0">
              <a href=" https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ESTI&plan=000012&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Estadística Mención Informática</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-ESTS&plan=000012&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Estadística Mención Socioeconómica</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-MERC&plan=000013&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Mercadotecnia</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-SOCI&plan=000013&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Sociología</a>
            </li>
            <li class="list-group-item; border-0">
              <a href=" https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-TRAS&plan=000014&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Trabajo Social</a>
            </li>
            <li class="list-group-item; border-0">
              <a href="https://soft.uasd.edu.do/PensumGrado/?periodoV=999999&programa=P-TURH&plan=201810&nivel=GR" class="btn btn-primary rounded-1 text-white fw-lighter fs-6">Licenciatura en Turismo y Hotelería</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-8">
  <div class="row d-flex">
    <div class="col-md bg-hide-col px-0">  
      <div class="bg-image"  style="background-image: url('https://postgradofces.com/assets/img/ofertadegrado.png');height: 100vh;background-size: cover; background-repeat:no-repeat; background-position: right;">
      </div>                 
    </div>
  </div>
  </div>
  </div>
</div>