import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CoorGuard implements CanActivate {

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {}

  canActivate(): boolean {
    const userRoles = this.tokenStorageService.getUserRoles();
    const user  = this.tokenStorageService.getUser();
    const role = user.roles;

    
      if (role.includes('COORDINADOR')) {
        return true; 
    } else {
        this.router.navigate(['/access-denied']); 
        return false;
    }
  }
}