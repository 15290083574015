import { Component } from '@angular/core';

@Component({
  selector: 'app-desarrollo-liderazgo',
  templateUrl: './desarrollo-liderazgo.component.html',
  styleUrl: './desarrollo-liderazgo.component.css'
})
export class DesarrolloLiderazgoComponent {

}
