<div class="container-fluid">
  <div class="row">
    <div class="col-2 border border-secundary bg-white">
      <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">              
                  <li class="nav-item mb-2 py-2">
                    <a href="/admin" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>              
                    <li class="nav-item mb-2 py-2">
                      <a  href="/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>                      
                    </li>                      
                    <li class="nav-item mb-2 py-2">
                      <a href="/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li>            
                    <li class="nav-item mb-2 py-2">
                      <a href="/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>            
                    <li class="nav-item mb-2 py-2">
                      <a href="/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li>            
                    <li class="nav-item mb-2 py-2">
                      <a href="/configuracion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                        </svg> Configuracion
                      </a>
                    </li>                      
                    <li class="border-top border-light d-block my-3 mx-0"></li>                    
                      <li class="nav-item mb-2 py-2">
                        <a href="/ayuda" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                          </svg> Ayuda
                        </a>
                      </li>
                      <li class="nav-item mb-2 py-2">
                        <a href="/logout" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                          </svg> Salir
                        </a>
                      </li>
                      <li class="border-top border-light d-block my-3 mx-0"></li>                      
                </ul>
              </div>
          </nav>            
      </div>
    </div>
    <div class="col-10">
      <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
          <div class="container">
            <div class="card-body rounded-3">
              <h3 class="text-start font-semibold" style="font-size: 1.3rem;">
                Bienvenido, {{ content.user.fname }} {{ content.user.lname }}
              </h3>
                <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
            </div>
          </div>
        </nav>
        <div class="row pt-3">
          <div class="col-md-8">
            <div class="row me-3">
              <div class="col">
                <div class="row d-flex">
                  <div class="col-sm mb-sm-0">
                      <div class="">
                        <div class="card-title">
                          <span class="text-start font-semibold text-sm py-2 px-2">Dashboard - Panel de Control</span>
                        </div>
                        <div class="card-body mx-3 rounded-3">
                          <div class="d-flex justify-content-end p-2">
                            <button class="btn btn-light mx-2 border border-1">Maestrías</button>
                            <button class="btn btn-light mx-2 border border-1">Asignaturas</button>
                            <button class="btn btn-light mx-2 border border-1">Diplomados</button>
                          </div>
                          <div class="row gap-2">
                            <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/admin/resultscoor" style="background: #f48020f7;">
                              <p class="fw-semibold">Coordinadores</p>
                              <span class="fw-bold fs-3">{{content.totalC}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Lista de Coordinadores</p>
                            </a>
                            <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/admin/resultsrecord" style="background: #f48020f7;">
                              <p class="fw-semibold">Record de Notas</p>
                              <span class="fw-bold fs-3">{{content.totalR}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Descarga & Imprime las Calificaciones de Estudiantes</p>
                            </a>
                            <a href="/admin/resultsadmision" class="col btn btn-light rounded-2 p-3 text-dark text-start" style="background: #f48020f7;">
                              <p class="fw-semibold">Admisiones</p>
                              <span class="fw-bold fs-3">{{content.totalA}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Solicitud de Admisiones</p>
                            </a>
                          </div>
                          <div class="row gap-2 py-2">
                            <a  class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/evaluacion" style="background: #f48020f7;">
                              <p class="fw-semibold">Evaluaciones</p>
                              <span class="fw-bold fs-3">{{content.totalE}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Registro de Evaluación Docente & Coordinadores</p>
                            </a>
                            <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/admin/resultstudents" style="background: #f48020f7;">
                              <p class="fw-semibold">Estudiantes</p>
                              <span class="fw-bold fs-3">{{content.totalEST}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Lista de Estudiantes</p>
                            </a>
                            <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/admin/resultsservice" style="background: #f48020f7;">
                              <p class="fw-semibold">Servicios</p>
                              <span class="fw-bold fs-3">{{content.totalS}}</span>
                              <p style="font-size: 0.6rem; font-family: sans-serif;">Solicitudes de Servicios PostGrado</p>
                            </a>
                          </div>
                          <div class="row gap-2 py-2">
                            <a class="col btn btn-light rounded-2 p-3 text-dark text-start" href="/admin/resultinscripcion" style="background: #f48020f7;">
                              <p class="fw-semibold">Inscripciones</p>
                              <span class="fw-bold fs-3">{{content.totalI}}</span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;">Nuevos Estudiantes Inscriptos</p>
                            </a>
                            <a class="col  rounded-2 p-3 text-dark text-start" style="background: #f9f9f9;">
                              <p class="fw-semibold"></p>
                              <span class="fw-bold fs-3"></span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;"></p>
                            </a>
                            <a class="col  rounded-2 p-3 text-dark text-start" style="background: #f9f9f9;">
                              <p class="fw-semibold"></p>
                              <span class="fw-bold fs-3"></span>
                              <p  style="font-size: 0.6rem;font-family: sans-serif;"></p>
                            </a>
                          </div>      
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div class="col-md-4">
            <div class="card">
              <div class="card-title">
                  <span class="text-start font-semibold text-sm py-2 px-2"></span>
              </div>
                <div class="card-body rounded-3">
                  
                    <div class="d-block mb-2">
                      <p class="text-center" style="font-size: 0.9rem;">{{content.user.nivel}}</p>
                      <p class="text-center fw-bold" style="font-size: 0.9rem;">{{content.user.campus}}</p>
                      <p class="text-center fw-bold" style="font-size: 0.9rem;">{{content.user.dept}}</p>
                      <p class="text-center fw-bold" style="font-size: 0.9rem;">Escuela {{content.user.escuela}}</p>
                    </div>
                  
                    <!-- <div class="input-group gap-2 mb-3 border-0 bg-light rounded-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-folder2" viewBox="0 0 16 16">
                        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5z"/>
                      </svg>
                      <select class="form-select border-0 bg-light text-dark">
                        <option selected disabled>Seleccionar Maestría</option>
                        <optgroup label="Escuela de Administración">
                          <option value="Administración de Empresas Cooperativas">Maestría en Administración de Empresas Cooperativas</option>
                          <option value="Alta Gerencia">Maestría en Alta Gerencia</option>
                          <option value="Dirección Estratégica y Gestión de la Innovación">Maestría en Dirección Estratégica y Gestión de la Innovación</option>
                          <option value="Gerencia Financiera">Maestría en Gerencia Financiera</option>
                          <option value="Gestion de Recursos Humanos">Maestría en Gestión de Recursos Humanos</option>
                          <option value="Gestión Pública">Maestría en Gestión Pública</option>
                          <option value="Seguros y Finanzas">Maestría en Seguros y Fianzas</option>
                          <option value="Gestión Logística de las Aduanas y Puertos">Maestría en Gestión Logística de las Aduanas y Puertos</option>
                          
                        </optgroup>
        
                        <optgroup label="Escuela de Economía">
                          <option value="Finanzas y Mercado de Capitales">Maestría en Finanzas y Mercado de Capitales</option>
                          <option value="Negocios y Relaciones Económicas Internacionales">Maestría en Negocios y Relaciones Económicas Internacionales</option>
                          <option value="Master Globalización">Maestría en Master Globalización</option>
                        </optgroup>
        
                        <optgroup label="Escuela de Contabilidad">
                          <option value="Auditoria Interna">Maestría en Auditoria Interna</option>
                          <option value="Contabilidad Gubernamental">Maestría en Contabilidad Gubernamental</option>
                          <option value="Contabilidad Tributaria">Maestría en Contabilidad Tributaria</option>
                        </optgroup>
        
                        <optgroup label="Escuela de Mercadotecnia">
                          <option value="Marketing Estratégico">Maestría en Marketing Estratégico</option>
                          <option value="Estrategias de CirbeMarketing">Maestría en Estrategias de CiberMarketing</option>
                        </optgroup>
        
                        <optgroup label="Escuela de Sociología y Trabajo Social">
                          <option value="Sociología de la Educación">Maestría en Sociología de la Educación</option>
                          <option value="Trabajo Social">Maestría en Trabajo Social</option>
                        </optgroup>
        
                        <optgroup label="Escuela de Estadística">
                          <option value="Estadística Especializada">Maestría en Estadística Especializada</option>
                        </optgroup>
                      </select>
                      </div>
                      <div class="input-group gap-2 mb-3 border-0 bg-light rounded-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                      </svg>
                      <select class="form-select border-0 bg-light text-dark">
                        <option selected>Seleccionar Coordinador</option>
                        <option value="Patria Fernandez">Patria Fernandez</option>
                        <option value="Mariel García">Mariel García</option>
                        <option value="María de Lourdes Sanchez">María de Lourdes Sanchez</option>
                        <option value="Cesar Díaz">Cesar Díaz</option>
                        <option value="Amin Martinez">Amin Martinez</option>
                        <option value="Carmen Luisa Santana">Carmen Luisa Santana</option>
                        <option value="Nancy Alonso">Nancy Alonso</option>
                        <option value="Giselle Duverge">Giselle Duverge</option>
                        <option value="Jose Crespo">Jose Crespo</option>
                        <option value="Joaquin de la Rosa">Joaquin de la Rosa</option>
                        <option value="Karina Abad">Karina Abad</option>
                        <option value="Aurea Pelletier">Aurea Pelletier</option>
                        <option value="Nancy Sanchez">Nancy Sanchez</option>
                        <option value="Jossira Santana Cedano">Jossira Santana Cedano</option>
                        <option value="Manuel Mercedes">Manuel Mercedes</option>
                        <option value="Giovanni Matos Subervi">Giovanni Matos Subervi</option>
                        <option value="Guarionaex Gomez">Guarionaex Gomez</option>
                        <option value="Marianela Fernandez">Marianela Fernandez</option>
                        <option value="Wagner Gomera">Wagner Gomera</option>
                        <option value="Xiomara Rodriguez">Xiomara Rodriguez</option>
                        <option value="Danilo Montero">Danilo Montero</option>
                        <option value="Sorivel Cristina Perez B.">Sorivel Cristina Perez B.</option>
                        <option value="Ydania Figuereo">Ydania Figuereo</option>
                        <option value="Leonte Castillo">Leonte Castillo</option>
                        <option value="Andry Garcia">Andry Garcia</option>
                      </select>
                      </div>
                      <div class="input-group gap-2 mb-3 border-0 bg-light rounded-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                      </svg>
                      <select class="form-select border-0 bg-light text-dark">
                        <option selected>Periodo</option>
                        <option value="1">2020-2024</option>
                      </select>
                    </div>
                    <div class="input-group gap-2 mb-3 border-0 ">
                      <button class="btn btn-primary" type="button">Buscar</button>
                    </div> -->
                  
                </div>
            </div>
          </div>
          <!-- <div class="row">      
            <div class="col-md-8" style="width: max-content;">
              <span class="text-start font-semibold text-sm py-2 px-2 pb-2">Maestrías Activas</span>
              <div class="content py-0 my-0">
                        <div class="card-body rounded-3">
                          <div class="row">
                            <div class="col">
                              <a class="card rounded-4" href="#" style="width: 16rem;">
                                <div class="text-center rounded-3" style="background-color: #868e96;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="180" fill="#fff" class="bi bi-image-fill" viewBox="0 0 16 16">
                                    <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                                  </svg>
                                </div>
                                <div class="card-body">
                                  <h5 class="card-title">Dirección Estratégica y Gestión de la Innovación</h5>
                                  <p class="card-text">Giselle Duverge</p>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 15%; background: #F47B20;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="50"></div>
                                  </div>
                                  <div class="row">
                                    <div class="d-flex">
                                      <p class="card-text">35/50 asignaturas</p>
                                      <div class="col 2 d-flex justify-content-end">
                                        <p style="color: #F47B20;">65%</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            
                          </div>
                          
                        </div>
                </div>
            </div>
        </div> -->
        </div>
    </div>
  </div>
</div>

<ng-template #modalContent>
<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">No autorizado</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Debes iniciar sesión para acceder a esta página.</p>
  </div>
</div>
</ng-template>
