<div class="container-fluid">
  <div class="row">
    <div class="col-2 border border-secundary bg-white">
      <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                  <li class="nav-item mb-2 py-2">
                    <a href="/admin" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>
            
                    <li class="nav-item mb-2 py-2">
                      <a  href="/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>
                    </li>
                    <li class="nav-item mb-2 py-2">
                      <a href="/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li>
          
                    <li class="nav-item mb-2 py-2">
                      <a href="/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>
          
                    <li class="nav-item mb-2 py-2">
                      <a href="/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li>
          
                    <li class="nav-item mb-2 py-2">
                      <a href="/configuracion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                        </svg> Configuracion
                      </a>
                    </li>
                    
                    <li class="border-top border-light d-block my-3 mx-0"></li>
                  
                      <li class="nav-item mb-2 py-2">
                        <a href="/ayuda" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                          </svg> Ayuda
                        </a>
                      </li>
                      <li class="nav-item mb-2 py-2">
                        <a href="/logout" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                          </svg> Salir
                        </a>
                      </li>
                      <li class="border-top border-light d-block my-3 mx-0"></li>
                    
                </ul>
              </div>
          </nav>
      </div>
    </div>
    <div class="col-10">
      <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
          <div class="container">
            <div class="card-body rounded-3">
              <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ fuser }} {{ luser }}</h3>
              <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
            </div>
          </div>
      </nav>
        <div class="card border-0">
          <div class="card-body">
              
              <div class="row">
                  <div class="col-md-2"> 
                      <span class="fs-4 text-dark-text-start fw-bold">Admisiones</span>
                  </div>
<!--                  
                  <div class="col"> 
                      <div class="d-flex gap-4">
                        <form action="">
                          <div class="d-flex align-items-center gap-2">
                            <input type="text" class="border  rounded-3 h-50 bg-light text-dark" placeholder="Buscar por nombre">
                            <button class="btn btn-sm  mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;"><i class="bi bi-search"></i>&nbsp;Buscar</button>
                          </div>
                        </form>
                        <form (click)="getByIdAdmisionService()">
                        <div class="d-flex align-items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000" class="bi bi-folder2" viewBox="0 0 16 16">
                            <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5z"/>
                          </svg>
                        
                        <select name="id" class="form-select border-0 bg-light text-dark border border-1 rounded-2 h-50 " aria-label="Seleccionar Maestría" [(ngModel)]="admision.programa">
                            <option selected value="" disabled>Seleccionar Maestría</option>
                            <optgroup label="Escuela de Administración">
                              <option value="Administración de Empresas Cooperativas">Maestría en Administración de Empresas Cooperativas</option>
                              <option value="Alta Gerencia">Maestría en Alta Gerencia</option>
                              <option value="Dirección Estratégica y Gestión de la Innovación">Maestría en Dirección Estratégica y Gestión de la Innovación</option>
                              <option value="Gerencia Financiera">Maestría en Gerencia Financiera</option>
                              <option value="Gestion de Recursos Humanos">Maestría en Gestión de Recursos Humanos</option>
                              <option value="Gestión Pública">Maestría en Gestión Pública</option>
                              <option value="Seguros y Fianzas">Maestría en Seguros y Fianzas</option>
                              <option value="Gestión Logística de las Aduanas y Puertos">Maestría en Gestión Logística de las Aduanas y Puertos</option>
                              <option value="Gestión en Finanzas Coorporativas">Maestría Gestión en Finanzas Coorporativas</option>
                            </optgroup>
          
                            <optgroup label="Escuela de Economía">
                              <option value="Finanzas y Mercado de Capitales">Maestría en Finanzas y Mercado de Capitales</option>
                              <option value="Negocios y Relaciones Económicas Internacionales">Maestría en Negocios y Relaciones Económicas Internacionales</option>
                              <option value="Master Globalización">Maestría en Master Globalización</option>
                            </optgroup>
          
                            <optgroup label="Escuela de Contabilidad">
                              <option value="Auditoria Interna">Maestría en Auditoria Interna</option>
                              <option value="Contabilidad Gubernamental">Maestría en Contabilidad Gubernamental</option>
                              <option value="Contabilidad Tributaria">Maestría en Contabilidad Tributaria</option>
                            </optgroup>
          
                            <optgroup label="Escuela de Mercadotecnia">
                              <option value="Marketing Estratégico">Maestría en Marketing Estratégico</option>
                              <option value="Estrategias de CirbeMarketing">Maestría en Estrategias de CiberMarketing</option>
                            </optgroup>
          
                            <optgroup label="Escuela de Sociología y Trabajo Social">
                              <option value="Sociología de la Educación">Maestría en Sociología de la Educación</option>
                              <option value="Trabajo Social">Maestría en Trabajo Social</option>
                            </optgroup>
          
                            <optgroup label="Escuela de Estadística">
                              <option value="Estadística Especializada">Maestría en Estadística Especializada</option>
                            </optgroup>
                          </select>
                           -->
                          <!-- <button  (click)="getByIdAdmisionService()" class="btn btn-sm h-50 w-50 mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;">Buscar</button> -->
                          <!-- <button class="btn btn-sm  mb-3 w-50 rounded-3" style="background-color: #f8ece3;color:#f47b20;" onclick="refreshPage(true);"><i class="bi bi-arrow-clockwise"></i>&nbsp;Refrescar</button>
                        </div>
                        </form>
                        
                      </div>
                  </div> -->

              </div>


              <div class="row mt-2 rounded-3">
                  <div class="col">

                    <nav>
                      <div class="nav nav-tabs gap-2" id="nav-tab" role="tablist">
                          <button class="py-4 rounded-top border-0 active" style="background-color: #f48020f7;" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true"><a class="px-5 py-3 text-dark">Todos ({{content.totalA}})</a></button>
                           <button class="py-4 rounded-top border-0" style="background-color: #f48020f7;" id="nav-nuevo-tab" data-bs-toggle="tab" data-bs-target="#nav-nuevo" type="button" role="tab" aria-controls="nav-nuevo" aria-selected="true"><a class="px-5 py-3 text-dark">Nuevos ({{content.dataNew}})</a></button>
                          <button class="py-4 rounded-top border-0" style="background-color: #f48020f7;" id="nav-recibido-tab" data-bs-toggle="tab" data-bs-target="#nav-recibido" type="button" role="tab" aria-controls="nav-recibido" aria-selected="true"><a class="px-5 py-3 text-dark">Recibidos ({{content.dataRecived}})</a></button>
                      </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContentAdmision">
                    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabindex="0">
                      <div class="row border border-light rounded-3 mt-2">
                        <div class="col rem-7">
                          
                          <div class="d-flex table-responsive" *ngIf="content && content.dataA"  style="font-family: sans-serif; font-size: 0.6rem;">
                            <table class="table table-bordered table-striped table-hover">
                              <thead class="bg-light" style="background-color: #f8fefe;border-radius: 10px;">
                              <tr>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">#</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Estado</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Nombre</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Tel.</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Programa</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Horario</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Fecha</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Controles</p></th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr class="border border-light" *ngFor="let item of pagedData; let i = index">
                                <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">
                                  <div *ngIf="item.recibido;else falseCase">
                                    <span class="badge rounded-pill bg-danger mt-0" style="font-size: 0.6rem;">recibido</span>
                                  </div>
                                  <ng-template #falseCase>
                                    <span class="badge rounded-pill bg-success mt-0" style="font-size: 0.6rem;">nuevo</span>
                                  </ng-template>
                                </td>
                                <th scope="row">
                                  <p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fname }} {{ item.lname }}</p>
                                </th>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.cel }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.programa }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.tanda }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fecha }}</p></td>
                                <td class="d-flex font-semibold text-heading text-muted text-xs">
                                  <button class="btn btn-sm mb-3 rounded-3 me-1 py-2 m-0" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" type="button" (click)="openViewAdmisionModal(item.programa, item.fecha, item.tanda, item.fname, item.lname, item.cedula, item.email, item.cel, item.estado_civil, item.dependientes, item.sexo, item.fecha_nac, item.lugar_nac, item.lugar_trabajo, item.ingresos, item.otros_ingresos,  item.direccion, item.ocupacion,  item.profesion, item.uni_procedencia,  item.grado_academico, item.idiomas, item.ref_nom_1, item.ref_addr_1, item.ref_tel_1, item.ref_nom_2, item.ref_addr_2, item.ref_tel_2, item.ref_nom_3,  item.ref_addr_3,  item.ref_tel_3)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>&nbsp;Información</button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putResultAdmisionById(item.email)">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check me-1" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                      </svg>&nbsp;Recibido
                                    </button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2 ms-1" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putInscripAdmisionById(item.id_admisiones);">
                                      &nbsp;Inscripto
                                    </button>
                                  </td>
                                
                              </tr>
                            </tbody>
                          </table>
                          </div>
                          <div class="d-flex justify-content-center gap-2">
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="previousPage()" [disabled]="currentPage <= 1">Anterior</button>
                            <span>Página {{ currentPage }} de {{ totalPages }}</span>
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="nextPage()" [disabled]="currentPage >= totalPages">Siguiente</button>
                          </div>
                        </div>
                    </div>
                    </div>
                    <div class="tab-pane fade" id="nav-nuevo" role="tabpanel" aria-labelledby="nav-nuevo-tab" tabindex="0">
                      
                      <div class="row border border-light rounded-3 mt-2">
                        <div class="col rem-7">
                          
                          <div class="d-flex table-responsive" *ngIf="content && content.dataA" style="font-family: sans-serif; font-size: 0.6rem;">
                            <table class="table table-bordered table-striped table-hover">
                              <thead class="bg-light" style="background-color: #f8fefe;border-radius: 10px;">
                              <tr>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">#</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Estado</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Nombre</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Tel.</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Programa</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Horario</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Fecha</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Controles</p></th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr class="border border-light" *ngFor="let item of pagedDataNuevos; let i = index">
                                <td>{{ (currentPageNuevos - 1) * itemsPerPageN + i + 1 }}</td>
                                
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">
                                  <div *ngIf="item.recibido;else falseCase">
                                    <span class="badge rounded-pill bg-danger mt-0" style="font-size: 0.6rem;">recibido</span>
                                  </div>
                                  <ng-template #falseCase>
                                    <span class="badge rounded-pill bg-success mt-0" style="font-size: 0.6rem;">nuevo</span>
                                  </ng-template>
                                </td>
                                <th scope="row">
                                  <p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fname }} {{ item.lname }}</p>
                                </th>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.cel }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.programa }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.tanda }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fecha }}</p></td>
                                <td class="d-flex font-semibold text-heading text-muted text-xs">
                                  <button class="btn btn-sm mb-3 rounded-3 me-1 py-2 m-0" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" type="button" (click)="openViewAdmisionModal(item.programa, item.fecha, item.tanda, item.fname, item.lname, item.cedula, item.email, item.cel, item.estado_civil, item.dependientes, item.sexo, item.fecha_nac, item.lugar_nac, item.lugar_trabajo, item.ingresos, item.otros_ingresos,  item.direccion, item.ocupacion,  item.profesion, item.uni_procedencia,  item.grado_academico, item.idiomas, item.ref_nom_1, item.ref_addr_1, item.ref_tel_1, item.ref_nom_2, item.ref_addr_2, item.ref_tel_2, item.ref_nom_3,  item.ref_addr_3,  item.ref_tel_3)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>&nbsp;Información</button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putResultAdmisionById(item.email)">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check me-1" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                      </svg>&nbsp;Recibido
                                    </button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2 ms-1" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putInscripAdmisionById(item.id_admisiones)">
                                      &nbsp;Inscripto
                                    </button>
                                  </td>
                                
                              </tr>
                            </tbody>
                          </table>
                          </div>
                          <div class="d-flex justify-content-center gap-2">
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="pageChangedNuevos(currentPageNuevos - 1)" [disabled]="currentPageNuevos <= 1">Anterior</button>
                            <span>Página {{ currentPageNuevos }} de {{ totalPagesNuevos }}</span>
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="pageChangedNuevos(currentPageNuevos + 1)" [disabled]="currentPageNuevos >= totalPagesNuevos">Siguiente</button>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                    <div class="tab-pane fade" id="nav-recibido" role="tabpanel" aria-labelledby="nav-recibido-tab" tabindex="0">
                      <div class="row border border-light rounded-3 mt-2">
                        <div class="col rem-7">
                          <div class="d-flex table-responsive" *ngIf="content && content.dataA" style="font-family: sans-serif; font-size: 0.6rem;">
                            <table class="table table-bordered table-striped table-hover">
                              <thead class="bg-light" style="background-color: #f8fefe;border-radius: 10px;">
                              <tr>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">#</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Estado</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Nombre</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Tel.</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Programa</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Horario</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Fecha</p></th>
                                <th scope="col"><p class="text-start font-semibold text-heading text-xs">Controles</p></th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr class="border border-light" *ngFor="let item of pagedDataRecibidos; let i = index">
                                <td>{{ (currentPageRecibidos - 1) * itemsPerPageR + i + 1 }}</td>
                                
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">
                                  <div *ngIf="item.recibido;else falseCase">
                                    <span class="badge rounded-pill bg-danger mt-0" style="font-size: 0.6rem;">recibido</span>
                                  </div>
                                  <ng-template #falseCase>
                                    <span class="badge rounded-pill bg-success mt-0" style="font-size: 0.6rem;">nuevo</span>
                                  </ng-template>
                                </td>
                                <th scope="row">
                                  <p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fname }} {{ item.lname }}</p>
                                </th>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.cel }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.programa }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.tanda }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fecha }}</p></td>
                                <td class="d-flex font-semibold text-heading text-muted text-xs">
                                  <button class="btn btn-sm mb-3 rounded-3 me-1 py-2 m-0" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" type="button" (click)="openViewAdmisionModal(item.programa, item.fecha, item.tanda, item.fname, item.lname, item.cedula, item.email, item.cel, item.estado_civil, item.dependientes, item.sexo, item.fecha_nac, item.lugar_nac, item.lugar_trabajo, item.ingresos, item.otros_ingresos,  item.direccion, item.ocupacion,  item.profesion, item.uni_procedencia,  item.grado_academico, item.idiomas, item.ref_nom_1, item.ref_addr_1, item.ref_tel_1, item.ref_nom_2, item.ref_addr_2, item.ref_tel_2, item.ref_nom_3,  item.ref_addr_3,  item.ref_tel_3)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>&nbsp;Información</button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putResultAdmisionById(item.email)">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check me-1" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                      </svg>&nbsp;Recibido
                                    </button>
                                    <button class="btn btn-sm h-50 w-50 mb-3 rounded-3 justify-content-md-start m-0 py-2 ms-1" style="font-size: 0.7rem; background-color: #f8ece3; color: #f47b20;" (click)="putInscripAdmisionById(item.id_admisiones)">
                                      &nbsp;Inscripto
                                    </button>
                                  </td>
                                
                              </tr>
                            </tbody>
                          </table>
                          </div>
                          <div class="d-flex justify-content-center gap-2">
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="pageChangedRecibidos(currentPageRecibidos - 1)" [disabled]="currentPageRecibidos <= 1">Anterior</button>
                            <span>Página {{ currentPageRecibidos }} de {{ totalPagesRecibidos }}</span>
                            <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="pageChangedRecibidos(currentPageRecibidos + 1)" [disabled]="currentPageRecibidos >= totalPagesRecibidos">Siguiente</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
         </div>
        </div>
    </div>
  </div>
</div>