import { Component } from '@angular/core';

@Component({
  selector: 'app-trabajo-social',
  templateUrl: './trabajo-social.component.html',
  styleUrls: ['./trabajo-social.component.css']
})
export class TrabajoSocialComponent {

}
