
<div class="container-fluid">
    <div class="row">
      <div class="col-md-4 inicio text-dark">
        <div class="card border-0 px-2 rounded-3">
          <div class="card-header bg-white border-0 d-flex justify-content-center">
            <h6 class="text-start pt-3">Proceso de Recopilación de Estadísticas</h6>
          </div>
          <div class="card-body p-0">
            
            <form class="mx-auto" *ngIf="!isFormSuccess"   name="form"   #f="ngForm" novalidate>
                <div class="itemMesyct">
                  <mat-horizontal-stepper linear="true" #stepper [selectedIndex]="stepperIndex" [class.d-flex.flex-column.rounded-3]>


                    <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                        <div>
                            <div class="d-flex mt-2">
                                <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información Personal</span>
                            </div>

                            <div class="d-block mb-3">
                                <label class="text-sm fw-bold" for="ctrlCampus">CAMPUS</label>
                                <input matInput type="text" name="ctrlCampus" [(ngModel)]="mesyct.CAMPUS" class="form-control rounded-4 border border-dark border-1"required>
                            </div>
    
                            <div class="d-block mb-3">
                                <label class="text-sm fw-bold" for="ctrlTipoDoc">SELECCIONAR TIPO DOCUMENTO</label>
                                <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlTipoDoc"  [(ngModel)]="mesyct.TIPO_DOCUMENTO" required>
                                  <option class="ms-2" value="" disabled>SELECCIONAR</option>
                                  <option class="ms-2" value="CEDULA">CEDULA</option>
                                  <option class="ms-2" value="PASAPORTE">PASAPORTE</option>
                                </select>
                            </div>
                            
                            <div class="d-block mb-3">
                                <label class="text-sm fw-bold" for="ctrlCedula">NO. CEDULA:</label>
                                <input matInput type="text" name="ctrlCedula" [(ngModel)]="mesyct.NID" class="form-control rounded-4 border border-dark border-1"required>
                              </div>
                            <!-- <div class="d-block mb-3">
                              <label class="text-sm fw-bold" for="ctrlMatricula">NO. MATRICULA</label>
                              <input matInput type="text" name="ctrlMatricula" [(ngModel)]="mesyct.NO_MATRICULA" class="form-control rounded-4 border border-dark border-1"required>
                            </div> -->

                            <div class="d-flex justify-content-center mt-3 gap-2">
                                <button type="button" class="btn btn-primary" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                    <div>
                        
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlNombre">NOMBRES</label>
                          <input matInput type="text" name="ctrlNombre"  [(ngModel)]="mesyct.NOMBRES" class="form-control rounded-4 border border-dark border-1" placeholder="NOMBRES" required>
                         
                        </div>
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlApellido">PRIMER APELLIDO</label>
                          <input matInput type="text" name="ctrlApellido"  [(ngModel)]="mesyct.PRIMER_APELLIDO" class="form-control rounded-4 border border-dark border-1" placeholder="PRIMER APELLIDO" required>
                        </div>
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlApellido2">SEGUNDO APELLIDO</label>
                          <input matInput type="text" name="ctrlApellido2"  [(ngModel)]="mesyct.SEGUNDO_APELLIDO" class="form-control rounded-4 border border-dark border-1" placeholder="SEGUNDO APELLIDO" required>
                        </div>
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlFechaNac">FECHA DE NACIMIENTO</label>
                          <input matInput type="date" name="ctrlFechaNac"  [(ngModel)]="mesyct.FECHA_NAC" class="form-control rounded-4 border border-dark border-1" placeholder="(dd/mm/aaaa)" required>
                        </div>
                        
                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlSex">SEXO</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlSex"  [(ngModel)]="mesyct.SEXO" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="F">FEMENINO</option>
                              <option class="ms-2" value="M">MASCULINO</option>
                              
                            </select>
                          </div>

                        <div class="d-flex justify-content-center mt-3 gap-2">
                          <button type="button" class="btn btn-primary" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                        </div>
                      </div>
                      </mat-step>
    
                      <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
    
                        <div class="d-flex mt-2">
                            <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Más Información</span>
                          </div>
                          
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlFechaBachiller">FECHA EN QUE TERMINO EL BACHILLERATO O LA CARRERA DE GRADO</label>
                          <input matInput type="date" name="ctrlFechaBachiller"  [(ngModel)]="mesyct.FECHA_BACHILLERATO" class="form-control rounded-4 border border-dark border-1" placeholder="(dd/mm/aaaa)" required>
                        </div>
    
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlMunicipioNacimiento">MUNICIPIO DE NACIMIENTO</label>
                          <input matInput type="text" name="ctrlMunicipioNacimiento"  [(ngModel)]="mesyct.MUNICIPIO_NACIMIENTO" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>
    
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlMunicipioResidencia">MUNICIPIO DE RESIDENCIA HABITUAL</label>
                          <input matInput type="text" name="ctrlMunicipioResidencia"  [(ngModel)]="mesyct.MUNICIPIO_RESIDENCIA" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>
    
                      <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlPaisOrigen">PAÍS DE ORIGEN (NACIMIENTO)</label>
                          <input matInput type="text" name="ctrlPaisOrigen"  [(ngModel)]="mesyct.PAIS_ORIGEN" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>
    
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlPaisPrevio">PAÍS DE ORIGEN PREVIO A ESTUDIO</label>
                          <input matInput type="text" name="ctrlPaisPrevio"  [(ngModel)]="mesyct.PAIS_PREVIO" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>

                                                
                        
                        <div class="d-flex justify-content-center mt-3 gap-2">
                          <button type="button" class="btn btn-primary" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                        </div>
                      </mat-step> 
                      
                      <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
    
                        <div class="d-flex mt-2">
                            <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Más Información</span>
                          </div>
                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlEscuela">SELECCIONAR ESCUELA</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlEscuela"  [(ngModel)]="mesyct.ESCUELA" required>
                                <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="Administración">Administración</option>
                              <option class="ms-2" value="Economía">Economía</option>
                              <option class="ms-2" value="Contabilidad">Contabilidad</option>
                              <option class="ms-2" value="Mercadotecnia">Mercadotecnia</option>
                              <option class="ms-2" value="Sociología">Sociología</option>
                              <option class="ms-2" value="Estadísticas">Estadísticas</option>
                              <option class="ms-2" value="Turismo">Turismo</option>
                            </select>
                        </div>

                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlCarrera">QUE MAESTRIA CURSA?</label>
                            <input matInput type="text" name="ctrlCarrera"  [(ngModel)]="mesyct.CARRERA" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>

                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlCodigo">NOMBRE DE SU COORDINADOR</label>
                            <input matInput type="text" name="ctrlCodigo"  [(ngModel)]="mesyct.CODIGO" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                        </div>

                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlModalidad">SELECCIONAR MODALIDAD</label>
                          <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlModalidad"  [(ngModel)]="mesyct.MODALIDAD" required>
                            <option class="ms-2" value="" disabled>SELECCIONAR</option>
                            <option class="ms-2" value="PRESENCIAL">PRESENCIAL</option>
                            <option class="ms-2" value="SEMI-PRESENCIAL">SEMI-PRESENCIAL</option>
                            <option class="ms-2" value="VIRTUAL">VIRTUAL</option>
                          </select>
                        </div>
    
                       
                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlPeridoAcademico">PERIODO ACADÉMICO</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlPeridoAcademico"  [(ngModel)]="mesyct.PERIODO_ACADEMICO" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="2023-2025">2023-2025</option>
                              <option class="ms-2" value="2024-2026">2024-2026</option>
                              
                            </select>
                          </div>

                        <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlNivelAcademico">SELECCIONAR NIVEL ACADÉMICO</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlNivelAcademico"  [(ngModel)]="mesyct.NIVEL_ACADEMICO" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="Técnico Superior">Técnico Superior</option>
                              <option class="ms-2" value="Grado">Grado</option>
                              <option class="ms-2" value="Especialidad">Especialidad</option>
                              <option class="ms-2" value="Maestría">Maestría</option>
                              <option class="ms-2" value="Maestría">Doctorado</option>
                            </select>
                        </div>

                            
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlNuevoIngreso">ES NUEVO INGRESO (SI/NO)</label>
                          <mat-radio-group style="display: inline-grid;" name="ctrlNuevoIngreso"  [(ngModel)]="mesyct.NUEVO_INGRESO">
                              <mat-radio-button value="SI">SI</mat-radio-button>
                              <mat-radio-button value="NO">NO</mat-radio-button>
                            </mat-radio-group>
                        </div>
    
                        

                          <div class="d-flex justify-content-center mt-3 gap-2">
                            <button type="button" class="btn btn-primary" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                          </div>
                    </mat-step>
                      

                    <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                        <div class="d-flex mt-2">
                            <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Pregunstas?</span>
                          </div>

                          <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlDficultadVer">A. DIFICULTAD PARA VER INCLUSO SI USA LENTES</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadVer"  [(ngModel)]="mesyct.DIFICULTAD_VER" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                              <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                              <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                            </select>
                          </div>
                          
      
                          <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlDficultadOir">B. DIFICULTAD PARA OÍR AUN CUANDO USA UN APARATO AUDITIVO</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadOir"  [(ngModel)]="mesyct.DIFICULTAD_OIR" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                              <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                              <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                            </select>
                          </div>
      
                          <div class="d-block mb-3">
                            <label class="text-sm fw-bold" for="ctrlDficultadCaminar">C. DIFICULTAD PARA CAMINAR O SUBIR ESCALONES</label>
                            <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadCaminar"  [(ngModel)]="mesyct.DIFICULTAD_CAMINAR" required>
                              <option class="ms-2" value="" disabled>SELECCIONAR</option>
                              <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                              <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                              <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                            </select>
                          </div>
                          <div class="d-flex justify-content-center mt-3 gap-2">
                            <button type="button" class="btn btn-primary" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                          </div>
                    
                    </mat-step>
                      <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                        <div class="d-flex mt-2">
                            <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Preguntas?</span>
                          </div>
                        
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlDficultadRecordar">D. DIFICULTAD PARA RECORDAR O CONCENTRARSE</label>
                          <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadRecordar"  [(ngModel)]="mesyct.DIFICULTAD_RECORDAR" required>
                            <option class="ms-2" value="" disabled>SELECCIONAR</option>
                            <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                            <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                            <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                          </select>
                        </div>
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlDficultadRealizar">E. DIFICULTAD PARA REALIZAR TAREAS (NECESITA SOPORTE PARA REALIZAR SU TRABAJO)</label>
                          <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadRealizar"  [(ngModel)]="mesyct.DIFICULTAD_REALIZAR" required>
                            <option class="ms-2" value="" disabled>SELECCIONAR</option>
                            <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                            <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                            <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                          </select>
                        </div>
    
                        <div class="d-block mb-3">
                          <label class="text-sm fw-bold" for="ctrlDficultadComunicarse">F. DIFICULTAD PARA COMUNICARSE (PARA ENTENDER A LAS Y LOS DEMÁS Y PARA QUE LA O LO ENTIENDAN)</label>
                          <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadComunicarse"  [(ngModel)]="mesyct.DIFICULTAD_COMUNICARSE" required>
                            <option class="ms-2" value="" disabled>SELECCIONAR</option>
                            <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                            <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                            <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                          </select>
                        </div>
    
    
    
    
    
    
    
                        <div class="col-md-6 mx-auto">
                          <button type="submit" (click)="onSubmit();f.form.valid;" class="btn btn-primary">Finalizar</button>
                        </div>                
                    
                  </mat-step>


    
                </mat-horizontal-stepper>
              </div>
          </form>

          
          <div class="alert alert-success text-center text-sm fw-bold  text-dark" *ngIf="isFormSuccess">{{successMessage}}</div>

           
          </div>
        </div>
      </div>
      <!-- <div class="col-md-8 bg-hide-col  px-0">   -->
      <div class="col-md-8 pt-3">
        <div class="row">
          <div class="col d-flex flex-column align-items-start gap-2">
            <h3 class="fw-semibold fs-6" style="color:#F47B20"></h3>
            <h2 class="text-dark text-start fw-bold"></h2>
            <p class="text-body-secondary"></p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex flex-column align-items-start gap-2 p-5">
            <div class="m-0 border-0">
      
          <ul class="list-unstyled">
            <li class="text-muted">
              <ul class="mt-5">
                <li></li>                
              </ul>
            </li>
          </ul>
          </div>
          </div>
        </div>
        
      </div>
    </div>
   
  </div>