import { Component } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {
  constructor(private tokenStorageService: TokenStorageService,private router: Router,private userService: UserService, private authService: AuthService) { }
  content:  any = [];
   user = this.tokenStorageService.getUser();

  ngOnInit(): void {
    
   /* this.tokenStorageService.signOut();
    // this.getLogoutService();
    window.location.replace("/"); */
   }
  /*
  logout(): void {
    this.tokenStorageService.signOut();
    // this.getLogoutService();
    window.location.replace("/");
  }
  getLogoutService(): void {
		
		  this.userService.postlogOutBoard().subscribe(
			res => {
			  this.content = res;
			},
			err => { 
			  this.content = JSON.parse(err.error).message;
			}
		  );
		}
	  */
    logout(): void {
      this.authService.logout(`${this.user.id_student}`).subscribe(
        (res:any[]) => {

          console.log('Logout exitoso');
          this.tokenStorageService.signOut();
          window.location.replace("/");
       
        },
        error => {
          // Manejar errores
          console.error('Error en el logout:', error);
        }
      );
    }
}
