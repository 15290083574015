import { Component } from '@angular/core';

@Component({
  selector: 'app-contabilidad-gubernamental',
  templateUrl: './contabilidad-gubernamental.component.html',
  styleUrls: ['./contabilidad-gubernamental.component.css']
})
export class ContabilidadGubernamentalComponent {

}
