<div class="container-fluid">
  <div class="row">
    <div class="col-2 border border-secundary bg-white">
      <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                  <li class="nav-item mb-2 py-2">
                    <a href="/coor" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>
<!--             
                    <li class="nav-item mb-2 py-2">
                      <a  href="/coor/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>
                    </li> -->
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li> -->
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/registro" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Registro
                      </a>
                    </li>

                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>
<!--           
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li> -->
          
                    
                      <li class="border-top border-light d-block my-3 mx-0"></li>
                    
                </ul>
              </div>
          </nav>
      </div>
    </div>
    <div class="col-10">
      <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
          <div class="container">
            <div class="card-body rounded-3">
              <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ content.user.fname }} {{ content.user.lname }}</h3>
              <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
            </div>
          </div>
      </nav>

      <div class="d-block">
        {{content.cC.programa}}
      </div>
      <div class="card rounded-4 mt-3"  style="width: 16rem;">
        <div class="card-body">
            <div class="d-block">
              <p class="card-title text-center" style="font-size: 0.8rem;">Contabilidad General</p>
              <p class="card-title text-center" style="font-size: 0.6rem;"></p>
            </div>
            <div class="d-block py-2">
            <div class="d-flex pb-2 gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-person" viewBox="0 0 16 16"  style="color: #F47B20;">
                    <path d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                    <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                  </svg>
                <p class="card-text" style="font-size: 0.8rem;">Prof. Juan Hernandez</p>
            </div>
            <div class="d-flex pb-2 gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16" style="color: #F47B20;">
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                  </svg>
              <p class="card-text" style="font-size: 0.8rem;">Modulo: Propedeutico</p>
          </div>
            <div class="d-flex pb-2 gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16" style="color: #F47B20;">
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                    </svg>
                <p class="card-text" style="font-size: 0.8rem;">Periodo: 2024-2026</p>
            </div>
            <div class="d-flex pb-2 gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-c-circle" viewBox="0 0 16 16" style="color: #F47B20;">
                <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512"/>
              </svg>
                <p class="card-text" style="font-size: 0.8rem;">Créditos: 03</p>
            </div>
            </div>
            
            <div class="d-flex justify-content-center">
            <button type="button" class="btn rounded-5 w-75" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;">Ver</button>  
          </div>
        </div>
    </div>
    </div>
  </div>
</div>