import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { TokenStorageService } from '../../services/token-storage.service';

import { database } from '../../models/database'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
@HostBinding('class') classes = 'row'
  /*login:database={
	student_id: '', 
	fname: '',
	lname: '',
	slname: '',
	nid: '',
	pwd: '',
	roles: '',
	email:	'',
	date: '',
	sex: '',
	coor: '',
	codigo: 0,
	cr: 0,
	token: '',
	prefix: '',
	periodo: '', 
	escuela: '',
	campus: '', 
	nivel: '', 
	dept: '',
	permission: false,
    is_online: false
	} */
	login:database={
		nid:	'',
		pwd:	'',
		roles: ''
		} 

 TOKEN_KEY = 'auth-token';
 USER_KEY = 'auth-user';
  content : string | undefined
  hide = true
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = ''
 roles: string[] = [];
 name?: string;

  constructor(private authService: AuthService,private tokenStorage: TokenStorageService) { }

  ngOnInit(): void {
	
  }
  onSubmit():void{
	/*	
	if (this.tokenStorage.getToken()) {
		this.isLoggedIn = true;
		this.roles = this.tokenStorage.getUser().roles;
	  }
	  if (this.isLoggedIn) {
		  const user = this.tokenStorage.getUser();
		  this.name = user.student_id;
		  
	  }*/
		this.authService.login(this.login).subscribe(
		res => {
				this.login = res
				this.tokenStorage.saveToken(res.token);
				this.tokenStorage.saveUser(res);

				this.isLoginFailed = false;
        		this.isLoggedIn = true;	
        		this.roles = this.tokenStorage.getUser().roles;
        	
				this.reloadPage();
   		},
		err => {
			this.errorMessage = err.error.message
			this.isLoginFailed = true;
		})
	}
	
 reloadPage(): void {
    window.location.reload();
  }
}
