import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-finanzas-coorporativa',
  templateUrl: './gestion-finanzas-coorporativa.component.html',
  styleUrls: ['./gestion-finanzas-coorporativa.component.css']
})
export class GestionFinanzasCoorporativaComponent {

}
