import { HTTP_INTERCEPTORS,HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { TokenStorageService } from '../services/token-storage.service';
import { Observable,throwError } from 'rxjs';

const TOKEN_HEADER_KEY = 'authorization';   

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

@Injectable() 
export class RoleInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const roles = req.headers.get('roles');
    const user = this.token.getUser();
    const role = user.roles;

    if (role === 'ADMIN') {
      const userRoles = user.roles; 
      // if (!userRoles || !userRoles.includes('ADMIN')) {
        if (!userRoles || !role.includes('ADMIN')) {
        return throwError('No tiene permisos para acceder a esta funcionalidad');
      }
    }
    return next.handle(req);
  }
}