<div class="container-fluid mx-0 pt-0 pb-0">
  <div class="d-flex mx-0 px-0 border-0">
    <div class="row">
      <div class="col-md-6 d-flex m-5 p-3" style="flex-direction: column;">
          <h5 class="mb-2" style="color: #fba000;">Conoce nuestras revistas acádemicas</h5>
          <h3 class="fw-semibold my-0" style="text-wrap: balance;">Publicaciones</h3>
          <p class="my-2" style="font-size:0.9rem; color: #8d8d8d; text-align: justify;">Te damos la bienvenida al portal oficial de las Revistas Académicas de la Facultad de Ciencias Económicas y  Sociales de la Universidad Autónoma de Santo Domingo, donde la investigación se transforma en artículos de divulgación y soluciones.</p>
          <p class="my-2" style="font-size:0.9rem;color: #8d8d8d; text-align: justify;">Aquí encontrarás una amplia gama de publicaciones que abordan diversos temas dentro de las ciencias económicas y sociales, contribuyendo así al avance del conocimiento y al desarrollo de nuestra sociedad.</p>

      </div>
        <div class="col">
          <img src="/assets/img/pub/img-pub-inicio.webp" alt="" class="img-fluid p-5 pt-0">
      </div>
    </div>
  </div>


<div class="container-fluid pt-0 mt-0">
  <div class="row mb-2 text-center">
      <div class="col d-flex gap-2 p-5 justify-content-center">
            <div class="row text-center">
              <div class="col list-group d-flex mx-3">
                  <a href="https://postgradofces.com/publicaciones/inise" class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4 border-0" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem; width: 14rem;">
                    <img src="/assets/img/pub/INSE.webp" width="47" height="51" alt="...">
                  <div>
                  <p class="text-sm text-white mb-0">INISE</p>
                  </div>
                </a>
              </div>
              <div class="col list-group d-flex mx-3">
                <a href="#" class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4 border-0" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem; width: 14rem;">
                  <img src="/assets/img/pub/IGEF-UASD.webp"  width="47" height="51" alt="...">                     
                  <div>
                    <p class="text-sm text-white mb-0 ">IGEF-UASD</p>
                  </div>
                </a>
              </div>
              <div class="col list-group d-flex mx-3">
                  <a href="#" class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4 border-0" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem; width: 14rem;">
                    <img src="/assets/img/pub/img-pub-article.webp"  width="40" height="51" alt="...">
                  <div>
                  <p class="text-sm text-white mb-0 ">Artículos Indexados</p>
                  </div>
                </a>
              </div>
              <div class="col list-group d-flex mx-3">
                <a href="#" class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4 border-0" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem; width: 14rem;">
                  <img src="/assets/img/pub/img-pub-tesis.webp"  width="40" height="51" alt="...">                        
                <div>
                <p class="text-sm text-white mb-0 ">Catálogo de tesis y monografías</p>
                </div>
              </a>
              </div>
            </div>
      </div>
  </div>
</div>

<div class="content">
  <div class="row justify-content-center rounded-3" style="background-color: #ECECEC;"> 
    <div class="col d-flex flex-column align-items-center">
      <h5 class="mb-2 my-3" style="color: #fba000;">Aquí podrás leer nuestras</h5>
        <h3 class="fw-semibold pt-2" style="text-wrap: balance;">Revistas e Informes Académicos</h3>
        
        <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
         
          
          <!--Rigth side-->
          <div class="col">
            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="/assets/img/pub/revistas/factores-inmigracion-haitiana.png" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;">Factores Económicos y Demográficos</h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">PALABRAS CLAVE: Inmigración haitiana, Factores Socioeconómicos</p>
                      <div class="d-flex justify-content-start pt-2 gap-2">
                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                        <a href="/assets/pdf/revistas/factores-inmigracion-haitiana.pdf" type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;" download>Descargar</a>
                      </div>
                  </div>
                </div>
              </div>   
            </div>

            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="/assets/img/pub/informes/ANALISIS-COYUNTURA.png" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;">Análisis de Coyuntura</h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">Julio - Diciembre 2022 Por: Intituto de Investigaciones Socioeconómicas, INICE</p>
                    <div class="d-flex justify-content-start pt-2 gap-2">
                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                        <a href="/assets/pdf/informes/ANALISIS-COYUNTURA.pdf" type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;" download>Descargar</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>






            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="/assets/img/pub/revistas/Revista-Cientifica-Economía-Marzo-2024.png" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;">Revista Cientifica Economía Marzo</h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">PALABRAS CLAVE: Revista, Economía</p>
                      <div class="d-flex justify-content-start pt-2 gap-2">
                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                        <a href="/assets/pdf/revistas/Revista-Cientifica-Economía-Marzo-2024-DIGITAL.pdf" type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;" download>Descargar</a>
                      </div>
                  </div>
                </div>
              </div>   
            </div>
            
          </div>

          <!--Left side-->
          <div class="col">
            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="/assets/img/pub/informes/EQUIDAD-Y-GENERO-EN-EL-SECTOR-TURISTICO.png" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;">Equidad  y Género En El Sector Turístico</h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">Escuela de Hotelería y Turísmo. Genero y Turísmo</p>
                      <div class="d-flex justify-content-start pt-2 gap-2">
                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                        <a href="/assets/pdf/informes/EQUIDAD-Y-GENERO-EN-EL-SECTOR-TURISTICO-Hoja-para-colocar-trabajo-de-investigacion.pdf" type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;" download>Descargar</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="/assets/img/pub/informes/Efectos-del-COVID-19-en-el-Gasto-de-la-Administración-General.png" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;">Efectos del COVID-19</h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">Gasto de la Administacion General de la República Dominicana</p>
                      <div class="d-flex justify-content-start pt-2 gap-2">
                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                        <a href="/assets/pdf/informes/Efectos-del-COVID-19-en-el-Gasto-de-la-Administración-General-de-República-Dominicana.pdf" type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;" download>Descargar</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
              <div class="row">
                <div class="col-md-3 d-flex align-items-center  rounded-2">
                  <img src="" class="img-fluid ms-2 border rounded" alt="...">
                </div>
                <div class="col rounded-1" style="border-right: 5px solid #8d8d8d;">
                  <div class="card-body">
                    <h6 class="card-title fw-semibold text-start" style="font-size: 0.8rem;"></h6>
                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;"></p>
                      <div class="d-flex justify-content-start pt-2 gap-2">
                        <!-- <a class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #8d8d8d;">Ver revista</a> -->
                        <!-- <a type="button" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #8d8d8d;" download>Descargar</a> -->
                      </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
    </div>
  </div>
</div>





<div class="container-fluid m-0 p-4" style="background: linear-gradient(90deg, #DA7409 45.8%, #E28D1A 54.2%);">
<div class="row row-col-1 row-cols-3">
  <div class="col-md-4">
    <div class="d-block justify-content-center">
    <span class="fs-4 text-white">ACTIVIDADES DE INVESTIGACIÓN</span>
    <p class="text-white" style="font-size: 0.7rem;">UASD, Santo Domingo, Distrito Nacional</p>
    </div>
  </div>
  <div class="col-md-4 d-flex gap-2">
    <div class="d-block rounded-3 p-2" style="background-color: #965007;">
      <p class="fw-semibold text-white text-center" style="font-size: 0.9rem;">20</p>
      <p class="text-white text-center" style="font-size: 0.5rem;">Proyectos</p>
      <p class="text-white text-center" style="font-size: 0.5rem;">Encabezados</p>
    </div>
    <div class="d-block rounded-3 p-2" style="background-color: #965007;">
      <p class="fw-semibold text-white text-center" style="font-size: 0.9rem;">13</p>
      <p class="text-white text-center" style="font-size: 0.5rem;">Investigadores</p>
    </div>
    <div class="d-block rounded-3 p-2" style="background-color: #965007;">
      <p class="fw-semibold text-white text-center" style="font-size: 0.9rem;">9</p>
      <p class="text-white text-center" style="font-size: 0.5rem;">Auxiliares de</p>
      <p class="text-white text-center" style="font-size: 0.5rem;">Investigación</p>
    </div>
  </div>
  <div class="col-md-4">
  <div class="col-1 d-flex justify-content-center align-items-center w-50 h-100"  style="background-color: #965007;">
    <p class="text-white fs-6">Conocer más</p>
  </div>
  </div>
</div>
</div>



<div class="container-fluid">
<div class="row justify-content-center">
  <div class="col-md-6">
    <h5 class="mb-2 my-3" style="color: #fba000; text-align: center;">Aquí podrás leer nuestras</h5>
    <h2 class="fw-semibold pt-2" style="text-wrap: balance; text-align: center;">Eventos y novedades</h2>
  </div>
</div>
</div>

<div class="content">
<div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
  <div class="col">
    <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
      <div class="rounded-top-4">
        <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-rev-1.webp" alt="Imagen no disponible">
      </div>
      <div class="d-flex p-2" style="font-size: 0.7rem;">
        <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
        <p class="text-white">16</p>
        <p class="text-white">Nov.</p>
        </div>
      </div>
      <div class="card-body px-2">
        <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
        <div class="d-flex justify-content-start align-items-center border border-1">
          <div class="d-block border-end border-1 px-2">
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i>Instituto de Investigación y Estudios de Género y Familia-UASD</p>
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i>Paraninfo, Ricardo Michel, UASD Sede.</p>
          </div>
          <div class="d-block border-end border-1 px-2">
            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
          <div class="d-block px-2">
            <p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">
            Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.
          </p>
        </div>
      </div>
      
    </div>
  </div>
  <div class="col">
    <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
      <div class="rounded-top-4">
        <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-rev-1.webp" alt="Imagen no disponible">
      </div>
      <div class="d-flex p-2" style="font-size: 0.7rem;">
        <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
        <p class="text-white">16</p>
        <p class="text-white">Nov.</p>
        </div>
      </div>
      <div class="card-body px-2">
        <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
        <div class="d-flex justify-content-start align-items-center border border-1">
          <div class="d-block border-end border-1 px-2">
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i>Instituto de Investigación y Estudios de Género y Familia-UASD</p>
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i>Paraninfo, Ricardo Michel, UASD Sede.</p>
          </div>
          <div class="d-block border-end border-1 px-2">
            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
          <div class="d-block px-2">
            <p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">
            Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.
          </p>
        </div>
      </div>
      
    </div>
  </div>
  <div class="col">
    <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
      <div class="rounded-top-4">
        <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-rev-1.webp" alt="Imagen no disponible">
      </div>
      <div class="d-flex p-2" style="font-size: 0.7rem;">
        <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
        <p class="text-white">16</p>
        <p class="text-white">Nov.</p>
        </div>
      </div>
      <div class="card-body px-2">
        <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
        <div class="d-flex justify-content-start align-items-center border border-1">
          <div class="d-block border-end border-1 px-2">
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i> Instituto de Investigación y Estudios de Género y Familia-UASD</p>
            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i> Paraninfo, Ricardo Michel, UASD Sede.</p>
          </div>
          <div class="d-block border-end border-1 px-2">
            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
          <div class="d-block px-2">
            <p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">
            Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.
          </p>
        </div>
      </div>
      
    </div>
  </div>
</div>
</div>




  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h5 class="mb-2 my-3" style="color: #fba000; text-align: center;">Conoce a nuestros</h5>
        <h2 class="fw-semibold pt-2" style="text-wrap: balance; text-align: center;">Investigadores</h2>
      </div>
    </div>
  </div>


      <div class="content">
        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div class="col d-flex flex-column gap-3 px-0" style="align-items: center;">
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="https://postgradofces.com/assets/img/profesores/wagner-gomera.png" class="img-fluid rounded-3">
              </div>
              <div class="card-footer  py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Wagner Gomera</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Director de Investigaciones</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
            
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="https://postgradofces.com/assets/img/profesores/juan-del-rosario.png" class="img-fluid rounded-3">
              </div>
              <div class="card-footer  py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Juan del Rosario</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Director</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
          </div>

          <div class="col d-flex flex-column gap-3 px-0" style="align-items: center;">
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="">
              </div>
              <div class="card-footer  py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Amaury Pérez</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Investigador</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="">
              </div>
              <div class="card-footer  py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Fideligna de la Rosa</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Investigadora</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
          </div>

          <div class="col d-flex flex-column gap-3 px-0" style="align-items: center;">
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="">
              </div>
              <div class="card-footer py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Henry Hernández</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Investigador</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
            <div class="card" style="background-color: #F47B20; width: 18rem; height: 20rem;">
              <div class="card-body">
                <img src="">
              </div>
              <div class="card-footer py-3 mb-0 border-0" style="background-image: url('https://postgradofces.com/assets/img/profesores/img-pub-info-prof.png'); background-repeat: no-repeat; background-size: cover; position: absolute; top: 234px; left: 30px; width: 236px; height: 86px;">
                <p class="fw-bold fs-6">Juan Pérez</p>
                <p class="text-secondary " style="font-size: 0.7rem;">Investigador</p>
                <div class="d-flex">

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

     <div class="content mt-5">
      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center rounded-3" style="background-color: #ECECEC;">
        <div class="col d-flex align-items-center">
          <img class="img-fluid me-3" src="/assets/img/img-pub-revista.webp" alt="">
        </div>
        <div class="col p-5">
          <div class="d-flex flex-column gap-2 mb-4 text-start">
            <h5 style="color: #F47B20;">¿Eres investigador o investigadora?</h5>
            <h3 class="fw-semibold">Se parte de nuestra próxima revista</h3>
            <p>Si te interesa presentar un articulo en la próxima edición de nuestra revista, escribe tus datos y nos pondremos en contacto en cuanto sea posible.</p>
          </div>
          <form>
            <div class="d-block gap-2">
              <div class="mb-3">
                <input type="text" class="form-control" id="nombrecompleto" aria-describedby="emailHelp" placeholder="Nombre completo">
              </div>
              <div class="mb-3">
                <input type="email" class="form-control" id="exampleInputPassword1" placeholder="Correo Electrónico">
              </div>
            </div>
            <div class="d-block gap-2">
              <div class="mb-3">
                <input type="text" class="form-control" id="numerotelefono" placeholder="Número de contacto">
              </div>
              <div class="mb-3">
                <input type="text" class="form-control" id="escuela" placeholder="Escuela">
              </div>
            </div>
            <div class="d-flex flex-column">
              <textarea class="form-control rounded-4" style="height: 100px;" name="textareapub" id="textareapub" placeholder="¿En cuál revista le gustaría participar?"></textarea>
                <div class="d-flex justify-content-center mt-2">
                  <button type="submit" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem;background-color: #F47B20; color: #FFFFFF;">Enviar</button>
                </div>
            </div>
          </form>
        </div>
      </div>
     </div>
</div>