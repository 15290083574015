import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { database } from  '../../models/database';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {registro_db} from '../../models/registro_db';

@Component({
    selector: 'app-viewinscripcionModal',
    template: `
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5">{{ maestria }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
          <p><strong>Maestría:</strong> {{ maestria }}</p>
         
          <ul>
            <li>
                 <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 0.6rem; text-align: center;">Nombre</th>
                                            <th style="font-size: 0.6rem; text-align: center;">Cedula</th>
                                            <th style="font-size: 0.6rem; text-align: center;">Horario Seleccionado</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ nombre }} </td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ cedula }}</td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">{{ horario }}</td>
                                            <td class="fw-bold" style="font-size: 0.6rem;">
                                             <button class="btn btn-sm mb-3 rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;">&nbsp;Acción</button>
                                             </td>
                                    
                                            
                                            
                                        </tr>
                                    </tbody>
                                </table>
      
               
            </li>
          </ul>
        </div>
        
            
        
        <div class="modal-footer border-1 justify-content-end">
            
            <button class="btn btn-sm mb-3 rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;" (click)="closeModal()"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill me-1" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
          </svg>&nbsp;Cerrar</button>
        </div>
        </div>`
    
  
  })

  export class ViewInscripcionModal  implements OnInit {
    @Input() maestria?: string;
    @Input() nombre?: string;
    @Input()  cedula?: string;
    @Input()  horario?: string;
   
  
    isEditable = false;
  
    content:  any = [];

    now = new Date()
      month = String(this.now.getMonth() + 1).padStart(2, '0')
      day = String(this.now.getDate()).padStart(2, '0')
      year = this.now.getFullYear()
      
      formattedDate = `${this.day}/${this.month}/${this.year}`
      student_db:database={
          student_id: '', 
          fname: '',
          lname: '',
          slname: '',
          nid: '',
          pwd: '',
          roles: '',
          email:	'',
          date: '',
          sex: '',
          coor: '',
          codigo: 0,
          cr: 0,
          token: '',
          prefix: '',
          periodo: '', 
          escuela: '',
          campus: '', 
          nivel: '', 
          dept: '', 
          permission: false,
          is_online: false
      }

  
    //   messageSuccess?: any;
  
    constructor( public bsModalRef: BsModalRef,private userService: UserService, private router: Router) {}
        
    ngOnInit(): void {

  }
  

    getRecordStrudent(): void {
    //     const studentId = this.registro_db.student_id !== undefined ? this.registro_db.student_id.toString() : '';
    //     const modulo = this.registro_db.modulo || '';
    //     const clave = this.registro_db.clave || '';
    //     const periodo = this.registro_db.periodo || '';
    //     const asignatura = this.registro_db.asignatura || '';
    //     const pp = this.registro_db.pp !== undefined ? this.registro_db.pp.toString() : '';
    //     const p = this.registro_db.p !== undefined ? this.registro_db.p.toString() : '';
    //     const ifp = this.registro_db.ifp !== undefined ? this.registro_db.ifp.toString() : '';
    //     const ap = this.registro_db.ap !== undefined ? this.registro_db.ap.toString() : '';
        
    //     this.userService.addRecordStrudent(studentId, modulo, clave, periodo, asignatura, pp, p, ifp, ap).subscribe(
    //         (res: any[]) => {			
  
    //     //  this.content = res;
        
    //      },
    //      err => {
    //      this.content = JSON.parse(err.error).message;
    //      }
    //    );
       
      }
    
      closeModal() {
          this.bsModalRef.hide();
      }
   
    }