import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';
import { ViewInscripcionModal } from './ViewInscriptionModal';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-inscripciones',
  templateUrl: './inscripciones.component.html',
  styleUrl: './inscripciones.component.css'
})
export class InscripcionesComponent implements OnInit {

  @HostBinding('class') classes = 'container-fluid';
	
	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()

	formattedDate = `${this.day}/${this.month}/${this.year}`
	student_db:database={
		student_id: '', 
    fname: '',
    lname: '',
    slname: '',
    nid: '',
    pwd: '',
    roles: '',
    email:	'',
    date: '',
    sex: '',
    coor: '',
    codigo: 0,
    cr: 0,
    token: '',
    prefix: '',
    periodo: '', 
    escuela: '',
    campus: '', 
    nivel: '', 
    dept: '', 
    permission: false,
    is_online: false
	}

  content: any = { user: {}, JsonData: { asignaturas: [] }, dataE: 0, totalEE: [] };
	modalRef?: BsModalRef;

	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;

	pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 10; 
  autoHide: boolean = false;
  totalItems!: number; 
  totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;

  
  constructor(private tokenStorageService: TokenStorageService,
    private http: HttpClient,
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);}

    ngOnInit(): void {
      this.getAllInscriptionData();
          
    }
    
    getAllInscriptionData():void{
      
      if (!this.authService.isLoggedIn()) {
        // this.openModal();
        this.router.navigate(['/']);
      } else {
        
        this.userService.getInscricionBoard().subscribe(
        (res: any[]) => {
        this.content = res;
          this.totalItems = this.content.dataI.length;
          this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.pageChanged(1);
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
        );
      }
     }
     
     previousPage(): void {
      if (this.currentPage > 1) {
        this.pageChanged(this.currentPage - 1);
      }
    }
      
    nextPage(): void {
        if (this.currentPage < this.totalPages) {
          this.pageChanged(this.currentPage + 1);
        }
      
    }
    
    pageChanged(pageNumber: number): void {
        const startIndex = (pageNumber - 1) * this.itemsPerPage;
        let endIndex = startIndex + this.itemsPerPage;
      
        
        endIndex = Math.min(endIndex, this.totalItems);
      
        this.pagedData = this.content.dataI.slice(startIndex, endIndex);
        this.currentPage = pageNumber;
        this.showPreviousButton = this.currentPage > 1;
        this.showNextButton = this.currentPage < this.totalPages;
    }


    openViewInscripcionModal(maestria:string,nombre:string, cedula:string, horario:string){
      const initialState= {
        maestria:maestria,
        nombre:nombre,
        cedula: cedula,
        horario: horario
      };
      this.modalRef = this.modalService.show(ViewInscripcionModal,{initialState})
    }

}
