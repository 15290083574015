<div class="offcanvas offcanvas-start bg-white"  data-bs-backdrop="static" tabindex="-1" id="modalMenubar" aria-labelledby="modalMenubarLabel">
    <div class="offcanvas-header" style="display: flex; justify-content: end;">
      <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="flex-shrink-0 p-3 bg-white">
    <div class="d-flex">
        <a href="https://postgradofces.com" class="align-items-center pb-3 mb-2 text-decoration-none mx-0" style="display: flex;">
          <div style="float: left;">
            <img  src="/assets/img/logo.png" class="bi pe-none me-2" width="65" alt="Facultad de Ciencias & Sociales (FCES)" height="75">
          </div>
        </a>
        <div class="d-flex mt-4 mx-0" style="flex-direction: column;">
          <p class="fw-semibold"  style="text-decoration: none; color: #022e57; width:9rem;font-size: 0.7rem;">Facultad de Ciencias Económicas Sociales</p> 
        </div>
    </div>
      <ul class="list-unstyled ps-0">
        <li class="border-top my-3"></li>
        <li class="mb-1">
          <a class="nav-link text-dark menu btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2"  href="https://postgradofces.com" aria-expanded="false">
            Inicío
          </a>
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/admisiones" class="nav-link text-dark menu btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2"  aria-expanded="false">
            Admisíones
          </a>
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/ofertas-academica" class="nav-link text-dark menu btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2"  aria-expanded="false">
            Oferta académica
          </a>
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/plan-estudios" class="nav-link text-dark menu btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="false">
            Oferta grado
          </a>
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/estudiantes"  class="nav-link menu text-dark btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
            Estudiantes
          </a> 
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/servicios"  class="nav-link menu text-dark btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
            Servicios
          </a> 
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/investigaciones"  class="nav-link menu text-dark btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
            Investigaciones
          </a> 
        </li>
        <li class="mb-1">
          <a href="https://postgradofces.com/servicios"  class="nav-link menu text-dark btn btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
            Educación continuada
          </a> 
        </li>
        <li class="border-top my-3"></li>
        <li class="nav-item mb-1 dropdown-item"  *ngIf="!isLoggedIn">
                  
          <a mat-button class="text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" data-bs-toggle="offcanvas" data-bs-target="#modalLogin" aria-controls="modalLogin">
            <span class="d-flex align-items-end justify-content-center fs-6" style=" width: 9rem;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle mx-2 my-1" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
              Iniciar sesión
            </span>
          </a>
          
        </li>
        <li class="nav-item mb-1 dropdown-item">
            <div class="dropdown-center dropdown-item">
              <!-- <li class="nav-item mb-1 dropdown-item"  *ngIf="isLoggedIn"> -->
                  <a class="btn btn-primary border-0 text-white" *ngIf="isLoggedIn"  data-bs-toggle="dropdown" aria-labelledby="login" ><span class="d-flex align-items-end justify-content-center fs-6 text-sm text-wrap" style=" width: 9rem; margin-top: 0px;"><i class="fs-5 bi bi-person-check-circle me-1"></i> {{username}}</span></a>
                <ul class="dropdown-menu">
                    <li class="d-block"><a class="dropdown-item" href="{{redirectURL}}">Dashboard</a></li>
                    <!-- <li class="d-block"><a class="dropdown-item" href="/logout" (click)="logout()">Cerrar sesión</a></li> -->
                    <li class="d-block"><a class="dropdown-item"  (click)="logout()">Cerrar sesión</a></li>
                </ul>
            </div>
          </li>
      </ul>
    </div>
</div>


