import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { evaluation_db } from '../../models/evaluation_db';


@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit{
	@HostBinding('class') classes = 'container-fluid';
	
	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()


	evaluation: evaluation_db;
	content:  any = [];
	modalRef?: BsModalRef;
  
	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;
	

	total = 30; 
	porcentaje = 0
	
	

	constructor(
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { 
		this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
		this.dateTime = this.today.toLocaleString('es-ES', this.options);  
		
		this.evaluation ={
			fname: '',
			lname: '',
			email: '',
			programa: '',
			asignatura: '',
			clave: '',
			modulo: '',
			periodo: '',
			coordinador: '',
			fecha: this.getFormattedDate(),
			p1: 0,
			p2: 0,
			p3: 0,
			p4: 0,
			p5: 0,
			p6: 0,
			p7: 0,
			p8: 0,
			p9: 0,
			p10: 0,
			p11: 0,
			p12: 0,
			p13: 0,
			p14: 0,
			p15: 0,
			p16: 0,
			p17: 0,
			p18: 0,
			p19: 0
	  };
	}


	openModal() {
		this.modalRef = this.modalService.show('modal-content'); 
	  }
	
	  closeModal() {
		if (this.modalRef) {
		  this.modalRef.hide();
		}
	  }

	  getFormattedDate(): string {
		const now = new Date();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		const year = now.getFullYear();
		return `${day}/${month}/${year}`;
	  }
	  
	ngOnInit(): void {
		this.getAllEvaluationService();
	}
	
	getAllEvaluationService(): void {
		if (!this.authService.isLoggedIn()) {
		  this.openModal();
		  this.router.navigate(['/']);
		} else {
		  this.userService.getEvalBoard().subscribe(
			res => {
			  this.content = res;
			 
			},
			err => { 
			  this.content = JSON.parse(err.error).message;
			}
		  );
		}
	  }
	  
}
