import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';
import * as datos from '../dataMaestrias.json';

import { InsertStudentModal} from './insertStudentModal';
import { ViewStudentModal} from './viewStudentModal';
// import { InsertRecordStudentModal} from './insertRecordStudentModal';

@Component({
  selector: 'app-result-students',
  templateUrl: './result-students.component.html',
  styleUrl: './result-students.component.css',
})


export class ResultStudentsComponent  implements OnInit{

  @HostBinding('class') classes = 'container-fluid';
	
	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()

	formattedDate = `${this.day}/${this.month}/${this.year}`
	student_db:database={
		student_id: '', 
    fname: '',
    lname: '',
    slname: '',
    nid: '',
    pwd: '',
    roles: '',
    email:	'',
    date: '',
    sex: '',
    coor: '',
    codigo: 0,
    cr: 0,
    token: '',
    prefix: '',
    periodo: '', 
    escuela: '',
    campus: '', 
    nivel: '', 
    dept: '', 
    permission: false,
    is_online: false
	}

  nombre_maestria?: string = '';

	content:  any = [];
	modalRef?: BsModalRef;
  
  dataFileMaestria?:any;

	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;
	
	selectedValue: string = '';

	// dataA: any[] = [];
	pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 10; 
  autoHide: boolean = false;
  totalItems!: number; 
  totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;

  constructor(private tokenStorageService: TokenStorageService,
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);}

    ngOnInit(): void {
      this.getAllStudentService();
    }
    
    getAllStudentService():void{
      
      if (!this.authService.isLoggedIn()) {
        // this.openModal();
        this.router.navigate(['/']);
      } else {
        
        this.userService.getResultStudentBoard().subscribe(
        (res: any[]) => {
        this.content = res;
          this.totalItems = this.content.dataA.length;
          this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.pageChanged(1);
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
        );
      }
     }
     getByIdStudentService(): void {
	
      if (this.student_db.codigo) {
        this.userService.getResultStudentById(this.student_db.codigo).subscribe(
       (res: any[]) => {			
        // console.log("codigo" + this.student_db.codigo);
       this.content = res;
       this.totalItems = this.content.dataA.length;
       this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
       this.pageChanged(1);
       },
       err => {
       this.content = JSON.parse(err.error).message;
       }
     );
     }
    }

     previousPage(): void {
      if (this.currentPage > 1) {
        this.pageChanged(this.currentPage - 1);
      }
    }
      
    nextPage(): void {
        if (this.currentPage < this.totalPages) {
          this.pageChanged(this.currentPage + 1);
        }
      
    }
    
    pageChanged(pageNumber: number): void {
        const startIndex = (pageNumber - 1) * this.itemsPerPage;
        let endIndex = startIndex + this.itemsPerPage;
      
        
        endIndex = Math.min(endIndex, this.totalItems);
      
        this.pagedData = this.content.dataA.slice(startIndex, endIndex);
        this.currentPage = pageNumber;
        this.showPreviousButton = this.currentPage > 1;
        this.showNextButton = this.currentPage < this.totalPages;
    }

 procesarDatos(codigo: number) {
    this.dataFileMaestria = datos;

      const programas = this.dataFileMaestria.programas;
      
      for (const programa of programas) {
        const maestria = programa.maestria;
        const codigox = programa.codigo;
        if(codigox == codigo){
    
  
        for (const modulo of programa.modulo) {
          for (const asignatura of modulo.asignaturas) {
            const nivel = asignatura.nivel;
            const clave = asignatura.clave;
            const asignaturaNombre = asignatura.asignatura;
            const creditos = asignatura.credito;
            const profesor = asignatura.profesor;
  
       
            return maestria
          }
        }
      }
    }
    }
    logout(): void {
      this.authService.logout(`${this.student_db.student_id}`).subscribe(
        (res:any[]) => {
       
          this.tokenStorageService.signOut();
          window.location.replace("/");
        },
        error => {
      
          console.error('Error en el logout:', error);
        }
      );
    }
    openInsertStudentModal(titulo:string){
      const initialState= {
        titulo: titulo
      };
      this.modalRef = this.modalService.show(InsertStudentModal,{initialState})
    }



    openViewStudentModal(nombre:string,apellido:string, xstudent_id:string, xperiodo:string){
      const initialState= {
        nombre: nombre ,
        apellido:apellido,
        xstudent_id:xstudent_id,
        xperiodo:xperiodo
      };
      this.modalRef = this.modalService.show(ViewStudentModal,{initialState})
    }
/*
    openInserRecordStudentModal(titulo:string){
      const initialState= {
        titulo:titulo
      };
      this.modalRef = this.modalService.show(InsertRecordStudentModal,{ class: 'modal-xl', initialState})
    }
*/
}