import { Component, HostBinding, OnInit, ViewChild  } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { admision_db } from '../../models/admision_db';
import { AdmisionService } from '../../services/admision.service';

@Component({
  selector: 'app-admisiones',
  templateUrl: './admisiones.component.html',
  styleUrls: ['./admisiones.component.css']
})
export class AdmisionesComponent{
  @ViewChild('stepper')
  @HostBinding('class') classes = 'row'
  stepperIndex = 0
  stepsTotal = 5
  stepsCompleted = 0
  isStepperCompleted():boolean{
    return this.stepsCompleted === this.stepsTotal
  }
  now = new Date()
  month = String(this.now.getMonth() + 1).padStart(2, '0')
  day = String(this.now.getDate()).padStart(2, '0')
  year = this.now.getFullYear()

  formattedDate = `${this.day}/${this.month}/${this.year}`

  formAdmisionGroup!:FormGroup
  errorMessage = ''
  successMessage = ''
  isFormSuccess = false
  isFormFailed = false
  isEditable = false

  admision:admision_db={
    fname: '',
    lname: '',
	cedula:'',
    estado_civil:'',
    sexo:'',
    fecha_nac:'',
    lugar_nac: '',
    dependientes:'',
    cel: '',
    email: '',
    programa: '',
    profesion: '',
    tanda: '',
    grado_academico: '',
    uni_procedencia: '',
    idiomas: '',
    medios: '',
    lugar_trabajo: '',
    direccion: '',
    ingresos: '',
    otros_ingresos: '',
    ocupacion: '',
	ref_nom_1:	'',
    ref_addr_1:	'',
    ref_tel_1:	'',
    ref_nom_2:	'',
    ref_addr_2:	'',
    ref_tel_2:	'',
    ref_nom_3:	'',
    ref_addr_3:	'',
    ref_tel_3:	'',
    fecha: `${this.formattedDate}`,
	recibido: 0,
	inscripto: 0

  }
  

	constructor( private admisionService: AdmisionService,  private _fb : FormBuilder) {
		this.initForms()
	}

	initForms(){
		this.formAdmisionGroup = this._fb.group({
			info_program: this._fb.group({
				
				ctrlInputProgram: this._fb.control('', Validators.required),
				ctrlInputTanda:this._fb.control('', Validators.required)
				
			}),
			info: this._fb.group({
				ctrlInputNombre: this._fb.control('', Validators.required),
				ctrlInputApellido: this._fb.control('', Validators.required),
				// ctrlInputEmail: this._fb.control('', [Validators.required,  Validators.pattern('[a-z0-9.%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
				ctrlInputEmail: this._fb.control('', Validators.required),
				ctrlInputCelular:this._fb.control('', Validators.required),
				ctrlInputCedula:this._fb.control('', Validators.required),
				ctlrInputSexo:this._fb.control('', Validators.required),
				ctlrInputEstado:this._fb.control('', Validators.required),
				ctrlInputFecNac:this._fb.control('', Validators.required),
				ctrlInputLugNac:this._fb.control('', Validators.required),
				ctrlInputDependientes:this._fb.control('', Validators.required)
				
				}),
			info_academy: this._fb.group({
				ctlrInputProfesion : this._fb.control('', Validators.required),
				ctlrInputGradoAcademico : this._fb.control('', Validators.required),
				ctlrInputUniversidad : this._fb.control('', Validators.required),
				ctlrInputIdiomas : this._fb.control('', Validators.required),
				ctlrInputMedios : this._fb.control('', Validators.required),
        		ctlrInputMedios_Seleccion : this._fb.control('', Validators.required)

			}),
			info_job: this._fb.group({
				ctlrInputTrabajo : this._fb.control('', Validators.required),
				ctlrInputDireccion : this._fb.control('', Validators.required),
				ctlrInputIngresos : [''],//this._fb.control('', Validators.required),
				ctlrInputOtrosIngresos : this._fb.control('', Validators.required),
				ctlrInputOcupacion : this._fb.control('', Validators.required)
			}),
			info_refPersonal1: this._fb.group({
				ctlrInputrefNombre1 : this._fb.control('', Validators.required),
				ctlrInputrefDireccion1 : this._fb.control('', Validators.required),
				ctlrInputrefTelefono1 : this._fb.control('', Validators.required)

			}),
			info_refPersonal2: this._fb.group({
				ctlrInputrefNombre2 : this._fb.control('', Validators.required),
				ctlrInputrefDireccion2 : this._fb.control('', Validators.required),
				ctlrInputrefTelefono2 : this._fb.control('', Validators.required)

			}),
			info_refPersonal3: this._fb.group({
				ctlrInputrefNombre3 : this._fb.control('', Validators.required),
				ctlrInputrefDireccion3 : this._fb.control('', Validators.required),
				ctlrInputrefTelefono3 : this._fb.control('', Validators.required)

			})
		}) 
	}
  get basicInfo(){
		return this.formAdmisionGroup.get('info') as FormGroup
	}
	get basicInfoProgram(){
		return this.formAdmisionGroup.get('info_program') as FormGroup
	}
	get basicAcademy(){
		return this.formAdmisionGroup.get('question_1') as FormGroup
	}
	get basicJob(){
		return this.formAdmisionGroup.get('question_2') as FormGroup
	}

	get basicRef(){
		return this.formAdmisionGroup.get('question_3') as FormGroup
	}
	
	get invalidEmail(){
		return   this.formAdmisionGroup.get('ctrlInputEmail')?.invalid && this.formAdmisionGroup.get('ctrlInputEmail')?.touched
	}
	get invalidProgram(){
		return  this.formAdmisionGroup.get('ctrlInputProgram')?.invalid && this.formAdmisionGroup.get('ctrlInputProgram')?.touched
	}
	get invalidTanda(){
		return  this.formAdmisionGroup.get('ctrlInputTanda')?.invalid && this.formAdmisionGroup.get('ctrlInputTanda')?.touched
	}

	get invalidIngresos(){
		return  this.formAdmisionGroup.get('ctlrInputIngresos')?.invalid && this.formAdmisionGroup.get('ctlrInputIngresos')?.touched
	}
	get invalidOtrosIngresos(){
		return  this.formAdmisionGroup.get('ctlrInputOtrosIngresos')?.invalid && this.formAdmisionGroup.get('ctlrInputOtrosIngresos')?.touched
	}

	reloadPage(): void {
    window.location.reload()
  }

  onSubmit():void{
		this.admisionService.admision(this.admision).subscribe(
		res => {
			this.admision = res
        	this.isFormFailed = false
        	this.successMessage = '*Formulario Admisión Completado. Gracias!'		
        	this.isFormSuccess = true
        
        	setTimeout(()=>{
				this.isFormFailed = false
				this.isFormSuccess = false
				this.reloadPage()
          	},5000)
			
		},
		err => {
			this.errorMessage = err.error.message
			this.isFormSuccess = false						
			this.isFormFailed = true
		   
			setTimeout(()=>{
				this.isFormFailed = false
				this.isFormSuccess = false
			  }, 5000)
			
		})
	}

}
