<div class="offcanvas offcanvas-start gradient-bg"  data-bs-backdrop="static" tabindex="-1" id="modalLogin" aria-labelledby="modalLoginLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title text-white" id="modalLoginLabel">Iniciar Sesión</h5>
      <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="card bg-transparent border-0">
        <div class="d-flex justify-content-center mt-5">
          <img class="mb-4 img-fluid mx-2" src="/assets/img/logouasd.png" alt="" width="60" height="65">
          <img class="mb-4 img-fluid mx-2" src="/assets/img/logo.png" alt="" width="60" height="65">                  
        </div>
      </div> 
    <div class="row">
		<form class="d-flex flex-column bg-transparent rounded-3" *ngIf="!isLoggedIn" name="form" #f="ngForm" novalidate>
			<div class="mb-3">
				<span class="icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
						<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
						<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
					</svg>
				</span>
				<input  type="text" class="form-control" name="nid" [(ngModel)]="login.nid" required nid #nid="ngModel" autocomplete="username"  maxlength="30" placeholder="Ingrese su usuario" autofocus>
			</div>
			<div class="mb-3">
			<span class="icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
					<path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
				</svg>
			</span>
				<input  type="password" class="form-control" placeholder="Ingrese su contraseña" name="pwd" [(ngModel)]="login.pwd" required pwd #pwd="ngModel" autocomplete="new-password"  minlength="6" maxlength="12" autofocus>
			</div>                  
			<div class="d-flex justify-content-center mt-5">
				<button  type="submit" (click)="onSubmit();f.form.valid;" class="btn w-50 btn-primary mx-2"  aria-controls="modalLogin">Acceder</button>
				<button type="button" class="btn w-50 btn-primary mx-2" data-bs-dismiss="offcanvas" aria-label="Close">Cerrar</button>
			</div>
			<mat-hint align="end" class="text-danger text-xs text-center" *ngIf="nid.errors && f.submitted">
				<div *ngIf="nid?.errors?.['required']">*Se requiere Usuario</div> 
			</mat-hint>
			<mat-hint align="end" class="text-danger text-xs text-center" *ngIf="pwd.errors && f.submitted">
					<div *ngIf="pwd?.errors?.['required']">*Se requiere contraseña</div>
					<div *ngIf="pwd?.errors?.['minlength']">
						La contraseña debe tener al menos 6 caracteres
					</div>
			</mat-hint>
			<div class="border-0 bg-transparent text-warning text-xs text-center" *ngIf="f.submitted && isLoginFailed">
					*Inicio de sesión fallida<br />{{ errorMessage }}
			</div>
		</form>
	</div>
	<div class="alert alert-success fs-6 text-center" *ngIf="isLoggedIn">
		Inicio de sessión activa
	</div>
</div>