import { Component } from '@angular/core';

@Component({
  selector: 'app-auditoria-interna',
  templateUrl: './auditoria-interna.component.html',
  styleUrls: ['./auditoria-interna.component.css']
})
export class AuditoriaInternaComponent {

}
