import { Component } from '@angular/core';

@Component({
  selector: 'app-estadistica-especializada',
  templateUrl: './estadistica-especializada.component.html',
  styleUrls: ['./estadistica-especializada.component.css']
})
export class EstadisticaEspecializadaComponent {

}
