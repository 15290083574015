import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';

@Component({
  selector: 'app-admin-board',
  templateUrl: './admin-board.component.html',
  styleUrls: ['./admin-board.component.css']
})
export class AdminBoardComponent implements OnInit {
  @HostBinding('class') classes = 'container-fluid';
  content:  any = [];
  modalRef?: BsModalRef;

  today: Date = new Date();
  options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  dateTime: string;




  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private tokenStorage: TokenStorageService
  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  this.dateTime = this.today.toLocaleString('es-ES', this.options);  }

  openModal() {
    this.modalRef = this.modalService.show('modal-content'); 
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  ngOnInit(): void {
    this.getDataAdminService();
  }

  getDataAdminService():void{
    
    if (!this.authService.isLoggedIn()) {
      this.openModal();
      this.router.navigate(['/']);
    } else {
      
      this.userService.getAdminBoard().subscribe(
        res => {
         this.content = res;
          
        
      },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }
  }
}
