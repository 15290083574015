import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { database } from  '../../models/database';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-insertcoor',
    template: `
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5">{{titulo}}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
            <mat-horizontal-stepper #stepper>
            <form action="">
                <mat-step [editable]="false" label="" completed="false">
                <div class="d-flex mt-2 ">
                <span class="badge rounded-pill w-100 text-white bg-primary p-3 rounded-4 justify-content-center">
                    Información Personal
                </span>
            </div>
            <div class="input-group gap-2 mb-3 border-0 bg-light rounded-2">
                <input type="text" class="form-control" id="fname" [(ngModel)]="student_db.fname" name="fname" placeholder="Ingresa el Nombre">
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" id="inputFlname" [(ngModel)]="student_db.lname" name="lname" placeholder="Ingresa el Apellido">
            </div>
            
            <div class="mb-3 gap-2">
               <input type="text" class="form-control" id="inputId" [(ngModel)]="student_db.student_id" name="student_id" placeholder="Cedula">
            </div>
            
            
            <div class="my-3 d-flex gap-5 justify-content-center">
                <p class="text-center">Sexo:</p>
                <div class="form-check">
                    <input class="form-check-input" type="radio"  [(ngModel)]="student_db.sex" name="sex" value="M" checked>
                    <label class="form-check-label" for="exampleRadios1">
                      Masculino
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio"  [(ngModel)]="student_db.sex" name="sex" value="F">
                    <label class="form-check-label" for="exampleRadios2">
                      Femenino
                    </label>
                </div>
            </div>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-sm rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;" (click)="stepper.next()"><i class="bi bi-arrow-right-circle"></i>&nbsp;Siguiente</button>
                    </div>
                </mat-step>
                <mat-step>
                    <div class="d-flex mt-2">
                        <span class="badge rounded-pill w-100 text-white bg-primary p-3 rounded-4 justify-content-center">
                            Información Maestría
                        </span>
                    </div>
                
                
                <div class="mb-3">
                    <select id="escuela" onclick="selectEMC();" [(ngModel)]="this.student_db.escuela" name="escuela"  class="form-select border-0 bg-light text-dark">
                        <option selected disabled>Seleccionar Escuela</option>
                    </select>
                </div>

                <div class="mb-3">
                    <select id="maestria" onclick="selectEMC();" [(ngModel)]="this.student_db.codigo" name="codigo" class="form-select border-0 bg-light text-dark">
                        <option selected disabled>Seleccionar Maestría</option>
                    </select>
                </div>
                <div class="mb-3">
                    <select id="coordinador"  class="form-select" [(ngModel)]="this.student_db.coor" name="coor" disabled>
                        <option selected disabled>Seleccionar Coordinador</option>
                    </select>
                </div>

                
               
                <div class="mb-3">
                    <select id="periodo"  class="form-select" [(ngModel)]="this.student_db.periodo" name="periodo">
                        <option selected disabled>Seleccionar Periodo</option>
                        <option value="2023-2025">2023-2025</option>
                        <option value="2024-2026">2024-2026</option>
                    </select>
                </div>
               
                
                <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-sm rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;"><i class="bi bi-floppy"></i>&nbsp;Guardar</button>
                    </div>
                </mat-step>
                </form>
            </mat-horizontal-stepper>
        </div>
        
           
        
        <div class="modal-footer border-1 justify-content-end">
            
            <button class="btn btn-sm mb-3 rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;" (click)="closeModal()"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill me-1" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
          </svg>&nbsp;Cerrar</button>
        </div>
      </div>`,
    styleUrls: ['./result-coor.component.css'],
    // imports: [CommonModule,MatStepperModule,FormsModule],
    
  
  })
  

  export class InsertCoorModal  implements OnInit {
  @Input() titulo?: string;

  @ViewChild('stepper')

  isEditable = false;

  now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()
    
	formattedDate = `${this.day}/${this.month}/${this.year}`
	student_db:database={
		student_id: '', 
        fname: '',
        lname: '',
        slname: '',
        nid: '',
        pwd: '',
        roles: 'Estudiante',
        email:	'tucorreo@gmail.com',
        date: this.formattedDate,
        sex: '',
        coor: '',
        codigo: 0,
        cr: 0,
        token: '',
        prefix: '',
        periodo: '', 
        escuela: '',
        campus: 'UASD-Santo Domingo', 
        nivel: 'Propedéutico', 
        dept: 'Unidad de Postgrado FCES', 
        permission: false,
        is_online: false
	}
    // messageSuccess?: any;

  constructor( public bsModalRef: BsModalRef,private userService: UserService, private router: Router) {}
      
  ngOnInit(): void { }

  setPrefix(fname:any,lname:any){
   const prefix = fname.substring(0,1).toUpperCase() + lname.substring(0,1).toUpperCase();
    this.student_db.prefix = prefix;
    
    return this.student_db.prefix
  }
  /*
  insertCoorService(): void {

    this.setPrefix(this.student_db.fname, this.student_db.lname)
    
    this.userService.createCoor(this.student_db).subscribe(
     (res: any[]) => {			
        
        // console.log(res);
        this.closeModal();
        window.location.reload();
        this.router.navigate(['/admin/resultstudents']);
          
     },
     err => console.error(err)
   );
  
  }
*/
    closeModal() {
        this.bsModalRef.hide();
    }
 
  }