import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { database } from '../../models/database';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css']
})
export class EquipoComponent {
  
  @HostBinding('class') classes = 'container-fluid';
status?: string;
 
  now = new Date()
 month = String(this.now.getMonth() + 1).padStart(2, '0')
 day = String(this.now.getDate()).padStart(2, '0')
 year = this.now.getFullYear()

 formattedDate = `${this.day}/${this.month}/${this.year}`

   content:  any = [];
   modalRef?: BsModalRef;
 
 today: Date = new Date();
 options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
 dateTime: string;
 pagedData: any[] = [];

 currentPage = 1;
 itemsPerPage = 10; 
 autoHide: boolean = false;
 totalItems!: number; 
 totalPages!: number; 
 showPreviousButton: boolean = false;
 showNextButton: boolean = true;


 constructor(
  private userService: UserService,
  private authService: AuthService,
  private router: Router,
  private modalService: BsModalService,
  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  this.dateTime = this.today.toLocaleString('es-ES', this.options);  }

  openModal() {
    this.modalRef = this.modalService.show('modal-content'); 
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  ngOnInit(): void {
		this.getAllTeamService();
	}
	
	getAllTeamService(): void{
		
		if (!this.authService.isLoggedIn()) {
       this.openModal();
		  this.router.navigate(['/']);
		} else {
		  
		  this.userService.getTeamBoard().subscribe(
        (res: any[]) => {

        this.content = res;
        this.totalItems = this.content.dataA.length;
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.pageChanged(1);
			
			},
			err => {
			  this.content = JSON.parse(err.error).message;
		
			}
		  );
		}
	 } 

   previousPage(): void {
    if (this.currentPage > 1) {
      this.pageChanged(this.currentPage - 1);
    }
  }
    
  nextPage(): void {
      if (this.currentPage < this.totalPages) {
        this.pageChanged(this.currentPage + 1);
      }
    
  }
  
  pageChanged(pageNumber: number): void {
      const startIndex = (pageNumber - 1) * this.itemsPerPage;
      let endIndex = startIndex + this.itemsPerPage;
    
      
      endIndex = Math.min(endIndex, this.totalItems);
    
      this.pagedData = this.content.dataA.slice(startIndex, endIndex);
      this.currentPage = pageNumber;
      this.showPreviousButton = this.currentPage > 1;
      this.showNextButton = this.currentPage < this.totalPages;
  }
}
