import { Component } from '@angular/core';

@Component({
  selector: 'app-contabilidad-tributaria',
  templateUrl: './contabilidad-tributaria.component.html',
  styleUrls: ['./contabilidad-tributaria.component.css']
})
export class ContabilidadTributariaComponent {

}
