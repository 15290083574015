<div class="container-fluid">
    <div class="row">
      <div class="col-2 border border-secundary bg-white">
        <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                  <li class="nav-item mb-2 py-2">
                    <a href="/coor" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>
<!--             
                    <li class="nav-item mb-2 py-2">
                      <a  href="/coor/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>
                    </li> -->
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li> -->
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/registro" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Registro
                      </a>
                    </li>

                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>
<!--           
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li> -->
          
                    
                      <li class="border-top border-light d-block my-3 mx-0"></li>
                    
                </ul>
              </div>
          </nav>
        </div>
      </div>
      <div class="col-10">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
            <div class="container">
              <div class="card-body rounded-3">
                <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ content.user.fname }} {{ content.user.lname }}</h3>
                <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
              </div>
            </div>
        </nav>
  
        <div class="card border-0 mt-2">          
          <div class="card-body">                
                <div class="row">
                    <div class="col"> 
                        <span class="fs-4 text-dark-text-start fw-bold">Evaluaciones</span>
                    </div>
                    <div class="col"> 
                        <div class="d-flex justify-content-end">
                          
                            
                            
                          <div class="d-flex gap-2">
                           <!-- Something is here -->
                            
                          </div>
                        </div>
                    </div>
                </div>
  
                <div class="row mt-2">
                    <div class="col">
                        <p class="text-sm text-muted ">Total de Evaluación: {{content.dataE }}</p>
                        <p class="text-sm text-muted fw-semibold  mt-2"></p>
                    </div>
                </div>
                <div class="row mt-2 rounded-3">
                    <div class="col">
             
                  <div class="col border border-1">
                      <div class="row row-cols-1 row-cols-md-1 fw-bold m-0">
                        <p class="fw-bold text-center">Evaluación Profesional</p>
                      </div>
                      <div class="d-flex table-responsive" style="font-family:sans-serif;">
                        <table class="table table-bordered table-striped table-hover">
                          <thead>
                       
                            <tr>
                              <th style="font-size: 0.6rem; text-align: center;">Asignaturas</th>
                              <th style="font-size: 0.6rem; text-align: center;">Clave</th>
                              <th style="font-size: 0.6rem; text-align: center;">Creditos</th>
                              <th style="font-size: 0.6rem; text-align: center;">Cantidad Evaluados</th>
                              <th style="font-size: 0.6rem; text-align: center;">Descargar PDF</th>
                              <th style="font-size: 0.6rem; text-align: center;">?</th>
                      
                         
                            </tr>
                          </thead>
                          <tbody class="table-group-divider">
                            <tr *ngFor="let m of content.JsonData.asignaturas; let i = index;">
                              <td class="fw-bold" style="font-size: 0.6rem;">{{ m.asignatura}} </td>
                              <td class="fw-bold" style="font-size: 0.6rem;">{{ m.clave}} </td>
                              <td class="fw-bold" style="font-size: 0.6rem;">{{ m.credito}} </td>
                              <td class="fw-bold" style="font-size: 0.6rem;">{{content.totalEE[i]}}</td>
                              <td style="text-align: center; font-size: 0.6rem;">
                                <button class="btn btn-sm h-50 px-3 w-100 rounded-3 mx-2" style="background-color: #f8ece3; color: #f47b20;" (click)="getpdfDataEval(content.user.student_id + '-' + m.asignatura);downloadPDF( content.user.student_id + '-' + m.asignatura);"> 
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
                                </svg>&nbsp; Descargar</button>
                              </td>
                              <td style="text-align: center; font-size: 0.6rem;">
                                <button class="btn btn-sm h-50 px-3 w-100 rounded-3 mx-2"  style="background-color: #f8ece3; color: #f47b20;" (click)="openViewEvalModal( m.asignatura, m.clave);" type="button">&nbsp;ver</button>
                              </td>
                            </tr>
                            <!-- <tr>
                              <td class="fw-bold"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                              <td style="text-align: center; font-size: 0.6rem;"></td>
                            </tr> -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>