import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {admision_db} from '../models/admision_db';
import {Observable} from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({'Content-Type' : 'application/json'})
}
@Injectable({
  providedIn: 'root'
})
export class AdmisionService {
 API_URI = 'https://uasd.postgradofces.com'

  constructor(private http:HttpClient) { }

  admision(admision:admision_db): Observable<any>{
  	return this.http.post(`${this.API_URI}/admision`,{admision}, httpOptions);
  }
   
}
