import { Component } from '@angular/core';

@Component({
  selector: 'app-empalme',
  templateUrl: './empalme.component.html',
  styleUrls: ['./empalme.component.css']
})
export class EmpalmeComponent {

}
