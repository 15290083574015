import { Component } from '@angular/core';

@Component({
  selector: 'app-alta-gerencia',
  templateUrl: './alta-gerencia.component.html',
  styleUrls: ['./alta-gerencia.component.css']
})
export class AltaGerenciaComponent {

}
