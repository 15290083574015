import { Component } from '@angular/core';

@Component({
  selector: 'app-negocios-relaciones-economicas',
  templateUrl: './negocios-relaciones-economicas.component.html',
  styleUrls: ['./negocios-relaciones-economicas.component.css']
})
export class NegociosRelacionesEconomicasComponent {

}
