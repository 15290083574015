import { Component } from '@angular/core';

@Component({
  selector: 'app-maestria-administracion-empresas',
  templateUrl: './maestria-administracion-empresas.component.html',
  styleUrls: ['./maestria-administracion-empresas.component.css']
})
export class MaestriaAdministracionEmpresasComponent {

}
