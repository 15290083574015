import { Component } from '@angular/core';

@Component({
  selector: 'app-sociologia-educacion',
  templateUrl: './sociologia-educacion.component.html',
  styleUrls: ['./sociologia-educacion.component.css']
})
export class SociologiaEducacionComponent {

}
