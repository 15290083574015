<div class="container-fluid">
    <div class="row">
      <div class="col-2 border border-secundary bg-white">
        <div class="d-block my-5">
          <nav class="navbar navbar-expand-lg">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">
                  <li class="nav-item mb-2 py-2">
                    <a href="/coor" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                      </svg> Dashboard
                    </a>
                  </li>
            
                    <!-- <li class="nav-item mb-2 py-2">
                      <a  href="/coor/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                          <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                        </svg> Notificaciones
                      </a>
                    </li> -->
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/equipo" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg> Equipo
                      </a>
                    </li> -->
                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/registro" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Registro
                      </a>
                    </li>

                    <li class="nav-item mb-2 py-2">
                      <a href="/coor/evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg> Evaluaciones
                      </a>
                    </li>
          
                    <!-- <li class="nav-item mb-2 py-2">
                      <a href="/coor/programas" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
                        </svg> Programa
                      </a>
                    </li> -->
          
                    
                      <li class="border-top border-light d-block my-3 mx-0"></li>
                    
                </ul>
              </div>
          </nav>
        </div>
      </div>
      <div class="col-10">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border boder-bottom">
            <div class="container">
              <div class="card-body rounded-3">
                <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ content.user.fname }} {{ content.user.lname }}</h3>
                <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
              </div>
            </div>
        </nav>
  
        <div class="card border-0 mt-2">          
          <div class="card-body">
                <div class="row">
                    <div class="col"> 
                        <span class="fs-4 text-dark-text-start fw-bold">Estudiantes</span>
                    </div>
                    <div class="col"> 
                        <div class="d-flex justify-content-end">
                          
                            
                            
                          <div class="d-flex w-100 gap-2">
                            <button class="btn btn-sm mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;" onclick="refreshPage(true);"><i class="bi bi-arrow-clockwise"></i>&nbsp;Refrescar</button>
                            <button class="btn btn-sm mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;" (click)="openInsertStudentModal('Agregar Estudiante', content.user.campus, content.user.escuela, content.user.codigo, content.user.coor);"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-add" viewBox="0 0 16 16">
                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                              <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
                            </svg>&nbsp;Agregar Estudiante</button>
                            
                          </div>
                        </div>
                    </div>
                </div>
  
  
  
                <div class="row mt-2">
                    <div class="col">
                        <p class="text-sm text-muted ">Total de Estudiantes: {{content.totalA}}</p>
                        <p class="text-sm text-muted fw-semibold  mt-2"></p>
                    </div>
                </div>
                <div class="row mt-2 rounded-3">
                    <div class="col">
                      <div class="row border border-light rounded-3 mt-2">
                           
                        <div class="col rem-7">
                            
                            <table class="table table-responsive-sm table-hover">
                              <thead class="bg-light" style="background-color: #f8fefe;border-radius: 10px;">
                                <tr>
                                  <th scope="col"><p class="text-start font-semibold text-heading text-xs">#</p></th>
                                  <th scope="col"><p class="text-start font-semibold text-heading text-xs">Cedula</p></th>
                                  <th scope="col"><p class="text-start font-semibold text-heading text-xs">Nombre</p></th>
                                  <th scope="col"><p class="text-start font-semibold text-heading text-xs">Información</p></th>
                         
                                  
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                <tr class="border border-light" *ngFor="let item of pagedData; let i = index">
                                  <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                  
                                  
                                  <th scope="row">
                                    <p class="text-start font-semibold text-heading text-muted text-xs">{{ item.nid }}</p>
                                  </th>
                                  <th scope="row">
                                    <p class="text-start font-semibold text-heading text-muted text-xs">{{ item.fname }} {{ item.lname }} {{ item.slname }}</p>
                                  </th>
  
                                                                                                        
                         
                                  
                                  <td class="d-flex flex-column-reverse flex-wrap justify-content-center align-items-center font-semibold text-heading text-muted text-xs">
                                    <button class="btn btn-sm h-50 px-3 w-100 rounded-3 mx-2"  style="background-color: #f8ece3; color: #f47b20;" type="button" (click)="openViewCoorStudentModal(item.fname , item.lname + ' ' + item.slname, item.student_id, item.periodo, item.prefix, content.user.coor );">
                                      <i class="bi bi-info-circle"></i>&nbsp;Información</button>
                                  </td>
                                  
                                </tr>
                              </tbody>
                            </table>
                            <div class="d-flex justify-content-center gap-2">
                              <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="previousPage()" [hidden]="!showPreviousButton"><i class="bi bi-arrow-left-circle"></i> Anterior</button>
                              <button class="btn btn-sm  rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="nextPage()" [hidden]="!showNextButton"><i class="bi bi-arrow-right-circle"></i> Siguiente</button>
                            </div>
                            
                        </div>
                        
                      </div>
                    </div>
                </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>