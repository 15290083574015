import { Component } from '@angular/core';

@Component({
  selector: 'app-relacion-de-los-destinos',
  templateUrl: './relacion-de-los-destinos.component.html',
  styleUrls: ['./relacion-de-los-destinos.component.css']
})
export class RelacionDeLosDestinosComponent {

}
