import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { database } from '../../models/database';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-result-notification',
  templateUrl: './result-notification.component.html',
  styleUrl: './result-notification.component.css'
})
export class ResultNotificationComponent implements OnInit{
   @HostBinding('class') classes = 'container-fluid';
	
	
   	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()

	formattedDate = `${this.day}/${this.month}/${this.year}`

  	content:  any = [];
    modalRef?: BsModalRef;
  
	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;

	constructor(
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
    private modalService: BsModalService,
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);  }
	
	 
    openModal() {
      this.modalRef = this.modalService.show('modal-content'); 
    }
    closeModal() {
      if (this.modalRef) {
        this.modalRef.hide();
      }
    }
	 
	ngOnInit(): void {
		this.getAllNotificacionService();
	}
	
	getAllNotificacionService(): void{
		
		if (!this.authService.isLoggedIn()) {
      this.openModal();
		  this.router.navigate(['/']);
		} else {
		  
		  this.userService.getNotyBoard().subscribe(
		  res  => {
			this.content = res;
			},
			err => {
			  this.content = JSON.parse(err.error).message;
			 
			}
		  );
		}
	 } 

}
