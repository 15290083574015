import { Component } from '@angular/core';

@Component({
  selector: 'app-finanzas-mercado',
  templateUrl: './finanzas-mercado.component.html',
  styleUrls: ['./finanzas-mercado.component.css']
})
export class FinanzasMercadoComponent {

}
