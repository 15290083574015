import { Component } from '@angular/core';
import { TokenStorageService } from './services/token-storage.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'FCES';

  private roles: string[] = [];
	
  isLoggedIn = false;
  showAdminBoard = false;
  showCoorBoard = false;
  showUserBoard = false;
  showAuditorBoard = false;
  username?: string;
  user_id?: string;
  redirectURL?: string;
 
  

 constructor(private tokenStorageService: TokenStorageService,private router: Router, private authService: AuthService) { }
 
 

 ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ADMIN');
      this.showUserBoard = this.roles.includes('Estudiante');
      this.showAuditorBoard = this.roles.includes('AUDITOR');
      this.showCoorBoard = this.roles.includes('COORDINADOR');
      this.username = user.prefix;
      this.user_id = user.student_id
      
      if(this.showAdminBoard == true){
        this.redirectURL = "/admin";
        // this.router.navigate(['/admin']);
    
      }
      if (this.showUserBoard == true) {
         this.redirectURL = "/user";
        //  this.router.navigate(['/user']);
    
      } 
      if (this.showAuditorBoard == true) {
        this.redirectURL = "/auditor";
        // this.router.navigate(['/auditor']);
      } 
      if (this.showCoorBoard == true) {
        this.redirectURL = "/coor";
        // this.router.navigate(['/auditor']);
      } 


    }
  }
/*
  logout(): void {
    this.tokenStorageService.signOut();
    window.location.replace("/");
  } */
  logout(): void {
    this.authService.logout(`${this.user_id}`).subscribe(
      (res:any[]) => {
     
        this.tokenStorageService.signOut();
        window.location.replace("/");
      },
      error => {
    
        console.error('Error en el logout:', error);
      }
    );
  }

	redirectToLoginSubdomain() {
    // const loginSubdomainURL = 'https://uasd.postgradofces.com/auth';
    // this.router.navigate([loginSubdomainURL]);
    
    // window.location.replace("https://uasd.postgradofces.com");
    
  }
  

}
