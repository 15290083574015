import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-publica',
  templateUrl: './gestion-publica.component.html',
  styleUrls: ['./gestion-publica.component.css']
})
export class GestionPublicaComponent {

}
