import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { database } from '../models/database'
import { Observable } from 'rxjs'
import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
  'Cache-Control': 'no-cache' })};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private tokenStorage: TokenStorageService,private http:HttpClient) { }

  isLoggedIn(): boolean {
    
    return !!this.tokenStorage.getToken();
  }


API_URI =  'https://uasd.postgradofces.com'

  
  login(login:database):Observable<any>{
	return this.http.post(`${this.API_URI}/login`,{login},httpOptions)
}
 /** register(register:database): Observable<any>{
		return this.http.post(`${this.API_URI}/register`,{register},httpOptions)
	} */

  logout(id:string): Observable<any> {
    return this.http.post<any>(`${this.API_URI}/logout/${id}`,{});
  }
}

