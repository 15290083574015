import { Component, OnInit  } from '@angular/core';
import { UserService } from '../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-audi-board',
  templateUrl: './audi-board.component.html',
  styleUrls: ['./audi-board.component.css']
})
export class AudiBoardComponent implements OnInit {

  content: SafeHtml | undefined;
  constructor(private userService: UserService,private sanitizer: DomSanitizer,private http: HttpClient) { }


  
    ngOnInit(): void {
    
    this.userService.getAuditorBoard().subscribe((
        data:any) => {
          this.content = this.sanitizer.bypassSecurityTrustHtml(data);
       
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }
}
