import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-logistica',
  templateUrl: './gestion-logistica.component.html',
  styleUrls: ['./gestion-logistica.component.css']
})
export class GestionLogisticaComponent {

}
