import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';
import * as datos from '../dataMaestrias.json';



@Component({
  selector: 'app-programa',
  templateUrl: './programa.component.html',
  styleUrls: ['./programa.component.css']
})
export class ProgramaComponent {


	content:  any = [];

  
  dataFileMaestria?:any;

	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;

  constructor(
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);}


  ngOnInit(): void {
	
	this.userService.getProgramBoard().subscribe((
      res:any[]) => {
        this.content = res;

      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
  }
}