import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { database } from  '../../models/database';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {evaluation_db} from '../../models/evaluation_db';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-viewEvalModal',
    template: `
  

    <div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title fs-5">Lista de Evaluaciones</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
  
      <div class="modal-body d-flex justify-content-center font-print" style="width: 85%;font-family: sans-serif;">
        <p class="text-danger" style="font-size: 0.6rem;">Aun Pendiente!, aqui estara la lista de estudiates que evaluaron y no evaluaron</p>
        <div class="d-block">
          <p class="text-success">{{materia}}</p>
           <p class="text-success">{{clave}}</p>
          
        </div>  
      </div>

    <div class="modal-footer border-1">
        <div class="d-flex justify-content-center gap-2">
        <button type="submit" class="btn btn-sm mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;  font-size: 0.9rem;" onclick="">&nbsp;Enviar</button>

          <button class="btn btn-sm mb-3 rounded-3" style="background-color: #f8ece3;color:#f47b20;" (click)="closeModal()">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill me-1" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>Cerrar
          </button>
        </div>
    </div>
</div>


    `,
    styleUrls: ['./coor-eval.component.css'],
     
  
  })

  export class ViewEvalModal  implements OnInit {
    @Input() materia?: string;
    @Input() clave?: string;


    isEditable = false;
  
    content:  any = [];

    now = new Date()
      month = String(this.now.getMonth() + 1).padStart(2, '0')
      day = String(this.now.getDate()).padStart(2, '0')
      year = this.now.getFullYear()
      
      formattedDate = `${this.day}/${this.month}/${this.year}`
      
      evaluation_db:evaluation_db={
        fname: '', 
        lname:'',
        email: '', 
        programa: '', 
        asignatura: '',
        clave: '',
        modulo: '', 
        periodo: '',
        coordinador: '',
        fecha: '', 
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        p10:0,
        p11:0,
        p12:0,
        p13:0,
        p14:0,
        p15:0,
        p16:0,
        p17:0,
        p18:0,
        p19:0
      } 
    //   messageSuccess?: any;
    pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 5; 
    autoHide: boolean = false;
    totalItems!: number; 
    totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;
  
    constructor( public bsModalRef: BsModalRef,private userService: UserService, private router: Router,private authService: AuthService) {}
        
    ngOnInit(): void {
     //  this.setEvaluationPDF();
    }
  
   /*

      setEvaluationPDF():void{
       
        if (!this.authService.isLoggedIn()) {
            // this.openModal();
            this.router.navigate(['/']);
          } else {
            
            this.userService.sendEvaluationPDF().subscribe(
            (res: any[]) => {
            this.content = res;
            //   this.registro_db.student_id = this.xstudent_id;
            //   this.registro_db.periodo = this.xperiodo;

              this.totalItems = this.content.dataA.length;
              this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
              this.pageChanged(1);
            },
            err => {
              this.content = JSON.parse(err.error).message;
            }
            );
          }
      } 
      */
      
      /* 

      setCoorRStudentService():void{
      
        if (!this.authService.isLoggedIn()) {
          // this.openModal();
          this.router.navigate(['/']);
        } else {
          this.userService.createCoorRStudent(this.registro_db).subscribe(
          (res: any[]) => {
            this.content = res;
            this.bsModalRef.hide();
            
          },
          err => {
            this.content = JSON.parse(err.error).message;
          }
          );
        }
        
       }
       */

      previousPage(): void {
        if (this.currentPage > 1) {
          this.pageChanged(this.currentPage - 1);
        }
      }
        
      nextPage(): void {
          if (this.currentPage < this.totalPages) {
            this.pageChanged(this.currentPage + 1);
          }
        
      }
      
      pageChanged(pageNumber: number): void {
          const startIndex = (pageNumber - 1) * this.itemsPerPage;
          let endIndex = startIndex + this.itemsPerPage;
        
          
          endIndex = Math.min(endIndex, this.totalItems);
        
          this.pagedData = this.content.dataA.slice(startIndex, endIndex);
          this.currentPage = pageNumber;
          this.showPreviousButton = this.currentPage > 1;
          this.showNextButton = this.currentPage < this.totalPages;
      }
    
      closeModal() {
          this.bsModalRef.hide();
      }
   
    }