import { Component } from '@angular/core';

@Component({
  selector: 'app-maestria-direccion-estrategica',
  templateUrl: './maestria-direccion-estrategica.component.html',
  styleUrls: ['./maestria-direccion-estrategica.component.css']
})
export class MaestriaDireccionEstrategicaComponent {

}
