import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { TokenStorageService } from '../../services/token-storage.service';

import { database } from '../../models/database'


@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrls: ['./estudiantes.component.css']
})
export class EstudiantesComponent {
  @HostBinding('class') classes = 'itemLogin'
  login:database={
	nid:	'',
	pwd:	'',
	roles: ''
	}
 TOKEN_KEY = 'auth-token';
 USER_KEY = 'auth-user';
  content : string | undefined
  hide = false;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = ''
 roles: string[] = [];

 constructor(private authService: AuthService,private tokenStorage: TokenStorageService) { }

 ngOnInit(): void {
	if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  onSubmit():void{
		this.authService.login(this.login).subscribe(
		res => {
				this.login = res
				this.tokenStorage.saveToken(res.token);
				this.tokenStorage.saveUser(res);

				this.isLoginFailed = false;
        		this.isLoggedIn = true;	
        		this.roles = this.tokenStorage.getUser().roles;
        	
				this.reloadPage();
   		},
		err => {
			this.errorMessage = err.error.message
			this.isLoginFailed = true;
		})
	}

  reloadPage(): void {
    window.location.reload();
  }

}
