import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';
import { admision_db } from '../../models/admision_db';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-dialog-admision',
    template: `
    <form action="">
    <div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title fs-5">{{programa}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
    <div class="border border-1 border-dark p-2">

    <div class="row m-0 p-0">
      <div class="col">
        <div class="d-flex">
          <a class="align-items-start  text-decoration-none mx-0" style="display: flex;">
            <div style="float: left;">
              <img src="https://postgradofces.com/assets/img/logo.png" class="bi pe-none me-2" width="100" alt="Facultad de Ciencias &amp; Sociales (FCES)" height="110">
            </div>
            <div class="d-flex text-center mt-4" style="flex-direction: column;">
              <p class="font-print m-0" style="text-decoration: none; color: #022e57;" class="fw-bold">UNIVERSIDAD AUTÓNOMA DE SANTO DOMINGO</p>  
              <p class="font-print fw-semibold m-0" style="text-decoration: none; color: #022e57;">Facultad de Ciencias Económicas & Sociales</p>
              <p class="font-print m-0" style="text-decoration: none; color: #022e57;">DIVISIÓN DE POSTGRADO</p> 
              <p class="font-print m-0" style="text-decoration: none; color: #022e57;  font-size: 0.6rem;">(FCES)</p> 
            </div>
          </a>
        </div>
        <div class="d-flex justify-content-between m-0">
          <p class="float-start fw-bold m-0">SOLICITUD DE ADMISIÓN</p><p class="text-center border-top border-end border-start border-1 border-dark ps-1 m-0" style="font-size: 0.7rem;">Fecha: {{ fecha }}</p>
        </div>
        <div class="d-block align-items-start bg-secondary pb-2 bg-gris border border-1 border-dark ps-1 mt-0">
          <p class="fw-bold ps-1">PROGRAMA A REALIZAR:</p>
          <p class="fw-semibold ps-1" style="font-size: 0.9rem;">{{ programa }}</p>
        </div>
      </div>
      <div class="col-4 border-start border-end border-top border-dark m-0 p-0">
        <div class="d-block text-center border-bottom border-1 border-dark">
          <p style="font-size: 0.8rem;">Uso de la DP-FCES</p>
        </div>
        <div class="d-flex">
          <p style="height: 170px;"></p>
        </div>
        <div class="border-top border-1 border-dark">
          <div class="col">
            <p class="ps-1" style="font-size: 0.7rem;">Horarios:  {{ tanda }}</p>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center border border-1 border-dark">
          <p class="fw-bold m-0">Información Personal</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
    
        <div class="d-block text-start border-bottom border-start border-dark">
          <p class="fw-bold me-2 ps-1 m-0" style="font-size: 0.9rem;">Nombre y Apellidos:</p>
          <p class="me-2 ps-1 m-0" style="font-size: 0.7rem;">{{ fname }}  {{ lname }}</p>
        </div>
      </div>
      <div class="col pe-0 ps-0">
    
        <div class="d-block text-start border-bottom border-dark">
          <p class="text-start fw-bold m-0" style="font-size: 0.9rem;">Cedula:</p>
          <p class="m-0" style="font-size: 0.7rem;"> {{ cedula }} </p>
        </div>
      </div>
      <div class="col pe-0 ps-0">
      <div class="d-block text-start border-bottom border-end border-dark">
        <p class="fw-bold m-0" style="font-size: 0.9rem;">Correo Electrónico:</p>
        <p class="m-0" style="font-size: 0.7rem;"> {{ email }}</p>
      </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-start border-bottom border-dark pe-0">
          <p class="ps-1 fw-bold m-0">Teléfonos:</p>
          <p class="ps-1 m-0" style="font-size: 0.8rem;">Casa: N/A</p>
        </div>
      </div>
      <div class="col m-0 p-0">
        <div class="d-block text-start border-bottom border-dark m-0 p-0">
          <p class="text-white m-0">.</p>
          <p class="m-0" style="font-size: 0.8rem;">Oficina: N/A</p>
        </div>
      </div>
      <div class="col m-0 p-0">
        <div class="d-block text-start border-bottom border-end border-dark">
          <p class="text-white m-0">.</p>
          <p class="m-0" style="font-size: 0.8rem;">Celular: {{ cel }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col">
        <div class="d-block text-start border-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Estado Civil:</p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ estado_civil }}</p>
        </div>
      </div>
      <div class="col">
        <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Dependientes:</p>
          <p  class="m-0" style="font-size: 0.7rem;">{{ dependientes }}</p>
        </div>
      </div>
      <div class="col">
        <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Sexo</p>
          <p class="m-0" style="font-size: 0.7rem;"> {{ sexo }}</p>
        </div>
      </div>
      <div class="col">
        <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Fecha Nac.</p>
          <p class="m-0" style="font-size: 0.7rem;"> {{ fecha_nac }}</p>
        </div>
      </div>
      <div class="col pe-0">
        <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Lugar de Nac.</p>
          <p class="m-0" style="font-size: 0.7rem;"> {{ lugar_nac }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center border border-1 border-dark">
          <p class="fw-bold m-0">Información Laboral</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-start border-end border-bottom border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Lugar de Trabajo: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem">{{ lugar_trabajo }}</p>
        </div>
        <div class="d-block text-start border-start border-end border-1 border-dark">
          <div class="row">                                        
            <div class="col">
              <div class="d-block border-end border-dark">
                  <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Ingresos Mensuales: </p>
                  <p class="ps-1 m-0" style="font-size: 0.7rem;">$ {{ ingresos }}</p>
              </div>
            </div>
            <div class="col">
                <div class="d-block">
                    <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Otros Ingresos: </p>
                    <p class="text-start ps-1 m-0" style="font-size: 0.7rem;">$ {{ otros_ingresos }}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col ps-0 pe-0">
        <div class="d-block text-start border-end border-bottom border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Dirección: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ direccion }}</p>
        </div>
      <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Ocupación: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ ocupacion }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center border border-1 border-dark">
          <p class="fw-bold m-0">Información Académica</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-start border-end border-bottom border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Profesión: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ profesion }}</p>
        </div>
      <div class="d-block text-start border-start border-end border-1 border-dark">
        <div class="row">                                        
        <div class="col">
        <div class="d-block border-end border-dark">
            <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Universidad de Procedencia:</p>
            <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ uni_procedencia }}</p>
        </div>
    </div>
  </div>
      </div>
    </div>
      <div class="col ps-0 pe-0">
        <div class="d-block text-start border-end border-bottom border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Grado Académico: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;">{{ grado_academico }}</p>
        </div>
      <div class="d-block text-start border-end border-1 border-dark">
          <p class="fw-bold ps-1 m-0" style="font-size: 0.9rem;">Idioma que Domina: </p>
          <p class="ps-1 m-0" style="font-size: 0.7rem;"> {{ idiomas }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center border-top border-start border-end border-1 border-dark">
          <p class="fw-bold m-0">Referencias Personales</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center border-top border-start border-end border-1 border-dark">
          <p class="m-0">Nombres</p>
        </div>
      </div>
    <div class="col pe-0 ps-0">
        <div class="d-block text-center border-top border-start border-end border-1 border-dark">
          <p class="m-0">Dirección</p>
        </div>
      </div><div class="col pe-0 ps-0 ">
        <div class="d-block text-center border-top border-start border-end border-1 border-dark">
          <p class="m-0">Teléfonos</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_nom_1 }}</p>
        </div>
      </div>
    <div class="col pe-0 ps-0">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_addr_1 }}</p>
        </div>
      </div><div class="col pe-0 ps-0 ">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_tel_1 }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_nom_2 }}</p>
        </div>
      </div>
    <div class="col pe-0 ps-0">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_addr_2 }}</p>
        </div>
      </div><div class="col pe-0 ps-0 ">
        <div class="d-block text-start border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_tel_2 }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-start border-bottom border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_nom_3 }}</p>
        </div>
      </div>
    <div class="col pe-0 ps-0">
        <div class="d-block text-start border-bottom border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_addr_3 }}</p>
        </div>
      </div><div class="col pe-0 ps-0 ">
        <div class="d-block text-start border-bottom border-top border-start border-end border-1 border-dark">
          <p class="m-0" style="font-size: 0.7rem;text-align: center;">.{{ ref_tel_3 }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0 justify-content-center">
      <div class="col-10 pe-0">
        <div class="d-block text-center border-top border-start border-end border-1 border-dark">
          <p class="fw-bold m-0">Documentos Para Admisión</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0">
        <div class="col-1 pe-0"></div>
        <div class="col-5 pe-0" style="font-size: 0.6rem;">
          <div class="d-block text-start pt-2 pb-2 border-top border-start border-bottom border-end border-1 border-dark">
            <p class="ps-2 m-0">1. Copia de Titulo Certif. MESCYT</p>
            <p class="ps-2 m-0">2. Record de Notas MESCYT</p>
            <p class="ps-2 m-0">3. Carta de Grado MESCYT</p>
            <p class="ps-2 m-0">4. Acta de Nacimiento Original</p></div>
        </div>
          <div class="col-5 pe-0 ps-0" style="font-size: 0.6rem;">
            <div class="d-block text-start pt-2 pb-2 border-top border-start border-bottom border-end border-1 border-dark">
              <p class="ps-2 m-0">5. 2 Fotos 2x2</p>
              <p class="ps-2 m-0">6. Hoja de Vida</p>
              <p class="ps-2 m-0">7. Copia de Cédula a Color o Pasaporte</p>
              <p class="ps-2 m-0">8. Certificado Médico Expedido UASD</p>
            </div>
          </div>
          <div class="col-1 pe-0 ps-0"></div>
    </div>

    <div class="row m-0 p-0">
      <div class="col pe-0">
        <div class="d-block text-center p-0 m-0">
          <p class="fw-bold" style="font-size: 0.4rem;">NOTA: Si realizó una maestría o especialidad en la UASD, solamente debe depositar copia del título de especialidad o maestría, dos (2) fotos 2x2, copia de cédula a color y Certificado Médico UASD</p>
        </div>
      </div>
    </div> 

      </div>
    </div>   
  </div>
    
    <div class="modal-footer border border-1 gap-2 justify-content-center">
      <button type="submit" class="btn rounded-3" style="background-color: #f8ece3;color:#f47b20;  font-size: 0.9rem;" onclick="window.print();">&nbsp;Imprimir</button>
      
      <button type="button" class="btn rounded-3 justify-content-md-start" style="background-color: #f8ece3; color: #f47b20; width: auto; font-size: 0.9rem;" onclick="refreshPage(true);" data-bs-dismiss="modal">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill me-1" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
        </svg>&nbsp;Cerrar
      </button>
    </div>
    
  </div>    
  </form>
    `,
    styleUrls: ['./result-admision.component.css']
  })

  export class DialogAdmision  implements OnInit{
    @Input() programa?: string;

    @Input() fecha?: string;
    @Input() tanda?: string;
    @Input() fname?: string;  
    @Input() lname?: string;
    @Input() cedula?: string;  
    @Input() email?: string; 
    @Input() cel?: string; 
    @Input() estado_civil?: string; 
    @Input() dependientes?: string; 
    @Input()  sexo?: string; 
    @Input()  fecha_nac?: string; 
    @Input()  lugar_nac?: string; 
    @Input() lugar_trabajo?: string; 
    @Input()  ingresos?: string; 
    @Input()  otros_ingresos?: string;
    @Input()  direccion?: string; 
    @Input() ocupacion?: string; 
    @Input() profesion?: string; 
    @Input() uni_procedencia?: string;
    @Input()  grado_academico?: string; 
    @Input()  idiomas?: string; 
    @Input()  ref_nom_1?: string; 
    @Input()  ref_addr_1?: string; 
    @Input()  ref_tel_1?: string; 
    @Input()  ref_nom_2?: string; 
    @Input()  ref_addr_2?: string; 
    @Input()  ref_tel_2?: string; 
    @Input()  ref_nom_3?: string; 
    @Input() ref_addr_3?: string;
    @Input() ref_tel_3?: string; 


    content:  any = [];
    
    
    constructor( public bsModalRef: BsModalRef,private userService: UserService, private router: Router) {}
        
    ngOnInit(): void { }
  
    closeModal() {
        this.bsModalRef.hide();
    }
  }