<div class="container-fluid">
    <div class="row row-cols-1 row-cols-md-2 text-center">
        <div class="col-md-6 text-start mt-5 d-flex flex-column p-5">
          <h5 class="mb-2" style="color: #fba000;">Conoce nuestras ofertas extracurriculares</h5>
            <h3 class="fw-semibold my-0" style="text-wrap: balance;">Educación Continuada</h3>
            <p class="my-2" style="font-size:0.9rem; color: #8d8d8d; text-align: justify;">En un mundo en constante evolución, la adquisición de nuevos conocimientos y habilidades se vuelve fundamental para destacar en el ámbito profesional.</p>
            <p class="my-2" style="font-size:0.9rem; color: #8d8d8d; text-align: justify;">En nuestra facultad, reconocida por su excelencia académica y compromiso con la formación integral, ofrecemos una variedad de programas de educación continua que abarcan diferentes áreas de las ciencias económicas y sociales.</p>
            <p class="my-2" style="font-size:0.9rem; color: #8d8d8d; text-align: justify;">Desde cursos cortos hasta diplomados especializados, te brindamos la oportunidad de actualizar tus competencias y mantenerte al día con las últimas tendencias y prácticas en tu campo de interés.</p>
        </div>
        <div class="col">
            <img src="/assets/img/img-edu-continua-model-1.webp" alt="" class="img-fluid p-5 pt-0">
        </div>
    </div>

    <div class="container-fluid">
        <h2 class="fw-bold text-center p-2" style="color: #F47B20;">Cursos y diplomados activos</h2>
        <div class="content py-0 my-0">
          <div class="card-body rounded-3">
            <div class="row row-cols-1 row-cols-md-3 mb-3">
              <div class="col">
                <div class="card rounded-4" href="#" style="width: 16rem;">
                  <div class="text-center rounded-top" style="background-color: #AFAFAF;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="180" fill="#fff" class="bi bi-image-fill" viewBox="0 0 16 16">
                      <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"></path>
                    </svg>
                  </div>
                  <div class="card-body">
                    <p class="card-text" style="font-size: 0.8rem;">Diplomado</p>
                    <h5 class="card-title">Community Management</h5>

                    <div class="d-block py-2">
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Domingos d 9 am. a 5 pm.</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z"/>
                          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">64 horas</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Modalidad Virtual</p>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center">
                      <button type="button" class="btn rounded-5 w-75" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;">Ir al programa</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card rounded-4" href="#" style="width: 16rem;">
                  <div class="text-center rounded-top" style="background-color: #AFAFAF;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="180" fill="#fff" class="bi bi-image-fill" viewBox="0 0 16 16">
                      <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"></path>
                    </svg>
                  </div>
                  <div class="card-body">
                    <p class="card-text" style="font-size: 0.8rem;">Diplomado</p>
                    <h5 class="card-title">Community Management</h5>

                    <div class="d-block py-2">
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Domingos d 9 am. a 5 pm.</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z"/>
                          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">64 horas</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Modalidad Virtual</p>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center">
                      <button type="button" class="btn rounded-5 w-75" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;">Ir al programa</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card rounded-4" href="#" style="width: 16rem;">
                  <div class="text-center rounded-top" style="background-color: #AFAFAF;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="180" fill="#fff" class="bi bi-image-fill" viewBox="0 0 16 16">
                      <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"></path>
                    </svg>
                  </div>
                  <div class="card-body">
                    <p class="card-text" style="font-size: 0.8rem;">Diplomado</p>
                    <h5 class="card-title">Community Management</h5>

                    <div class="d-block py-2">
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Domingos d 9 am. a 5 pm.</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z"/>
                          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">64 horas</p>
                      </div>
                      <div class="d-flex pb-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16" style="color: #F47B20;">
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
                        </svg>
                        <p class="card-text" style="font-size: 0.8rem;">Modalidad Virtual</p>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center">
                      <button type="button" class="btn rounded-5 w-75" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;">Ir al programa</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>        
        </div>
    </div>
        
        
       
    </div>

    <div class="content">
      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col p-5">
          <div class="d-block">
            <p class="fs-6 text-start" style="color: #F47B20;">Requisitos para cursar programas</p>
            <h3 class="fw-bold text-start">Público en general</h3>
          </div>
          <p class="text-sm text-start p-2 text-body-secondary">Para ser considerado como candidato, debes cumplir con los siguientes requisitos:</p>
          
          <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
            <div class="list-group text-white gap-2 w-100">
              <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                </svg>
                <div>
                    <p class="mb-0">2 Fotos 2x2.</p>
                </div>
              </div>
              <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                </svg>
                <div>
                    <p class="mb-0">Fotocopia de la Cédula a color.</p>
                </div>
              </div>
              <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                </svg>
                <div>
                    <p class="mb-0">Hoja de Vida (Curriculum Vitae).</p>
                </div>
              </div>
              <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                </svg>
                <div>
                    <p class="mb-0">Certificado Médico UASD.</p>
                </div>
              </div>
            </div>
        </div>
        </div>
        <div class="col d-flex felx-column">
            <div class="d-flex opacity-75 rounded-4 ms-5" style="background-color:#AFAFAF; width: 27rem; height: 25rem; align-items: end;justify-content: end;">
                <div class="d-flex rounded-4" style="background-color: #737373; width: 17rem; height: 15rem;margin-right: -60px;margin-bottom: -65px;"></div>
            </div>
        </div>
    </div>
    </div>

    <div class="content pt-0 mt-0">
      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col d-flex flex-column align-items-start gap-2 p-5">
              <div class="row row-cols-1 row-cols-md-2 text-center">
                <div class="col list-group px-0">
                    <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-bottom" style="flex-direction: column;align-items: center; height: 15rem;">
                        <img src="/assets/icon/location.webp" width="52" height="52">

                      <div>
                        <p class="text-sm mb-0">Dirección</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">6to Piso, Torre Adm.</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">UASD, SEDE.</p>
                      </div>
                    </div>
                    <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-top" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                        <div class="d-flex gap-2 mb-0" style="flex-direction: column;align-items: center; height: 15rem; width: max-content; margin-right: 4rem;">
                            <img src="/assets/icon/reloj.webp" width="52" height="52"> 
                          <div>
                            <p class="text-sm mb-0">Horario de oficina</p>
                            <p class="text-sm mb-0" style="font-size: 0.7rem;">Días de semana: 8:00 – 7:00 p.m.</p>
                            <p class="text-sm mb-0" style="font-size: 0.7rem;">Sábados y Domingos: Cerrado</p>
                          </div> 
                        </div>
                    </div>
                </div>
                <div class="col list-group">
                    <div class="d-flex gap-2 mb-0 border-dark border-start border-bottom" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                        <div class="d-flex gap-2 mb-0 p-4" style="flex-direction: column;align-items: center; height: 15rem;">
                        <img src="/assets/icon/mail.webp" width="52" height="52">                                                              
                          <div>
                            <p class="text-sm mb-0">Email</p>
                            <a class="text-sm mb-0" style="font-size: 0.7rem;">postgradofces&#64;uasd.edu.do</a>
                          </div> 
                        </div>
                    </div>
                    <div class="d-flex gap-2 mb-0 p-4 border-dark border-start border-top" style="flex-direction: column;align-items: center; height: 15rem;">
                        <img src="/assets/icon/phone.webp" width="52" height="52">
                      <div>
                        <p class="text-sm mb-0">Teléfono</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">(809) 233-3333</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">(809) 754-6985</p>
                      </div>
                    </div>
                </div>
              </div>
        </div>
        <div class="col">
          <h3 class="fw-semibold fs-6 text-start" style="color:#F47B20">Necesitas otra informacion</h3>
          <h3 class="text-dark text-start fw-bold">Contáctanos, ¡Estamos aquí!</h3>
            <div class="mb-3">
              <div>
                <div class="mb-2 d-flex">
                    <input type="text" name="ctrlFormNombre"  class="me-1 p-2 fs-6 form-control rounded-6 border border-dark border-1" placeholder="Nombre Completo" required>
                    <input type="text" name="ctrlFormCorreo" class="p-2 fs-6 form-control rounded-6 border border-dark border-1" placeholder="Correo Electrónico" required>
                </div>
                <div class="mb-2 d-flex">
                    <input type="text" name="ctrlFormContacto" class=" me-1 p-2 fs-6 form-control rounded-6 border border-dark border-1" placeholder="Numero de contacto" required>
                    <input type="text" name="ctrlFormTema" class="p-2 fs-6 form-control rounded-6 border border-dark border-1" placeholder="Tema" required>
                </div>
                <div class="mb-2">
                    <textarea class="form-control rounded-6 border border-dark border-1" placeholder="Escribe tu mensaje aquí" id="floatingTextarea2" style="height: 100px"></textarea>
                </div>                              
                <div class="col-md-6 mx-auto">
                    <button type="button" class="btn btn-primary">Enviar</button>
                    <div class="flex">
                      <p class="text-danger" style="font-size: 0.6rem;">*No disponible</p>
                  </div>
                </div>               
              </div>
            </div>
        </div>
      </div>
    </div>
