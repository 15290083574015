import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-analisis-estadisticas',
  templateUrl: './gestion-analisis-estadisticas.component.html',
  styleUrl: './gestion-analisis-estadisticas.component.css'
})
export class GestionAnalisisEstadisticasComponent {

}
