import { Component } from '@angular/core';

@Component({
  selector: 'app-gerencia-financiera',
  templateUrl: './gerencia-financiera.component.html',
  styleUrls: ['./gerencia-financiera.component.css']
})
export class GerenciaFinancieraComponent {

}
