import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-calificacion',
  standalone: true,
  templateUrl: './user-calificacion.component.html',
  styleUrl: './user-calificacion.component.css',
  imports: [
    CommonModule
  ]
})
export class UserCalificacionComponent implements OnInit{
	@HostBinding('class') classes = 'container-fluid';
	content:  any = [];
	modalRef?: BsModalRef;
  
	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;

	pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 10; 
  	autoHide: boolean = false;
  	totalItems!: number; 
  	totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;

	constructor( private userService: UserService,
	  private authService: AuthService,
	  private router: Router,
	  private modalService: BsModalService,
	  private tokenStorage: TokenStorageService) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);}
  
	  
	openModal():void {
	  this.modalRef = this.modalService.show('modal-content'); 
	}
  
	closeModal():void {
	  if (this.modalRef) {
		this.modalRef.hide();
	  }
	}
  
	ngOnInit(): void {
	  this.getDataUserCalificacionService();
	}
  
  
	getDataUserCalificacionService():void{
	  
	  if (!this.authService.isLoggedIn()) {
		this.openModal();
		this.router.navigate(['/']);
	  } else {
		
		this.userService.getCalificacionBoard().subscribe(
		  res => {
			this.content = res;
			this.totalItems = this.content.dataA.length;
			this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
			this.pageChanged(1);
		},
		  err => {
			this.content = JSON.parse(err.error).message;
		  }
		);
	  }
	}
	

	previousPage(): void {
		if (this.currentPage > 1) {
		  this.pageChanged(this.currentPage - 1);
		}
	  }
		
	  nextPage(): void {
		  if (this.currentPage < this.totalPages) {
			this.pageChanged(this.currentPage + 1);
		  }
		
	  }
	  
	  pageChanged(pageNumber: number): void {
		  const startIndex = (pageNumber - 1) * this.itemsPerPage;
		  let endIndex = startIndex + this.itemsPerPage;
		
		  
		  endIndex = Math.min(endIndex, this.totalItems);
		
		  this.pagedData = this.content.dataS.slice(startIndex, endIndex);
		  this.currentPage = pageNumber;
		  this.showPreviousButton = this.currentPage > 1;
		  this.showNextButton = this.currentPage < this.totalPages;
	  }

}
