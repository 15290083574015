<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 border border-secundary bg-white mt-3">
        <div class="d-block my-5">           
            <nav class="navbar navbar-expand-lg">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavMb" aria-controls="navbarNavMb" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
		              <p class="text-start" style="font-size:0.9rem;">Menu</p>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavMb">
                  <ul class="navbar-bar list-unstyled ps-0" style="width: 100%;">              
                    <li class="nav-item mb-2 py-2">
                      <a href="/user" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                        </svg> Dashboard
                      </a>
                    <!-- </li>              
                      <li class="nav-item mb-2 py-2">
                        <a  href="/u/notificacion" class="btn rounded-2 text-muted fw-light fs-6 menu text-start collapsed">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-fill" viewBox="0 0 16 16">
                            <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                          </svg> Notificaciones
                        </a>                      
                      </li>                      
                      <li class="nav-item mb-2 py-2">
                        <a href="/u/perfil" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                          </svg> Mi perfil
                        </a>
                      </li>               -->
                      <li class="nav-item mb-2 py-2">
                        <a href="/u/user-evaluacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                          </svg> Evaluaciones
                        </a>
                      </li>              
                      <li class="nav-item mb-2 py-2">
                        <a href="/u/user-calificacion" class="btn gap-2 text-muted fw-light fs-6 menu text-start collapsed">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ui-checks" viewBox="0 0 16 16">
                            <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
                          </svg> Calificaciones
                        </a>
                      </li>                      
                      <li class="border-top border-light d-block my-3 mx-0"></li>                    
                        <li class="nav-item mb-2 py-2">
                          <a href="/ayuda" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                            </svg> Ayuda
                          </a>
                        </li>
                        <!-- <li class="nav-item mb-2 py-2">
                          <a href="/logout" class="btn rounded-2 text-muted fw-light fs-6 menu text-start  collapsed p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                              <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                            </svg> Salir
                          </a>
                        </li> -->
                        <li class="border-top border-light d-block my-3 mx-0"></li>                      
                  </ul>
                </div>
            </nav> 
          </div>
      </div>
      <div class="col-md-10">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border border-bottom mt-3">
            <div class="container">
              <div class="card-body rounded-3">
                  <h3 class="text-start font-semibold" style="font-size: 1.3rem;">Bienvenido, {{ content.user.fname }} {{ content.user.lname }}</h3>
                  <span class="text-start font-light text-sm text-muted my-1">{{this.dateTime}}</span> 
              </div>
            </div>
        </nav>
        <div class="container-fluid">
            <div class="row pt-3">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-sm mb-sm-0">
                            <div class="card shadow">
                              <div class="card-title bg-light">
                                <span class="text-start font-semibold text-sm py-2 px-2">Información de Alumno</span>
                              </div>
                                <div class="card-body rounded-3">
                                  <p class="text-start m-0" style="font-size: 0.9rem;"><strong>Nombre: </strong> &nbsp; {{ content.user.fname }} {{ content.user.lname }} ,  {{content.user.prefix}} </p>
                                  <!-- <p class="text-start m-0" style="font-size: 0.9rem;"> <strong>Nivel:</strong> &nbsp; []</p> -->
                                  <p class="text-start m-0" style="font-size: 0.9rem;"><strong>Periodo de Promoción: </strong> &nbsp; {{content.user.periodo}} </p>
                                  <p class="text-start m-0" style="font-size: 0.9rem;"><strong> Escuela: </strong> &nbsp; {{content.user.escuela}} </p>
                                  <p class="text-start m-0" style="font-size: 0.9rem;"><strong> Campus: </strong> &nbsp; {{content.user.campus}} </p>
                                  <!-- <p class="text-start m-0" style="font-size: 0.9rem;"><strong>Departamento: </strong> &nbsp; {{content.user.dept}} </p> -->
                              </div>
                          </div>
                    </div>
                  </div>
                </div>  
                
                
                <div class="col-md-4">
                  <div class="card h-100">
                    <div class="card-title">
                        <span class="text-start font-semibold text-sm py-2 px-2">Maestría</span>
                    </div>
                      <div class="card-body rounded-3">
                        <p class="font-semibold text-center" style="font-size: 0.9rem;"> {{content.dataM.maestria}}</p>
                        <p class="font-semibold text-center" style="font-size: 0.9rem;"> {{content.user.coor}} </p>
                        <p class="font-bold text-center" style="font-size: 0.9rem;">{{content.user.periodo}}</p>
                      </div>
                  </div>
                </div>
                
                <div class="col-md mt-3">
                  <div class="card shadow">
                    <div class="card-title bg-light">
                      <span class="text-start font-semibold text-sm py-2 px-2">Notificaciones</span>
                    </div>
                    <!-- <div class="card-body rounded-3">
                        <p class="text-start text-danger" style="font-family: sans-serif; font-size: 0.7rem;">Atención! Completar Formulario</p>                        
                        <div class="d-flex justify-content-start">
                          <button type="button" class="btn rounded-5" (click)="openModal(content.user.fname, content.user.nid,content.user.campus, content.user.escuela, content.user.periodo, content.user.codigo, content.dataM.maestria, content.user.sex)" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;">Proceso de Recopilación de Estadísticas 2025</button>  
                        </div>
                    </div> -->
                  </div>
                        
                  </div>
            </div>
        </div>
      </div>
    </div>
</div>
