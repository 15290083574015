import { Component } from '@angular/core';

@Component({
  selector: 'app-educacion-continuada',
  templateUrl: './educacion-continuada.component.html',
  styleUrls: ['./educacion-continuada.component.css']
})
export class EducacionContinuadaComponent {

}
