<div class="content-fluid">
    <div class="row row-cols-1 row-cols-md-1 mx-0 px-0">
        <div class="col mx-0 px-0">
            <img class="img-fluid w-100" src="/assets/img/pub/img-pub-inice-1.webp" style="height: 36rem;">
        </div>
    </div>
    <nav style="background-color: #0E4C75;">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="py-4 iniceNav active" id="nav-inice-tab" data-bs-toggle="tab" data-bs-target="#nav-inice" type="button" role="tab" aria-controls="nav-inice" aria-selected="true"><a class="px-5 py-3 text-white backColor iniceNavEfec">INISE</a></button>
            <button class="py-4 iniceNav" id="nav-inv-tab" data-bs-toggle="tab" data-bs-target="#nav-inv" type="button" role="tab" aria-controls="nav-inv" aria-selected="false"><a class="px-5 py-3 text-white backColor iniceNavEfec">Investigación</a></button>
            <button class="py-4 iniceNav" id="nav-difucion-tab" data-bs-toggle="tab" data-bs-target="#nav-difucion" type="button" role="tab" aria-controls="nav-difuncion" aria-selected="false"><a class="px-5 py-3 text-white backColor iniceNavEfec">Difusión</a></button>
            <button class="py-4 iniceNav" id="nav-act-tab" data-bs-toggle="tab" data-bs-target="#nav-act" type="button" role="tab" aria-controls="nav-act" aria-selected="false"><a class="px-5 py-3 text-white backColor iniceNavEfec">Actividades</a></button>
            <button class="py-4 iniceNav" id="nav-contacto-tab" data-bs-toggle="tab" data-bs-target="#nav-contacto" type="button" role="tab" aria-controls="nav-contacto" aria-selected="false"><a class="px-5 py-3 text-white backColor iniceNavEfec">Contacto</a></button>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-inice" role="tabpanel" aria-labelledby="nav-inice-tab" tabindex="0">
            <div class="content">
                <div class="row rol-cols-1 row-cols-md-2 mb-3 text-start">
                    <div class="col">
                        <h6 style="color: #F47B20;">Sobre el Instituto</h6>
                        <h3 class="fw-semibold">¿Quiénes somos?</h3>
                        <p class="" style="text-align: justify; color: #7C7C7C;">El Instituto de Investigación Socioeconómica, INISE, es un órgano de investigación adscrito a la Dirección de Investigación de la Facultad de Ciencias Económicas y Sociales de la Universidad Autónoma de Santo Domingo.
                            El mismo fue creado con la finalidad de fomentar la realización de proyectos de investigación que incidan en la solución a los problemas económicos y sociales que afectan la sociedad dominicana.La producción y difusión del conocimiento constituyen el eje de la existencia del Instituto, así como su compromiso a favor de una mejor y más sólida comprensión de los grandes temas económicos, políticos y sociales de la RD y el mundo.
                        </p>
                    </div>
                    <div class="col">
                        <img class="img-fluid rounded-3 mt-5" src="/assets/img/pub/img-pub-inice-2.webp" alt="">
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="background-color: #F1F1F5;">
                <div class="content">
                    <div class="row rol-cols-1 row-cols-md-2 mb-3 text-start mt-5">
                        <div class="col">
                            <h6 style="color: #F47B20;">Conoce un poco más sobre nosotros</h6>
                                <h3 class="fw-semibold">Historia del Instituto</h3>
                                <p class="" style="text-align: justify; color: #7C7C7C;">El Instituto de Investigaciones Socioeconómicas-INISE-fue creado mediante la Resolución No. 90-63 de fecha 12 de Diciembre del 1990, durante la Rectoría del Dr. Julio Ravelo Astacio, siendo su primer Director el Dr. Mario Bonetti.
                                    El propósito fundamental de su creación fue promover y fortalecer la investigación científica y formativa en el área de las ciencias sociales, en la Facultad de Ciencias Económicas y Sociales (FCES) de la Universidad Autónoma de Santo Domingo (UASD).</p>
                                <p class="mt-2" style="text-align: justify; color: #7C7C7C;">A lo largo de más de dos décadas, el INISE ha creado alrededor de 50 documentos de trabajo en áreas relacionadas con macroeconomía, crecimiento económico, salud, pobreza, delincuencia, equidad de género, cambio climático, mercado laboral, análisis de coyuntura, economía dominicana, migraciones, remesas, zonas francas, deserción estudiantil, políticas públicas, etc.</p>
                                <p class="mt-2" style="text-align: justify; color: #7C7C7C;">En la actualidad funciona con un equipo multidisciplinario, lo que le permite abordar la investigación científica en todos sus ámbitos y campos de acción de las carreras y perfiles profesionales involucrados en la FCES. Ésto ha permitido convertir al INISE en un eje fundamental a tomar en cuenta para la investigación económica y social en la UASD y en el país.</p>
                        </div>
                        <div class="col">
                            <div class="d-flex flex-column">
                                <button class="fw-semibold btn-toggle d-inline-flex align-items-center rounded border-0 collapsed px-5 py-2 bg-white" data-bs-toggle="collapse" data-bs-target="#mision" aria-expanded="true" style="color: #F47B20;">
                                    MISIÓN
                                </button>
                                <div class="collapse rounded bg-white px-5 py-4" id="mision">
                                    <p>Somos un espacio propicio para la práctica investigativa científica y formativa del cuerpo docente y estudiantil de las distintas Carreras y Áreas de la FCES que genera, promueve, difunde y preserva la investigación socioeconómica de la UASD.</p>
                                </div>
                                <button class="fw-semibold btn-toggle d-inline-flex align-items-center rounded border-0 collapsed px-5 py-2 bg-white mt-4" data-bs-toggle="collapse" data-bs-target="#vision" aria-expanded="false" style="color: #F47B20;">
                                    VISIÓN
                                </button>
                                <div class="collapse rounded bg-white px-5 py-4" id="vision">
                                    <p>Somos un espacio propicio para la práctica investigativa científica y formativa del cuerpo docente y estudiantil de las distintas Carreras y Áreas de la FCES que genera, promueve, difunde y preserva la investigación socioeconómica de la UASD.</p>
                                </div>
                                <button class="fw-semibold btn-toggle d-inline-flex align-items-center rounded border-0 collapsed px-5 py-2 bg-white mt-4" data-bs-toggle="collapse" data-bs-target="#valores" aria-expanded="false" style="color: #F47B20;">
                                    VALORES
                                </button>
                                <div class="collapse rounded bg-white px-5 py-4" id="valores">
                                    <p>Somos un espacio propicio para la práctica investigativa científica y formativa del cuerpo docente y estudiantil de las distintas Carreras y Áreas de la FCES que genera, promueve, difunde y preserva la investigación socioeconómica de la UASD.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row row-cols-1 row-cols-md-1 mx-0 px-0">
                    <div class="col-md-6 mx-5">
                        <h6 style="color: #F47B20;">Conoce un poco más sobre nosotros</h6>
                        <h3 class="fw-semibold">Personal administrativo</h3>
                        <table class="table bg-trasnparent">
                            <thead>
                                <tr>
                                    <th scope="col" style="color: #F47B20;">Nombre Completo</th>
                                    <th scope="col" style="color: #F47B20;">Puesto</th>
                                    <th scope="col" style="color: #F47B20;">Correo electrónico</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="fw-semibold p-4">Juan Pérez </td>
                                    <td class="p-4">Auxiliar Administrativo</td>
                                    <td class="p-4">juanperez&#64;hotmail.com</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-inv" role="tabpanel" aria-labelledby="nav-inv-tab" tabindex="0">
            <div class="content">
                <div class="row row-cols-1 row-cols-md-2 mb-3 text-start">
                    <div class="col">
                        <h3 class="fw-semibold">Investigación</h3>
                        <p style="text-align: justify; color: #7C7C7C;">La sección de investigación en nuestra facultad es un pilar fundamental de nuestra misión académica. Está dedicada a fomentar la investigación de vanguardia en las ciencias económicas y sociales, contribuyendo al avance del conocimiento en estos campos y a la generación de soluciones innovadoras para los desafíos contemporáneos.</p>
                        <p class="mt-2" style="text-align: justify; color: #7C7C7C;">Contamos con un equipo de investigadores altamente calificados y comprometidos, quienes llevan a cabo proyectos de investigación de alto impacto en diversas áreas temáticas. Desde estudios teóricos hasta investigaciones aplicadas, nuestro objetivo es explorar nuevas perspectivas, generar evidencia sólida y proponer recomendaciones prácticas que puedan influir en políticas públicas, prácticas empresariales y la sociedad en general.</p>    
                    </div>
                    <div class="col mt-5">
                        <img class="img-fluid" src="/assets/img/pub/img-pub-inv-1.webp" alt="">
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-1 pt-5" style="background-color: #F1F1F5;">
                    <div class="col">
                        <nav>
                            <div class="nav" id="nav-tab-inise-inv" role="tablist">
                                <button class="nav-link border-0 active" id="nav-lineas-inv-tab" data-bs-toggle="tab" data-bs-target="#nav-lineas-inv" type="button" role="tab" aria-controls="nav-lineas-inv" aria-selected="true"><a class="btn-inv btn-invEfec border border-1 rounded-2">Líneas de investigación</a></button>
                                <button class="nav-link border-0" id="nav-observatorios-tab" data-bs-toggle="tab" data-bs-target="#nav-observatorios" type="button" role="tab" aria-controls="nav-observatorios" aria-selected="false"><a class="btn-inv btn-invEfec border border-1 rounded-2">Observatorios Temáticos</a></button>
                                <button class="nav-link border-0" id="nav-proyectos-tab" data-bs-toggle="tab" data-bs-target="#nav-proyectos" type="button" role="tab" aria-controls="nav-proyectos" aria-selected="false"><a class="btn-inv btn-invEfec border border-1 rounded-2">Proyectos de investigación</a></button>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tab-inise-invContent">
                            <div class="tab-pane fade show active" id="nav-lineas-inv" role="tabpanel" aria-labelledby="nav-lineas-inv-tab" tabindex="0">
                                <div class="row row-cols-1 row-cols-md-1 mx-5">
                                    <div class="col">
                                        <h6 style="color: #F47B20;">Conoce nuestros ejes temáticos</h6>
                                        <h3 class="fw-semibold">Líneas de investigacion</h3>
                                        <ul class="d-flex gap-3 flex-column" style="list-style: none;">
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-observatorios" role="tabpanel" aria-labelledby="nav-observatorios-tab" tabindex="0">
                                <div class="row row-cols-1 row-cols-md-1 mx-5">
                                    <div class="col">
                                        <h6 style="color: #F47B20;">Conoce nuestros ejes temáticos</h6>
                                        <h3 class="fw-semibold">Observatorios Temáticos</h3>
                                        <ul class="d-flex gap-3 flex-column" style="list-style: none;">
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-proyectos" role="tabpanel" aria-labelledby="nav-proyectos-tab" tabindex="0">
                                <div class="row row-cols-1 row-cols-md-1 mx-5">
                                    <div class="col">
                                        <h6 style="color: #F47B20;">Conoce nuestros ejes temáticos</h6>
                                        <h3 class="fw-semibold">Proyectos de investigación</h3>
                                        <ul class="d-flex gap-3 flex-column" style="list-style: none;">
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                            <li class="py-5 w-75 bg-white rounded-3"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-difucion" role="tabpanel" aria-labelledby="nav-difucion-tab" tabindex="0">
            <div class="content mt-5">
                <div class="row">
                    <div class="col">
                        <div class="row row-cols-md-1 mx-0 px-0">
                            <h6 style="color: #F47B20;">En medios digitales</h6>
                            <h3  class="fw-semibold">Artículos de investigadores</h3>
                            <div class="card" href="#" style="width: 16rem;">
                                <div class="text-center rounded-2 mt-2 me-1 ms-2" style="background: rgba(28, 27, 27, 1); background: linear-gradient(3.21deg, #1C1B1B 2.5%, #000 17.13%, #000 53.04%, #000 60.35%, #000 97.5%); height: 10rem;">
                                    <div class="d-flex">
                                        <div class="p-5">

                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">Titulo</h5>
                                    <div class="d-block py-2">
                                        <div class="d-flex pb-2 gap-2">
                                            <p>Diplomado</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-cols-md-1 mx-0 px-0 mt-5" style="background: #F1F1F5;">
                            <h3 class="fw-semibold">Videoteca</h3>
                            <div class="d-flex">
                                <div class="col">
                                    <div class="card" href="#" style="width: 14rem; height: 10rem; background: #AFAFAF;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="180" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16" style="color: white; margin-top: 5px; margin-left: 85px;">
                                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card" href="#" style="width: 14rem; height: 10rem; background: #AFAFAF;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="180" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16" style="color: white; margin-top: 5px; margin-left: 85px;">
                                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card" href="#" style="width: 14rem; height: 10rem; background: #AFAFAF;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="180" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16" style="color: white; margin-top: 5px; margin-left: 85px;">
                                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card" href="#" style="width: 14rem; height: 10rem; background: #AFAFAF;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="180" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16" style="color: white; margin-top: 5px; margin-left: 85px;">
                                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-cols-md-1 mx-0 px-0 mt-5">
                            <h6 style="color: #F47B20;">En medios digitales</h6>
                            <h3  class="fw-semibold">Artículos de investigadores</h3>
                            <div class="d-flex gap-3">
                                <div class="col">
                                    <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
                                        <div class="row g-0">
                                            <div class="col-md-3 d-flex align-items-center">
                                                <img src="/assets/img/pub/img-pub-revista.webp" alt="..." class="img-fluid ms-2 rounded">
                                            </div>
                                            <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                                                <div class="card-body">
                                                    <h6 class="card-title fw-semibold text-start">Revista científica FCES</h6>
                                                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">Modelo innovador- emprendedor. Consiente y la atmósfera socioeconómica del COVID-19.</p>
                                                    <div class="d-flex justify-content-start pt-2">
                                                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card mb-3 d-flex flex-column" style="max-width: 540px;">
                                        <div class="row g-0">
                                            <div class="col-md-3 d-flex align-items-center">
                                                <img src="/assets/img/pub/img-pub-revista.webp" alt="..." class="img-fluid ms-2 rounded">
                                            </div>
                                            <div class="col rounded-1" style="border-right: 5px solid #F47B20;">
                                                <div class="card-body">
                                                    <h6 class="card-title fw-semibold text-start">Revista científica FCES</h6>
                                                    <p class="card-text mb-2" style="color: #8d8d8d; text-align: start; font-size: 0.8rem;">Modelo innovador- emprendedor. Consiente y la atmósfera socioeconómica del COVID-19.</p>
                                                    <div class="d-flex justify-content-start pt-2">
                                                        <a href="#" class="btn rounded-5" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFFFFF;">Ver revista</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-act" role="tabpanel" aria-labelledby="nav-act-tab" tabindex="0">
            <div class="content mt-5">
                <div class="row">
                    <h4 style="color: #F47B20;">Del Instituto</h4>
                    <h3  class="fw-semibold">Eventos</h3>
                    <div class="row row-cols-md-3 mx-0 px-0 mt-5">
                        <div class="col">
                            <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
                                <div class="rounded-top-4">
                                    <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-actividades-1.webp" alt="Imagen no disponible">
                                </div>
                                <div class="d-flex p-2" style="font-size: 0.7rem;">
                                    <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
                                        <p class="text-white">16</p>
                                        <p class="text-white">Nov.</p>
                                    </div>
                                </div>
                                <div class="card-body px-2">
                                    <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
                                    <div class="d-flex justify-content-start align-items-center border border-1">
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i>Instituto de Investigación y Estudios de Género y Familia-UASD</p>
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i>Paraninfo, Ricardo Michel, UASD Sede.</p>
                                        </div>
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                        <div class="d-block px-2"><p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-start">
                                        <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
                                <div class="rounded-top-4">
                                    <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-actividades-1.webp" alt="Imagen no disponible">
                                </div>
                                <div class="d-flex p-2" style="font-size: 0.7rem;">
                                    <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
                                        <p class="text-white">16</p>
                                        <p class="text-white">Nov.</p>
                                    </div>
                                </div>
                                <div class="card-body px-2">
                                    <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
                                    <div class="d-flex justify-content-start align-items-center border border-1">
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i>Instituto de Investigación y Estudios de Género y Familia-UASD</p>
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i>Paraninfo, Ricardo Michel, UASD Sede.</p>
                                        </div>
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                        <div class="d-block px-2">
                                            <p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-start">
                                        <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card  rounded-4" style="width: 16rem; height: 26rem;">
                                <div class="rounded-top-4">
                                    <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-actividades-1.webp" alt="Imagen no disponible">
                                </div>
                                <div class="d-flex p-2" style="font-size: 0.7rem;">
                                    <div class="d-block rounded-2 position-absolute p-2" style="background-color: #DA7409;top: 109px;left: 4px;">
                                        <p class="text-white">16</p>
                                        <p class="text-white">Nov.</p>
                                    </div>
                                </div>
                                <div class="card-body px-2">
                                    <p class="fw-semibold text-dark pb-2" style="font-size: 0.9rem;">Seminario: Feminicidio en R.D.</p>
                                    <div class="d-flex justify-content-start align-items-center border border-1">
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-person"></i>Instituto de Investigación y Estudios de Género y Familia-UASD</p>
                                            <p class="text-start" style="color: #8d8d8d;font-size: 0.6rem;"><i  class="bg-white bi bi-geo-alt"></i>Paraninfo, Ricardo Michel, UASD Sede.</p>
                                        </div>
                                        <div class="d-block border-end border-1 px-2">
                                            <p class="fw-semibold text-dark h-100" style="font-size: 0.6rem;">08:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                        <div class="d-block px-2">
                                            <p class="fw-semibold text-dark" style="font-size: 0.6rem;">10:00</p>
                                            <p style="color: #8d8d8d;font-size: 0.6rem;">am</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-start">
                                        <p class="text-start text-wrap mt-2" style="color: #8d8d8d;font-size: 0.7rem;">Desde nuestra academia, desarrollamos el Seminario Nacional “Feminicidio en República Dominicana: Abordaje Preventivo e Integral desde la Educación igualitaria”, organizado junto al Ministerio de la Mujer, con el apoyo de la Unión Europea.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="background: #F1F1F5;">
                <div class="content">
                    <div class="d-block">
                        <h4 style="color: #F47B20;">Estas son nuestras</h4>
                        <h3  class="fw-semibold">Actividades</h3>
                    </div>
                    <div class="row row-cols-md-3 mx-0 px-0 mt-5">
                        <div class="col">
                            <div class="rounded-top-4">
                                <img class="img-fluid rounded-top-4" src="/assets/img/pub/img-pub-actividades-2.webp" alt="Imagen no disponible">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-contacto" role="tabpanel" aria-labelledby="nav-contacto-tab" tabindex="0">
            <div class="content mt-5">
                <div class="row">
                    <div class="col d-flex flex-column align-items-start gap-2 p-5">
                        <div class="row row-cols-1 row-cols-md-2 text-center">
                            <div class="col list-group px-0">
                                <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-bottom" style="flex-direction: column;align-items: center; height: 15rem;">
                                    <img src="/assets/icon/location.webp" width="80" height="80">
                                    <div>
                                        <p class="text-sm mb-0" style="font-weight: bold;">Dirección</p>
                                        <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">6to Piso, Torre Adm.</p>
                                        <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">UASD, SEDE.</p>
                                    </div>
                                </div>
                                <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-top" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                                    <div class="d-flex gap-2 mb-0" style="flex-direction: column;align-items: center; height: 15rem; width: max-content; margin-right: 1rem;">
                                        <img src="/assets/icon/reloj.webp" width="80" height="80"> 
                                        <div>
                                            <p class="text-sm mb-0" style="font-weight: bold;">Horario de oficina</p>
                                            <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">Días de semana: 8:00 – 7:00 p.m.</p>
                                            <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">Sábados y Domingos: Cerrado</p>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col list-group">
                                <div class="d-flex gap-2 mb-0 border-dark border-start border-bottom" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                                    <div class="d-flex gap-2 mb-0 p-4" style="flex-direction: column;align-items: center; height: 15rem;">
                                        <img src="/assets/icon/mail.webp" width="80" height="80">                                                              
                                        <div>
                                            <p class="text-sm mb-0" style="font-weight: bold;">Email</p>
                                            <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">postgradofces&#64;uasd.edu.do</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex gap-2 mb-0 p-4 border-dark border-start border-top" style="flex-direction: column;align-items: center; height: 15rem;">
                                    <img src="/assets/icon/phone.webp" width="80" height="80">
                                    <div>
                                        <p class="text-sm mb-0" style="font-weight: bold;">Teléfono</p>
                                        <p class="text-sm mb-0" style="font-size: 0.7rem; color: #737373;">(809) 233-3333</p>
                                        <p class="text-sm mb-0" style="font-size: 0.7rem;color: #737373;">(809) 754-6985</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <h3 class="fw-semibold fs-6 text-start" style="color:#F47B20">Necesitas otra informacion</h3>
                        <h3 class="text-dark text-start fw-bold">Contáctanos, ¡Estamos aquí!</h3>
                        <form class="mx-auto" novalidate>
                            <div>
                                <div>
                                    <div class="mb-2 d-flex">
                                        <input matInput type="text"  class="me-1 p-2 fs-6 form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Nombre Completo" required>
                                        <input matInput type="text"  class="p-2 fs-6 form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Correo Electrónico" required>
                                    </div>
                                    <div class="mb-2 d-flex">
                                        <input matInput type="text"  style="font-size: 0.9rem;" class=" me-1 p-2 fs-6 form-control rounded-4 border border-dark border-1" placeholder="Numero de contacto" required>
                                        <input matInput type="text"  style="font-size: 0.9rem;" class="p-2 fs-6 form-control rounded-4 border border-dark border-1" placeholder="Tema" required>
                                    </div>
                                    <div class="mb-2">
                                        <textarea class="form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Escribe tu mensaje aquí" id="floatingTextarea2" style="height: 100px"></textarea>
                                    </div>                              
                                    <div class="col-md-6 mx-auto">
                                        <button type="button" class="btn btn-primary">Enviar</button>
                                        <div class="text-center">
                                            <p class="text-danger" style="font-size: 0.6rem;">*No disponible</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row row-cols-md-2 mx-0 px-0 mt-5">
                    <div class="col" style="align-content: center;">
                        <div class="row">
                            <h3 class="fw-semibold">Encuentranos</h3>
                        </div>
                        <div class="row">
                            <h6 class="mt-5" style="color: #F47B20;">También puedes contactarnos</h6>
                            <h3 class="fw-semibold">Redes Sociales</h3>
                        </div>
                        <div class="col d-flex" style="align-items: end;">
                            <ul class="list-unstyled d-flex">
                                <li class="p-2">
                                    <a href="https://www.facebook.com/Economiauasd?mibextid=LQQJ4d" class="btn btn-primary p-3 rounded-4 text-sm fw-bold">
                                        <div class="bg-transparent border-0 rounded-3 d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li class="p-2">
                                    <a href="https://wa.link/4g5evg" class="btn btn-primary p-3 rounded-4 text-sm fw-bold">
                                        <div class="bg-transparent border-0 rounded-3 d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li class="p-2">
                                    <a href="https://www.instagram.com/economiauasd?igsh=MXd1a2U4dTR4enppdg%3D%3D&utm_source=qr" class="btn btn-primary p-3 rounded-4 text-sm fw-bold">
                                        <div class="bg-transparent border-0 rounded-3 d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-instagram" viewBox="0 0 16 16">
                                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li class="p-2">
                                    <a href="#" class="btn btn-primary p-3 rounded-4 text-sm fw-bold">
                                        <div class="bg-transparent border-0 rounded-3 d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex col">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3784.530177646094!2d-69.920054!3d18.459629!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ebaf4a942959859%3A0x5b383b1330fb7fcf!2sUniversidad%20Aut%C3%B3noma%20de%20Santo%20Domingo%20(UASD)%20-%20Sede%20Central!5e0!3m2!1ses-419!2sdo!4v1711985215696!5m2!1ses-419!2sdo" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>