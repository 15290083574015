<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 inicio text-dark">
                <div class="card border-0">
                    <div class="card-header bg-white border-0">
                        <h2 class="pt-3" style="text-align: center;">Solicitud de Factura con comprobante</h2>
                    </div>
                    <div class="card-body">
                      
                            <div class="itemComprobante">
                                <div class="mb-3">
                                    <input type="text"   class="form-control rounded-4 border border-dark border-1 me-3" name="nombre"   placeholder="Nombre">
                                    <input type="text"   class="form-control rounded-4 border border-dark border-1"      name="apellido" placeholder="Apellido">
                                </div>
                                <div class="mb-3">
                                    <input type="text"   class="form-control rounded-4 border border-dark border-1 me-3" name="cedula"   placeholder="Cédula">
                                    <input type="tel" class="form-control rounded-4 border border-dark border-1"      name="telefono" placeholder="Teléfono">
                                </div>
                                <div class="mb-3">
                                    <input type="email" class="form-control rounded-4 border border-dark border-1"  name="email"    placeholder="Correo">
                                </div>
                                <div class="mb-2">
                                <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" required>
                                    <option class="ms-2" value="" disabled selected>Maestría</option>
                                    <optgroup label="Escuela de Administración">
                                        <option value="Administración de Empresas Cooperativas">Maestría en Administración de Empresas Cooperativas</option>
                                        <option value="Alta Gerencia">Maestría en Alta Gerencia</option>
                                        <option value="Dirección Estratégica y Gestión de la Innovación">Maestría en Dirección Estratégica y Gestión de la Innovación</option>
                                        <option value="Gerencia Financiera">Maestría en Gerencia Financiera</option>
                                        <option value="Gestion de Recursos Humanos">Maestría en Gestión de Recursos Humanos</option>
                                        <option value="Gestión Pública">Maestría en Gestión Pública</option>
                                        <option value="Seguros y Finanzas">Maestría en Seguros y Finanzas</option>
                                        <option value="Gestión Logística de las Aduanas y Puertos">Maestría en Gestión Logística de las Aduanas y Puertos</option>
                                        <option value="Gestión en Finanzas Coorporativas">Maestría Gestión en Finanzas Coorporativas</option>
                                    </optgroup>

                                    <optgroup label="Escuela de Economía">
                                        <option value="Finanzas y Mercado de Capitales">Maestría en Finanzas y Mercado de Capitales</option>
                                        <option value="Negocios y Relaciones Económicas Internacionales">Maestría en Negocios y Relaciones Económicas Internacionales</option>
                                        <option value="Master Globalización">Maestría en Master Globalización</option>
                                    </optgroup>

                                    <optgroup label="Escuela de Contabilidad">
                                        <option value="Auditoria Interna">Maestría en Auditoria Interna</option>
                                        <option value="Contabilidad Gubernamental">Maestría en Contabilidad Gubernamental</option>
                                        <option value="Contabilidad Tributaria">Maestría en Contabilidad Tributaria</option>
                                    </optgroup>
                                    
                                    <optgroup label="Escuela de Mercadotecnia">
                                        <option value="Marketing Estratégico">Maestría en Marketing Estratégico</option>
                                        <option value="Estrategias de CirbeMarketing">Maestría en Estrategias de CiberMarketing</option>
                                    </optgroup>
                                    
                                    <optgroup label="Escuela de Sociología y Trabajo Social">
                                        <option value="Sociología de la Educación">Maestría en Sociología de la Educación</option>
                                        <option value="Trabajo Social">Maestría en Trabajo Social</option>
                                    </optgroup>

                                    <optgroup label="Escuela de Sociología y Trabajo Social">
                                        <option value="Estadística Especializada">Maestría en Estadística Especializada</option>
                                    </optgroup>
                                </select>
                                </div>
                                
                                <div class="mb-2">
                                <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" required>
                                    <option class="ms-2" value="" disabled selected>Periodo</option>
                                    <option value="2023-2024">2023-2024</option>
                                </select>
                                </div>
                                <div class="mb-2">
                                <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" required>
                                    <option class="ms-2" value="" disabled selected>Coordinador</option>
                                    <option value="Patria Fernandez">Patria Fernandez</option>
                                    <option value="Mariel García">Mariel García</option>
                                    <option value="María de Lourdes Sanchez">María de Lourdes Sanchez</option>
                                    <option value="Cesar Díaz">Cesar Díaz</option>
                                    <option value="Amin Martinez">Amin Martinez</option>
                                    <option value="Carmen Luisa Santana">Carmen Luisa Santana</option>
                                    <option value="Nancy Alonso">Nancy Alonso</option>
                                    <option value="Giselle Duverge">Giselle Duverge</option>
                                    <option value="Jose Crespo">Jose Crespo</option>
                                    <option value="Joaquin de la Rosa">Joaquin de la Rosa</option>
                                    <option value="Karina Abad">Karina Abad</option>
                                    <option value="Aurea Pelletier">Aurea Pelletier</option>
                                    <option value="Nancy Sanchez">Nancy Sanchez</option>
                                    <option value="Jossira Santana Cedano">Jossira Santana Cedano</option>
                                    <option value="Manuel Mercedes">Manuel Mercedes</option>
                                    <option value="Giovanni Matos Subervi">Giovanni Matos Subervi</option>
                                    <option value="Guarionaex Gomez">Guarionaex Gomez</option>
                                    <option value="Marianela Fernandez">Marianela Fernandez</option>
                                    <option value="Wagner Gomera">Wagner Gomera</option>
                                    <option value="Xiomara Rodriguez">Xiomara Rodriguez</option>
                                    <option value="Danilo Montero">Danilo Montero</option>
                                    <option value="Sorivel Cristina Perez B.">Sorivel Cristina Perez B.</option>
                                    <option value="Ydania Figuereo">Ydania Figuereo</option>
                                    <option value="Leonte Castillo">Leonte Castillo</option>
                                    <option value="Andry Garcia">Andry Garcia</option>
                                </select>
                                </div>
                                <div class="mb-3">
                                    <input type="text"  class="form-control rounded-4 border border-dark border-1" name="rnc"   placeholder="RNC">
                                </div>
                                <div class="mb-3">
                                    <input type="text"  class="form-control rounded-4 border border-dark border-1" name="empresa/institucion"   placeholder="Empresa/Institución">
                                </div>
                                <div class="mb-3">
                                    <input type="text"  class="form-control rounded-4 border border-dark border-1 me-3" name="monto"      placeholder="Monto">
                                    <input type="text"  class="form-control rounded-4 border border-dark border-1"      name="mododepago" placeholder="Modo de Pago">
                                </div>
                                <div class="mb-3">
                                    <input type="text"  class="form-control rounded-4 border border-dark border-1" name="tipodefactura"   placeholder="Tipo de factura">
                                </div>
                                <div class="col-md-6 mx-auto">
                                    <button type="button" class="btn btn-primary mt-3">Enviar</button>
                                </div>
                            </div>
                      
                    </div>
                </div>
            </div>
            <div class="col-md-8 bg-hide-col px-0">   
                <div class="bg-image"  style="background-image: url('https://postgradofces.com/assets/img/img_comprobante.png');height: 100%;background-size: cover; background-repeat:no-repeat; background-position: right;">                
            </div>
        </div>
    </div>
</div>