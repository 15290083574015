<div class="container-fluid">
    <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col d-flex flex-column align-items-start gap-2 p-5">
            <h3 class="fw-semibold fs-6" style="color:#F47B20">Tu puerta al éxito académico y profesional</h3>
            <h4 class="text-dark text-start fw-bold">Portal del Estudiante</h4>
            <div class="row row-cols-1 row-cols-md-2 text-center">
                <div class="col list-group">
                    <a href="/admisiones">
                        <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                            <svg class="mt-3" width="30" height="30" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0625 22.6875C31.6452 22.6875 28.875 25.4577 28.875 28.875C28.875 32.2923 31.6452 35.0625 35.0625 35.0625C38.4798 35.0625 41.25 32.2923 41.25 28.875C41.25 25.4577 38.4798 22.6875 35.0625 22.6875ZM24.75 28.875C24.75 23.1796 29.3671 18.5625 35.0625 18.5625C40.7579 18.5625 45.375 23.1796 45.375 28.875C45.375 34.5704 40.7579 39.1875 35.0625 39.1875C29.3671 39.1875 24.75 34.5704 24.75 28.875Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 27.8438C6.1875 26.7047 7.11091 25.7812 8.25 25.7812H14.4375C15.5766 25.7812 16.5 26.7047 16.5 27.8438C16.5 28.9828 15.5766 29.9062 14.4375 29.9062H8.25C7.11091 29.9062 6.1875 28.9828 6.1875 27.8438Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 17.5312C6.1875 16.3922 7.11091 15.4688 8.25 15.4688H14.4375C15.5766 15.4688 16.5 16.3922 16.5 17.5312C16.5 18.6703 15.5766 19.5938 14.4375 19.5938H8.25C7.11091 19.5938 6.1875 18.6703 6.1875 17.5312Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 38.1562C6.1875 37.0172 7.11091 36.0938 8.25 36.0938H14.4375C15.5766 36.0938 16.5 37.0172 16.5 38.1562C16.5 39.2953 15.5766 40.2188 14.4375 40.2188H8.25C7.11091 40.2188 6.1875 39.2953 6.1875 38.1562Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 48.4688C6.1875 47.3297 7.11091 46.4062 8.25 46.4062H14.4375C15.5766 46.4062 16.5 47.3297 16.5 48.4688C16.5 49.6078 15.5766 50.5312 14.4375 50.5312H8.25C7.11091 50.5312 6.1875 49.6078 6.1875 48.4688Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0626 39.1875C32.9814 39.1875 30.9287 39.6721 29.0672 40.6028C27.2056 41.5336 25.5864 42.885 24.3376 44.55C23.6542 45.4613 22.3614 45.646 21.4501 44.9625C20.5389 44.279 20.3542 42.9863 21.0376 42.075C22.6706 39.8977 24.7881 38.1305 27.2224 36.9133C29.6567 35.6962 32.341 35.0625 35.0626 35.0625C37.7843 35.0625 40.4685 35.6962 42.9028 36.9133C45.3371 38.1305 47.4546 39.8977 49.0876 42.075C49.7711 42.9863 49.5864 44.279 48.6751 44.9625C47.7639 45.646 46.4711 45.4613 45.7876 44.55C44.5389 42.885 42.9196 41.5336 41.0581 40.6028C39.1965 39.6721 37.1439 39.1875 35.0626 39.1875Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.625 6.1875C55.9032 6.1875 57.75 8.03433 57.75 10.3125V55.6875C57.75 57.9657 55.9032 59.8125 53.625 59.8125H16.5C14.2218 59.8125 12.375 57.9657 12.375 55.6875V10.3125C12.375 8.03433 14.2218 6.1875 16.5 6.1875L53.625 6.1875ZM53.625 55.6875V10.3125L16.5 10.3125V55.6875H53.625Z" fill="white"/>
                            </svg>
                            <div>
                                <p class="text-sm text-white mb-0 ">Formulario de Admisión</p>
                            </div>
                        </div>
                    </a>
                    <a href="/servicios">
                        <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                            <svg class="mt-3" width="30" height="30" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1562 15.25C17.1562 14.1972 18.0097 13.3438 19.0625 13.3438H41.9375C42.9903 13.3438 43.8438 14.1972 43.8438 15.25V26.6875C43.8438 27.7403 42.9903 28.5938 41.9375 28.5938H19.0625C18.0097 28.5938 17.1562 27.7403 17.1562 26.6875V15.25ZM20.9688 17.1562V24.7812H40.0312V17.1562H20.9688Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6562 5.71875C49.7618 5.71875 51.4688 7.42566 51.4688 9.53125V51.4688C51.4688 53.5743 49.7618 55.2812 47.6562 55.2812H13.3438C11.2382 55.2812 9.53125 53.5743 9.53125 51.4688V9.53125C9.53125 7.42566 11.2382 5.71875 13.3438 5.71875L47.6562 5.71875ZM47.6562 51.4688V9.53125L13.3438 9.53125V51.4688H47.6562Z" fill="white"/>
                                <path d="M20.9688 38.125C22.5479 38.125 23.8281 36.8448 23.8281 35.2656C23.8281 33.6864 22.5479 32.4062 20.9688 32.4062C19.3896 32.4062 18.1094 33.6864 18.1094 35.2656C18.1094 36.8448 19.3896 38.125 20.9688 38.125Z" fill="white"/>
                                <path d="M30.5 38.125C32.0792 38.125 33.3594 36.8448 33.3594 35.2656C33.3594 33.6864 32.0792 32.4062 30.5 32.4062C28.9208 32.4062 27.6406 33.6864 27.6406 35.2656C27.6406 36.8448 28.9208 38.125 30.5 38.125Z" fill="white"/>
                                <path d="M40.0312 38.125C41.6104 38.125 42.8906 36.8448 42.8906 35.2656C42.8906 33.6864 41.6104 32.4062 40.0312 32.4062C38.4521 32.4062 37.1719 33.6864 37.1719 35.2656C37.1719 36.8448 38.4521 38.125 40.0312 38.125Z" fill="white"/>
                                <path d="M20.9688 47.6562C22.5479 47.6562 23.8281 46.3761 23.8281 44.7969C23.8281 43.2177 22.5479 41.9375 20.9688 41.9375C19.3896 41.9375 18.1094 43.2177 18.1094 44.7969C18.1094 46.3761 19.3896 47.6562 20.9688 47.6562Z" fill="white"/>
                                <path d="M30.5 47.6562C32.0792 47.6562 33.3594 46.3761 33.3594 44.7969C33.3594 43.2177 32.0792 41.9375 30.5 41.9375C28.9208 41.9375 27.6406 43.2177 27.6406 44.7969C27.6406 46.3761 28.9208 47.6562 30.5 47.6562Z" fill="white"/>
                                <path d="M40.0312 47.6562C41.6104 47.6562 42.8906 46.3761 42.8906 44.7969C42.8906 43.2177 41.6104 41.9375 40.0312 41.9375C38.4521 41.9375 37.1719 43.2177 37.1719 44.7969C37.1719 46.3761 38.4521 47.6562 40.0312 47.6562Z" fill="white"/>
                            </svg>
                            <div>
                                <p class="text-sm text-white mb-0 ">Solicitud de cotización</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col list-group">
                    <a href="https://forms.gle/s2DXW7dH4vF9xNuX7">
                        <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                            <svg class="mt-3" width="30" height="30" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0625 27.625C18.0625 26.4514 19.0139 25.5 20.1875 25.5H47.8125C48.9861 25.5 49.9375 26.4514 49.9375 27.625C49.9375 28.7986 48.9861 29.75 47.8125 29.75H20.1875C19.0139 29.75 18.0625 28.7986 18.0625 27.625Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0625 36.125C18.0625 34.9514 19.0139 34 20.1875 34H47.8125C48.9861 34 49.9375 34.9514 49.9375 36.125C49.9375 37.2986 48.9861 38.25 47.8125 38.25H20.1875C19.0139 38.25 18.0625 37.2986 18.0625 36.125Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.6198 11.8698C8.41683 11.0728 9.49783 10.625 10.625 10.625H57.375C58.5022 10.625 59.5832 11.0728 60.3802 11.8698C61.1772 12.6668 61.625 13.7478 61.625 14.875V55.25C61.625 55.9865 61.2437 56.6704 60.6172 57.0576C59.9907 57.4448 59.2084 57.48 58.5497 57.1507L51 53.3758L43.4503 57.1507C42.8521 57.4498 42.1479 57.4498 41.5497 57.1507L34 53.3758L26.4503 57.1507C25.8521 57.4498 25.1479 57.4498 24.5497 57.1507L17 53.3758L9.45033 57.1507C8.7916 57.48 8.00931 57.4448 7.38282 57.0576C6.75634 56.6704 6.375 55.9865 6.375 55.25V14.875C6.375 13.7478 6.82277 12.6668 7.6198 11.8698ZM57.375 14.875L10.625 14.875L10.625 51.8117L16.0497 49.0993C16.6479 48.8002 17.3521 48.8002 17.9503 49.0993L25.5 52.8742L33.0497 49.0993C33.6479 48.8002 34.3521 48.8002 34.9503 49.0993L42.5 52.8742L50.0497 49.0993C50.6479 48.8002 51.3521 48.8002 51.9503 49.0993L57.375 51.8117V14.875Z" fill="white"/>
                                </svg>                               
                            <div>
                                <p class="text-sm text-white mb-0 ">Solicitud de Factura</p>
                            </div>
                        </div>
                    </a>
                    <a href="https://soft.uasd.edu.do/UASDVirtualGateway/">
                        <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                            <svg class="mt-3" width="30" height="30" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0588 6.23529C31.6471 5.92157 32.3529 5.92157 32.9412 6.23529L62.9412 22.2353C63.5929 22.5829 64 23.2614 64 24C64 24.7386 63.5929 25.4171 62.9412 25.7647L32.9412 41.7647C32.3529 42.0784 31.6471 42.0784 31.0588 41.7647L1.05882 25.7647C0.407097 25.4171 0 24.7386 0 24C0 23.2614 0.407097 22.5829 1.05882 22.2353L31.0588 6.23529ZM6.25 24L32 37.7333L57.75 24L32 10.2667L6.25 24Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2357 23.0589C30.7555 22.0842 31.967 21.7155 32.9416 22.2353L47.9416 30.2353C48.5933 30.5829 49.0004 31.2614 49.0004 32V60C49.0004 61.1046 48.105 62 47.0004 62C45.8958 62 45.0004 61.1046 45.0004 60V33.2L31.0592 25.7647C30.0846 25.2449 29.7159 24.0335 30.2357 23.0589Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00002 25.725C10.1046 25.725 11 26.6204 11 27.725V41.3499L11.0044 41.3556L11.0043 41.3557C12.4875 43.3481 19.064 51 32 51C44.936 51 51.5125 43.3481 52.9958 41.3557L53 41.35V27.725C53 26.6204 53.8955 25.725 55 25.725C56.1046 25.725 57 26.6204 57 27.725V41.375L57 41.3834C56.9964 42.2393 56.7149 43.0707 56.198 43.7527C54.3205 46.2714 46.6976 55 32 55C17.3023 55 9.67943 46.2712 7.80197 43.7526C7.28515 43.0707 7.00365 42.2393 7.00004 41.3834L7 41.375H7.00002V27.725C7.00002 26.6204 7.89545 25.725 9.00002 25.725Z" fill="white"/>
                            </svg>
                            <div>
                                <p class="text-sm text-white mb-0 ">UASD Virtual</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="col">
        <img src="/assets/img/img-servicio-model-0.png" alt="" class="img-fluid p-5 pt-0">
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col" style="align-items: center;">
            <div class="d-block ms-5" style="text-align: left;">
                <h3 class="fs-6" style="color: #F47B20;">Consulta tus calificaciones y más</h3>
                <h3 class="fw-semibold">Estudiantes</h3>
                <p style="font-size: 0.9rem;">Si eres estudiante inicia sesión con tu cedula por primera vez en ambos campus. Al ingresar, recuerda ir a tu perfil y cambiar la contraseña para mayor seguridad.</p>
        
            </div>
        </div>

        <div class="col">
    <div class="d-flex" style="flex-direction: column;">
        <form class="d-flex flex-column bg-transparent rounded-3" *ngIf="!isLoggedIn" name="form"  #f="ngForm" novalidate style="background-image: url('https://postgradofces.com/assets/img/bg-form-estudiante.png'); padding: 6rem;">
            <div class="mb-3 flex-column">
                <h1 class="fw-semibold">Iniciar Sesión</h1>
                <h4>Ingresa a tu cuenta online</h4>
            </div>
            <div class="mb-3">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>
                </span>
            <input  type="text" class="form-control" name="nid" [(ngModel)]="login.nid" required nid #nid="ngModel" autocomplete="username"  maxlength="30" placeholder="Ingrese su usuario" autofocus>
                
            </div>
            <div class="mb-3">
            
            <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>
            </span>
            <input  type="password" class="form-control" placeholder="Ingrese su contraseña" name="pwd" [(ngModel)]="login.pwd" required pwd #pwd="ngModel" autocomplete="new-password"  minlength="6" maxlength="12" autofocus>
                
            </div>  
            <div class="mb-3 flex-column">
                <a class="text-dark" href="#">
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1429 8.69298C14.1429 8.5624 14.0625 8.4519 13.942 8.40168C13.9018 8.38159 13.8616 8.37155 13.8214 8.37155C13.7411 8.37155 13.6607 8.40168 13.5904 8.47199L12.9475 9.11485C12.8873 9.17512 12.8571 9.25548 12.8571 9.33583V11.8872C12.8571 12.7711 12.1339 13.4943 11.25 13.4943H2.89286C2.00893 13.4943 1.28571 12.7711 1.28571 11.8872V3.53003C1.28571 2.6461 2.00893 1.92289 2.89286 1.92289H11.25C11.4007 1.92289 11.5513 1.94297 11.702 1.98315C11.7321 1.9932 11.7623 2.00324 11.7924 2.00324C11.8728 2.00324 11.9632 1.96306 12.0234 1.9028L12.5156 1.41061C12.596 1.33025 12.6261 1.22981 12.606 1.11931C12.5859 1.01887 12.5156 0.928466 12.4252 0.888287C12.0536 0.717528 11.6518 0.637171 11.25 0.637171H2.89286C1.29576 0.637171 0 1.93293 0 3.53003V11.8872C0 13.4843 1.29576 14.78 2.89286 14.78H11.25C12.8471 14.78 14.1429 13.4843 14.1429 11.8872V8.69298ZM16.4632 3.78115C16.7846 3.45972 16.7846 2.95748 16.4632 2.63606L15.3583 1.53115C15.0368 1.20972 14.5346 1.20972 14.2132 1.53115L7.71429 8.03003L5.07254 5.38829C4.75112 5.06686 4.24888 5.06686 3.92746 5.38829L2.82254 6.4932C2.50112 6.81463 2.50112 7.31686 2.82254 7.63829L7.14174 11.9575C7.46317 12.2789 7.9654 12.2789 8.28683 11.9575L16.4632 3.78115Z" fill="#656565"/>
                    </svg>
                    Recuerdame
                </a>
                <h6 class="text-decoration-underline" style="color: #F47B20;">Olvide mi usuario o contraseña</h6>
            </div>                 
            <div class="d-flex justify-content-center mt-5">
                <button  type="submit" (click)="onSubmit();f.form.valid;" class="btn w-100 btn-primary mx-2"  aria-controls="modalLogin">
                    Iniciar Sesión
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8332 0.632962C13.0644 0.372829 13.2957 0.372829 13.5269 0.632962L19.2498 6.35588C19.51 6.58711 19.51 6.81834 19.2498 7.04957L13.5269 12.7725C13.2957 13.0326 13.0644 13.0326 12.8332 12.7725L11.9661 11.9487C11.706 11.6886 11.706 11.4429 11.9661 11.2117L15.4779 7.82997H0.520266C0.173422 7.82997 0 7.65655 0 7.3097V6.09575C0 5.74891 0.173422 5.57549 0.520266 5.57549H15.4779L11.9661 2.19376C11.706 1.96253 11.706 1.71685 11.9661 1.45672L12.8332 0.632962Z" fill="white"/>
                        </svg>
                </button>
            </div>
            <div class="mb-3">
                <h6>¿Necesitas ayuda? <a style="color: #F47B20;" href="#">Contáctanos</a></h6>             
            </div> 
            <mat-hint align="end" class="text-danger text-xs text-center" *ngIf="nid.errors && f.submitted">
                <div *ngIf="nid?.errors?.['required']">*Se requiere Usuario</div> 
        </mat-hint>
            <mat-hint align="end" class="text-danger text-xs text-center" *ngIf="pwd.errors && f.submitted">
                    <div *ngIf="pwd?.errors?.['required']">*Se requiere contraseña</div>
                    <div *ngIf="pwd?.errors?.['minlength']">
                        La contraseña debe tener al menos 6 caracteres
                    </div>
            </mat-hint>
            <div class="border-0 bg-transparent text-warning text-xs text-center" *ngIf="f.submitted && isLoginFailed">
                    *Inicio de sesión fallida<br />{{ errorMessage }}
            </div>
        </form>
    </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="content">
            <div class="row row-cols-1 row-cols-md-1 mb-3 text-center">
                <h3 class="text-dark text-center fw-bold py-5">Nuestros estudiantes</h3>
                <div class="col mb-4">
                    <div class="d-flex py-4 rounded-3 elColorCard cardEfect mb-5" style="align-items: center; margin-right: 6.7rem; margin-left: 6.7rem;">
                        <div class="rounded-circle mx-4" style="background-color: #AFAFAF;">
                            <img src="/assets/img/img-est-1.webp" class="img-fluid">
                        </div>
                        <div class="ms-4" style="text-align: left;">
                            <h3>Andreina Tolentino</h3>
                            <p class="mb-2" style="color: #F47B20;">Estudiante</p>
                            <p style="width: 90%; font-size: 0.8rem; text-align: justify;">Logre terminar este ciclo, el cual estuvo lleno de aprendizaje, con maestros capacitado en cada una de las materias impartidas, seguimiento con cada uno de nosotros, conocer a personas capacitadas en diferentes ámbito fue una experiencia extraordinaria, muchas gracias a cada uno de mis compañeros , al coordinador por estar atento y dar seguimiento y a los maestros por hacer que esta maestría una experiencia superior.</p>
                        </div>
                    </div>
        
                    <div class="d-flex py-4 rounded-3 elColorCard cardEfect" style="align-items: center; margin-right: 6.7rem; margin-left: 6.7rem;">
                        <div class="rounded-circle mx-4" style="background-color: #AFAFAF;">
                            <img src="/assets/img/img-est-2.webp" class="img-fluid">
                        </div>
                        <div class="ms-4" style="text-align: left;">
                            <h3>Franklin de Aza</h3>
                            <p class="mb-2" style="color: #F47B20;">Estudiante</p>
                            <p style="width: 90%; font-size: 0.8rem; text-align: justify;">Logre terminar este ciclo, el cual estuvo lleno de aprendizaje, con maestros capacitado en cada una de las materias impartidas, seguimiento con cada uno de nosotros, conocer a personas capacitadas en diferentes ámbito fue una experiencia extraordinaria, muchas gracias a cada uno de mis compañeros , al coordinador por estar atento y dar seguimiento y a los maestros por hacer que esta maestría una experiencia superior.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

