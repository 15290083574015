import { Component, HostBinding, OnInit, ViewChild  } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { service_db } from '../../models/service_db';
import { ServicioService } from '../../services/servicio.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit{
  @ViewChild('stepper')

//   @HostBinding('class') classes = 'itemService'
	@HostBinding('class') classes = '';


  stepperIndex = 0
  stepsTotal = 3
  stepsCompleted = 0
  isStepperCompleted():boolean{
    return this.stepsCompleted === this.stepsTotal
  }


  now = new Date()
  month = String(this.now.getMonth() + 1).padStart(2, '0')
  day = String(this.now.getDate()).padStart(2, '0')
  year = this.now.getFullYear()

  formattedDate = `${this.day}/${this.month}/${this.year}`

  formServiceGroup!:FormGroup
  errorMessage = ''
  successMessage = ''
  isFormSuccess = false
  isFormFailed = false
  isEditable = false
  isSubmitting: boolean = false;

  service:service_db={
    fname: '',
    lname: '',
    cedula: '',
    phone: '',
    email: '',
    coordinador: '',
    programa: '',
    promocion: '',
    tipo: '',
    observ: '',
    fecha: `${this.formattedDate}`
  }

  
  	ngOnInit(){ this.classes = 'itemService'; }

	constructor( private serviceService: ServicioService,  private _fb : FormBuilder) {	
		this.initForms()
	}

	initForms(){
		this.formServiceGroup = this._fb.group({
			info_service: this._fb.group({
				ctrlServiceNombre: this._fb.control('', Validators.required),
				ctrlServiceApellido: this._fb.control('', Validators.required),
				ctrlServiceNid: this._fb.control('', Validators.required),
				ctrlServiceCelular: this._fb.control('', Validators.required),
				ctrlServiceEmail: this._fb.control('', Validators.required)
				
			}),
			info2_service: this._fb.group({
				ctrlServiceCoordinador: this._fb.control('', Validators.required),
				ctrlServiceProgram: this._fb.control('', Validators.required),
				ctrlServicePromocion: this._fb.control('', Validators.required),
				ctrlServiceTipo: this._fb.control('', Validators.required),
				ctrlServiceObserv: this._fb.control('', Validators.required)

			})
		})
	}
 

	
  	get personInfo(){
		return this.formServiceGroup.get('info_service') as FormGroup
	}
	get serviceInfo(){
		return this.formServiceGroup.get('info2_service') as FormGroup
	}
	get invalidEmail(){
		return   this.formServiceGroup.get('ctrlServiceEmail')?.invalid && this.formServiceGroup.get('ctrlServiceEmail')?.touched
	}
	get invalidProgram(){
		return  this.formServiceGroup.get('ctrlServiceProgram')?.invalid && this.formServiceGroup.get('ctrlServiceProgram')?.touched
	}
	get invalidTanda(){
		return  this.formServiceGroup.get('ctrlServiceTipo')?.invalid && this.formServiceGroup.get('ctrlServiceTipo')?.touched
	}

  reloadPage(): void {
	 
     window.location.reload()
	 
  }

//   onSubmit():void{
		
	
// 	if (!this.isSubmitting) {
//         this.isSubmitting = true;
//         this.serviceService.service(this.service).subscribe(
// 			res => {
// 				this.service = res
// 				this.isFormFailed = false
// 				this.successMessage = '*Formulario de Servicios Completado. Gracias!'		
// 				this.isFormSuccess = true
			
// 				setTimeout(()=>{
// 					this.isFormFailed = false
// 					this.isFormSuccess = false
// 					 this.reloadPage()
// 				  },5000)
				
// 			},
// 			err => {
// 				this.errorMessage =  err.error.message
// 				this.isFormSuccess = false						
// 				this.isFormFailed = true
			   
// 				setTimeout(()=>{
// 					this.isFormFailed = false
// 					this.isFormSuccess = false
// 				  }, 5000)
				
// 			})
//     }
	
	
// 	}

onSubmit(): void {
	if (this.formServiceGroup.valid && !this.isSubmitting) {
		this.isSubmitting = true;
		this.serviceService.service(this.service).subscribe(
			res => {
				this.isSubmitting = false;
				this.service = res;
				this.isFormFailed = false;
				this.successMessage = '*Formulario de Servicios Completado. Gracias!';
				this.isFormSuccess = true;
				setTimeout(() => {
					this.resetForm();
				}, 5000);
			},
			err => {
				this.isSubmitting = false;
				this.errorMessage = err.error.message;
				this.isFormSuccess = false;
				this.isFormFailed = true;
				setTimeout(() => {
					this.isFormFailed = false;
				}, 5000);
			}
		);
	}
}

resetForm(): void {
	this.isSubmitting = false;
	this.formServiceGroup.reset();
}
}
