import { Component, Input, OnInit,ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { mesyt_db } from '../../models/mesyt_db';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header">
    <h6 class="modal-title">Proceso de Recopilación de Estadísticas</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <form class="mx-auto" *ngIf="!isFormSuccess"   name="form"   #f="ngForm" novalidate>
            <div class="itemMesyct">
              <mat-horizontal-stepper linear="true" #stepper [selectedIndex]="stepperIndex" [class.d-flex.flex-column.rounded-3]>
                <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">        
                  <div class="row g-0">
                    <div class="col-2">
                      <img  src="https://postgradofces.com/assets/img/logouasd.png" class="bi pe-none " width="60" height="65">
                    </div>
                  <div class="col-4 m-auto px-1">
                    <span class="text-start fw-bold text-heading  text-sm text-primary m-0" style="font-size:0.9rem;">UASD</span>
                    <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Universidad Autonoma</p>
                    <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">De Santo Domingo</p>
                    <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">PRIMADA DE AMERICA</p>
                    <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">FUNDADA EL 28 DE OCTUBRE DE 1538</p>
                  </div>
                  <div class="col-2">
                    <img  src="https://postgradofces.com/assets/img/logo.png" class="bi pe-none" width="60" height="65">
                  </div> 
                  <div class="col-4 m-auto px-1">
                    <span class="text-start fw-bold text-heading  text-sm text-dark m-0" style="font-size:0.9rem;">FCES</span>
                    <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Facultad de Ciencias</p>
                    <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Economicas y Sociales</p>
                    <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">UNIDAD DE POSTGRADO</p>
                  </div>
                </div>
                 

                <div class="col">
                  <h5 class="fw-bold text-primary text-center pt-3">Proceso de Recopilación de Estadísticas</h5>
                  <div class="d-flex pt-3">
                    <h6 class="text-start fw-bold text-heading  text-dark  text-center m-0" style="font-size:0.9rem;">Hola {{ nombre }}, Completar formulario: Proceso de Recopilación de Estadísticas 2025</h6>
                  </div>
                </div>  
                <div class="d-flex justify-content-center mt-3 gap-2">
                  <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                </div>
              </mat-step>
       
              <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                <div>
                    <div class="d-flex mt-2">
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información Personal</span>
                    </div>
                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlMatricula">NO. MATRICULA / NO. CEDULA:</label>
                      <input matInput type="text" name="ctrlMatricula" [(ngModel)]="mesyt.NO_MATRICULA" class="form-control rounded-4 border border-dark border-1"required>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlNombre">NOMBRES</label>
                      <input matInput type="text" name="ctrlNombre"  [(ngModel)]="mesyt.NOMBRES" class="form-control rounded-4 border border-dark border-1" placeholder="NOMBRES" required>
                     
                    </div>
                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlApellido">PRIMER APELLIDO</label>
                      <input matInput type="text" name="ctrlApellido"  [(ngModel)]="mesyt.PRIMER_APELLIDO" class="form-control rounded-4 border border-dark border-1" placeholder="PRIMER APELLIDO" required>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlApellido2">SEGUNDO APELLIDO</label>
                      <input matInput type="text" name="ctrlApellido2"  [(ngModel)]="mesyt.SEGUNDO_APELLIDO" class="form-control rounded-4 border border-dark border-1" placeholder="SEGUNDO APELLIDO" required>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlFechaNac">FECHA DE NACIMIENTO</label>
                      <input matInput type="date" name="ctrlFechaNac"  [(ngModel)]="mesyt.FECHA_NAC" class="form-control rounded-4 border border-dark border-1" placeholder="(dd/mm/aaaa)" required>
                    </div>
                    
                    <div class="d-flex justify-content-center mt-3 gap-2">
                      <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                    </div>
                  </div>
                  </mat-step>

                  <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">

                   
                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlFechaBachiller">FECHA EN QUE TERMINO EL BACHILLERATO O LA CARRERA DE GRADO</label>
                      <input matInput type="date" name="ctrlFechaBachiller"  [(ngModel)]="mesyt.FECHA_BACHILLERATO" class="form-control rounded-4 border border-dark border-1" placeholder="(dd/mm/aaaa)" required>
                    </div>


                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlMunicipioNacimiento">MUNICIPIO DE NACIMIENTO</label>
                      <input matInput type="text" name="ctrlMunicipioNacimiento"  [(ngModel)]="mesyt.MUNICIPIO_NACIMIENTO" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                    </div>


                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlMunicipioResidencia">MUNICIPIO DE RESIDENCIA HABITUAL</label>
                      <input matInput type="text" name="ctrlMunicipioResidencia"  [(ngModel)]="mesyt.MUNICIPIO_RESIDENCIA" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                    </div>

                  <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlPaisOrigen">PAÍS DE ORIGEN (NACIMIENTO)</label>
                      <input matInput type="text" name="ctrlPaisOrigen"  [(ngModel)]="mesyt.PAIS_ORIGEN" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                    </div>


                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlPaisPrevio">PAÍS DE ORIGEN PREVIO A ESTUDIO</label>
                      <input matInput type="text" name="ctrlPaisPrevio"  [(ngModel)]="mesyt.PAIS_PREVIO" class="form-control rounded-4 border border-dark border-1" placeholder="" required>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlModalidad">SELECCIONAR MODALIDAD</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlModalidad"  [(ngModel)]="mesyt.MODALIDAD" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="PRESENCIAL">PRESENCIAL</option>
                        <option class="ms-2" value="SEMI-PRESENCIAL">SEMI-PRESENCIAL</option>
                        <option class="ms-2" value="VIRTUAL">VIRTUAL</option>
                      </select>
                    </div>


                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlNuevoIngreso">ES NUEVO INGRESO (SI/NO)</label>
                      <mat-radio-group style="display: inline-grid;" name="ctrlNuevoIngreso"  [(ngModel)]="mesyt.NUEVO_INGRESO">
                          <mat-radio-button value="SI">SI</mat-radio-button>
                          <mat-radio-button value="NO">NO</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="d-flex justify-content-center mt-3 gap-2">
                      <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Continuar</button>
                    </div>
                  </mat-step> 
                  
                  
                  <mat-step  editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadVer">A. DIFICULTAD PARA VER INCLUSO SI USA LENTES</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadVer"  [(ngModel)]="mesyt.DIFICULTAD_VER" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>
                    

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadOir">B. DIFICULTAD PARA OÍR AUN CUANDO USA UN APARATO AUDITIVO</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadOir"  [(ngModel)]="mesyt.DIFICULTAD_OIR" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadCaminar">C. DIFICULTAD PARA CAMINAR O SUBIR ESCALONES</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadCaminar"  [(ngModel)]="mesyt.DIFICULTAD_CAMINAR" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadRecordar">D. DIFICULTAD PARA RECORDAR O CONCENTRARSE</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadRecordar"  [(ngModel)]="mesyt.DIFICULTAD_RECORDAR" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadRealizar">E. DIFICULTAD PARA REALIZAR TAREAS (NECESITA SOPORTE PARA REALIZAR SU TRABAJO)</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadRealizar"  [(ngModel)]="mesyt.DIFICULTAD_REALIZAR" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>

                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlDficultadComunicarse">F. DIFICULTAD PARA COMUNICARSE (PARA ENTENDER A LAS Y LOS DEMÁS Y PARA QUE LA O LO ENTIENDAN)</label>
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! ; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlDficultadComunicarse"  [(ngModel)]="mesyt.DIFICULTAD_COMUNICARSE" required>
                        <option class="ms-2" value="" disabled>SELECCIONAR</option>
                        <option class="ms-2" value="No, ninguna dificultad">No, ninguna dificultad.</option>
                        <option class="ms-2" value="Sí, cierta dificultad">Sí, cierta dificultad.</option>
                        <option class="ms-2" value="Sí, mucha dificultad">Sí, mucha dificultad.</option>
                      </select>
                    </div>







                    <div class="col-md-6 mx-auto">
                      <button type="submit" (click)="onSubmit();f.form.valid;" class="btn btn-primary">Finalizar</button>
                    </div>                
                
              </mat-step>

            </mat-horizontal-stepper>
          </div>
      </form>
    </div>

  `,
})


export class ModalContentMesyt implements OnInit{

  @Input() nombre?: string;
  @Input() nid?: string;
  @Input() campus?: string;
  @Input() escuela?: string;  
  @Input() periodo?: string;  
  @Input() codigo?: string;  
  @Input() maestria?: string;  
  @Input() sexo?: string;  

  content: any = [];
  currentStep: number = 1; 
  mesyt: mesyt_db;

  @ViewChild('stepper')
  stepperIndex = 0
  stepsTotal = 5
  stepsCompleted = 0
  isStepperCompleted():boolean{
    return this.stepsCompleted === this.stepsTotal
  }

  now = new Date()
  month = String(this.now.getMonth() + 1).padStart(2, '0')
  day = String(this.now.getDate()).padStart(2, '0')
  year = this.now.getFullYear()

  formattedDate = `${this.day}/${this.month}/${this.year}`


  // formMesyctGroup!:FormGroup
  errorMessage = ''
  successMessage = ''
  isFormSuccess = false
  isFormFailed = false
  isEditable = false
  
  


  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    public bsModalRef: BsModalRef,
   
  ) {
    this.mesyt = {
      CAMPUS: '',
      TIPO_DOCUMENTO: 'CEDULA',
      NID: '',
      NO_MATRICULA: '',
      NOMBRES: '',
      PRIMER_APELLIDO: '',
      SEGUNDO_APELLIDO: '',
      SEXO: '',
      FECHA_NAC: '',
      EDAD: '',
      FECHA_BACHILLERATO: '', 
      MUNICIPIO_NACIMIENTO: '',
      MUNICIPIO_RESIDENCIA: '', 
      PAIS_ORIGEN: '', 
      PAIS_PREVIO: '', 
      FACULTAD: 'FACULTAD DE CIENCIAS ECONÓMICAS Y SOCIALES', 
      ESCUELA: '', 
      CARRERA: '', 
      CODIGO: '',
      MODALIDAD: '',
      PERIODO_ACADEMICO: '',
      NIVEL_ACADEMICO: 'MAESTRÍA',
      NUEVO_INGRESO: '',
      DIFICULTAD_VER: '',
      DIFICULTAD_OIR: '',
      DIFICULTAD_CAMINAR: '',
      DIFICULTAD_RECORDAR: '',
      DIFICULTAD_REALIZAR: '',
      DIFICULTAD_COMUNICARSE: '',
      FECHA: `${this.formattedDate}`,      
    };
    
    // this.initForms()
    
  }

  ngOnInit(): void { }

  reloadPage(): void {
    window.location.reload()
  }
  


  
  initForms(){
		// this.formMesyctGroup = this._fb.group({
			
		// 	info: this._fb.group({
		// 		ctrlNombre: this._fb.control('', Validators.required),
		// 		ctrlApellido: this._fb.control('', Validators.required),
		// 		ctrlApellido2: this._fb.control('', Validators.required),
		// 		})
    // })
	}

  // get basicInfo(){
	// 	return this.formMesyctGroup.get('info') as FormGroup
	// }
	
  closeModal() {
    this.bsModalRef.hide();
  }

  

  nextStep(): void {
    if (this.currentStep < 19) { 
      this.currentStep++;
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onSubmit():void{
		if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
  
      this.mesyt.CAMPUS = `${this.campus}`;
      this.mesyt.NID = `${this.nid}`;
      this.mesyt.SEXO = `${this.sexo}`;
      this.mesyt.ESCUELA = `${this.escuela}`;
      this.mesyt.CARRERA = `${this.maestria}`;
      this.mesyt.CODIGO = `${this.codigo}`;
      this.mesyt.PERIODO_ACADEMICO = `${this.periodo}`;


      this.userService.getMesyctBoard(this.mesyt).subscribe(
        res => {
          this.router.navigate(['/user']);
          this.closeModal();
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }
	}

}
