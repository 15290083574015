import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { evaluation_db } from '../../models/evaluation_db';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">{{ asignatura }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <form action="">
      <div class="form-group">
      <mat-horizontal-stepper #stepper>

      <mat-step>
      <div class="row g-0">
      <div class="col-2">
        <img  src="https://postgradofces.com/assets/img/logouasd.png" class="bi pe-none " width="60" height="65">
      </div>
      <div class="col-4 m-auto px-1">
        <span class="text-start fw-bold text-heading  text-sm text-primary m-0" style="font-size:0.9rem;">UASD</span>
        <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Universidad Autonoma</p>
        <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">De Santo Domingo</p>
        <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">PRIMADA DE AMERICA</p>
        <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">FUNDADA EL 28 DE OCTUBRE DE 1538</p>
      </div>
      <div class="col-2">
        <img  src="https://postgradofces.com/assets/img/logo.png" class="bi pe-none" width="60" height="65">
      </div> 
      <div class="col-4 m-auto px-1">
        <span class="text-start fw-bold text-heading  text-sm text-dark m-0" style="font-size:0.9rem;">FCES</span>
        <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Facultad de Ciencias</p>
        <p class="text-start font-semibold text-heading  text-xs text-dark m-0" style="font-size:0.5rem;">Economicas y Sociales</p>
        <p class="text-start text-heading  text-muted  text-dark m-0" style="font-size:0.5rem;">UNIDAD DE POSTGRADO</p>
      </div>
    </div>
        <input type="hidden" name="fname" [(ngModel)]="evaluation.fname"   class="form-control">
        <input type="hidden" name="lname" [(ngModel)]="evaluation.lname"  class="form-control">
        <input type="hidden" name="email" [(ngModel)]="evaluation.email"  class="form-control">
        <input type="hidden" name="programa" [(ngModel)]="evaluation.programa" class="form-control">
        <input type="hidden" name="clave" [(ngModel)]="evaluation.clave"  class="form-control">
        <input type="hidden" name="modulo" [(ngModel)]="evaluation.modulo"  class="form-control">
        <input type="hidden" name="asignatura" [(ngModel)]="evaluation.asignatura"  class="form-control">
        <input type="hidden" name="periodo" [(ngModel)]="evaluation.periodo"  class="form-control">
        <input type="hidden" name="coordinador" [(ngModel)]="evaluation.coordinador" class="form-control">
    <!-- Tabs Question -->
    <!-- Step Front Information -->
    <div class="col">
      <h5 class="fw-bold text-primary text-center pt-3">Usted está evaluando la Asignatura</h5>
      <div class="d-flex rounded-3 border border-0 py-4 px-1" style="background-color: #f37906;">
        <h5 class="text-center fw-bold  text-white ms-auto m-auto">{{ asignatura }}</h5>
      </div>
      <div class="d-flex pt-3">
        <h6 class="text-start fw-bold text-heading  text-dark  text-center m-0" style="font-size:0.9rem;">Responda las preguntas que se le presentan a continuación. Favor hacer clic en el botón para iniciar la evaluación.</h6>
      </div>
    </div>  
    <div class="d-flex justify-content-center mt-3 gap-2">
              <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
              
          </div>
      </mat-step>
       <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Planeación.</p>
        <label>1- Presenta el sílabo o programa al inicio de la asignatura.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p1" [(ngModel)]="evaluation.p1" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p1" [(ngModel)]="evaluation.p1" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p1" [(ngModel)]="evaluation.p1" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p1" [(ngModel)]="evaluation.p1" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p1" [(ngModel)]="evaluation.p1" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        
        
        
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
              <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
              
          </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Planeación.</p>
        <label>2- Demuestra que planifica previamente el proceso que sigue en cada sesión de clases.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p2" [(ngModel)]="evaluation.p2" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p2" [(ngModel)]="evaluation.p2" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p2" [(ngModel)]="evaluation.p2" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p2" [(ngModel)]="evaluation.p2" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p2" [(ngModel)]="evaluation.p2" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Planeación.</p>
        <label>3- El desarrollo de la asignatura ha estado acorde con el programa presentado.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p3" [(ngModel)]="evaluation.p3" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p3" [(ngModel)]="evaluation.p3" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p3" [(ngModel)]="evaluation.p3" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p3" [(ngModel)]="evaluation.p3" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p3" [(ngModel)]="evaluation.p3" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Competencias Profesionales.</p>
        <label>4- Muestra dominio de los temas y se mantiene actualizado en su área.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p4" [(ngModel)]="evaluation.p4" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p4" [(ngModel)]="evaluation.p4" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p4" [(ngModel)]="evaluation.p4" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p4" [(ngModel)]="evaluation.p4" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p4" [(ngModel)]="evaluation.p4" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Competencias Profesionales.</p>
        <label>5- Se comunica adecuadamente y responde de manera clara y precisa las preguntas de los estudiantes.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p5" [(ngModel)]="evaluation.p5" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p5" [(ngModel)]="evaluation.p5" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p5" [(ngModel)]="evaluation.p5" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p5" [(ngModel)]="evaluation.p5" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p5" [(ngModel)]="evaluation.p5" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Competencias Profesionales.</p>
        <label>6- Relaciona los contenidos con el campo profesional y/o con otras asignaturas.</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p6" [(ngModel)]="evaluation.p6" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p6" [(ngModel)]="evaluation.p6" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p6" [(ngModel)]="evaluation.p6" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p6" [(ngModel)]="evaluation.p6" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p6" [(ngModel)]="evaluation.p6" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Competencias Profesionales.</p>
        <label>7- Muestra capacidad y liderazgo para manejar el grupo?.</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p7" [(ngModel)]="evaluation.p7" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p7" [(ngModel)]="evaluation.p7" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p7" [(ngModel)]="evaluation.p7" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p7" [(ngModel)]="evaluation.p7" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p7" [(ngModel)]="evaluation.p7" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Estrategias Metodológicas y Recursos de Aprendizajes.</p>
        <label>8- Motiva a los estudiantes a participar, a integrarse y a reflexionar sobre los temas tratados.?.</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p8" [(ngModel)]="evaluation.p8" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p8" [(ngModel)]="evaluation.p8" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p8" [(ngModel)]="evaluation.p8" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p8" [(ngModel)]="evaluation.p8" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p8" [(ngModel)]="evaluation.p8" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Estrategias Metodológicas y Recursos de Aprendizajes.</p>
        <label>9- Sugiere y usa fuentes de información actualizadas (Bases de datos, Bibliografías).?.</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p9" [(ngModel)]="evaluation.p9" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p9" [(ngModel)]="evaluation.p9" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p9" [(ngModel)]="evaluation.p9" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p9" [(ngModel)]="evaluation.p9" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p9" [(ngModel)]="evaluation.p9" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Relación interpersonal y desarrollo de valores.</p>
        <label>10- Promueve un ambiente de confianza y respeto entre todos/as.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p10" [(ngModel)]="evaluation.p10" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p10" [(ngModel)]="evaluation.p10" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p10" [(ngModel)]="evaluation.p10" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p10" [(ngModel)]="evaluation.p10" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p10" [(ngModel)]="evaluation.p10" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Relación interpersonal y desarrollo de valores.</p>
        <label>11- Muestra interés por el aprendizaje de sus estudiantes.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p11" [(ngModel)]="evaluation.p11" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p11" [(ngModel)]="evaluation.p11" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p11" [(ngModel)]="evaluation.p11" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p11" [(ngModel)]="evaluation.p11" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p11" [(ngModel)]="evaluation.p11" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Relación interpersonal y desarrollo de valores.</p>
        <label>12- Su comportamiento y actuaciones están enmarcados dentro la ética profesional.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p12" [(ngModel)]="evaluation.p12" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p12" [(ngModel)]="evaluation.p12" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p12" [(ngModel)]="evaluation.p12" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p12" [(ngModel)]="evaluation.p12" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p12" [(ngModel)]="evaluation.p12" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Relación interpersonal y desarrollo de valores.</p>
        <label>13- Promuevo el sentido de pertenencia a la Institución.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p13" [(ngModel)]="evaluation.p13" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p13" [(ngModel)]="evaluation.p13" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p13" [(ngModel)]="evaluation.p13" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p13" [(ngModel)]="evaluation.p13" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p13" [(ngModel)]="evaluation.p13" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Evaluación de los aprendizajes</p>
        <label>14- El profesor establece claramente el valor de cada asignación y evaluación durante el desarrollo de la asignatura.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p14" [(ngModel)]="evaluation.p14" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p14" [(ngModel)]="evaluation.p14" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p14" [(ngModel)]="evaluation.p14" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p14" [(ngModel)]="evaluation.p14" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p14" [(ngModel)]="evaluation.p14" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Evaluación de los aprendizajes</p>
        <label>15-  Mantiene informado al alumno sobre su progreso en la asignatura.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p15" [(ngModel)]="evaluation.p15" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p15" [(ngModel)]="evaluation.p15" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p15" [(ngModel)]="evaluation.p15" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p15" [(ngModel)]="evaluation.p15" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p15" [(ngModel)]="evaluation.p15" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Elementos Externos al/la Docente</p>
        <p class="text-center fw-bold pb-2">Papel del Coordinador</p>
        <label>1-  Da seguimiento a los procesos de asignacion docente en el tiempo reglamentario?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p16" [(ngModel)]="evaluation.p16" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p16" [(ngModel)]="evaluation.p16" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p16" [(ngModel)]="evaluation.p16" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p16" [(ngModel)]="evaluation.p16" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p16" [(ngModel)]="evaluation.p16" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Elementos Externos al/la Docente</p>
        <p class="text-center fw-bold pb-2">Papel del Coordinador</p>
        <label>2-  Garantiza la satisfacion de el los docentes y estudiantes del programa, en entrega de programas, calificaciones, puntualidad?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p17" [(ngModel)]="evaluation.p17" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p17" [(ngModel)]="evaluation.p17" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p17" [(ngModel)]="evaluation.p17" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p17" [(ngModel)]="evaluation.p17" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p17" [(ngModel)]="evaluation.p17" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Elementos Externos al/la Docente</p>
        <p class="text-center fw-bold pb-2">Papel del Coordinador</p>
        <label>3-  Garantiza el cumplimiento de la calidad del contendio del programa?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p18" [(ngModel)]="evaluation.p18" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p18" [(ngModel)]="evaluation.p18" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p18" [(ngModel)]="evaluation.p18" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p18" [(ngModel)]="evaluation.p18" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p18" [(ngModel)]="evaluation.p18" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
          <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
        </div>
        </mat-step>
        <mat-step>
        <div class="d-block">
        <p class="text-center fw-bold pb-2">Elementos Externos al/la Docente</p>
        <p class="text-center fw-bold pb-2">Papel del Coordinador</p>
        <label>4-  Mantiene una comunicación efectiva tanto con el alumno  como con el profesor.?</label>
  
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto mt-3">
                                                     
        <label class="list-group-item py-2">Nunca o Muy mal
            <input type="radio" name="p19" [(ngModel)]="evaluation.p19" [value]="1">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi nunca o Mal
            <input type="radio" name="p19" [(ngModel)]="evaluation.p19" [value]="2">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">A veces o Regular
            <input type="radio" name="p19" [(ngModel)]="evaluation.p19" [value]="3">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Casi siempre o Bueno
            <input type="radio" name="p19" [(ngModel)]="evaluation.p19" [value]="4">
            <span class="checkmark"></span>
          </label>
          </div>
          <div class="d-grid gap-2 border-0  btn-hover d-block m-auto ">
                                                     
          <label class="list-group-item py-2">Siempre o Muy Bueno
            <input type="radio" name="p19" [(ngModel)]="evaluation.p19" [value]="5">
            <span class="checkmark"></span>
          </label>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3 gap-2">
              
        <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="stepper.next()">Siguiente</button>
          </div>
        </mat-step>

        <mat-step>
          <div class="d-block">
            <h5 class="fw-bold text-primary text-center pt-3">Usted a evaluando la Asignatura</h5>
            <div class="d-flex rounded-3 border border-0 py-4 px-1" style="background-color: #f37906;">
              <h5 class="text-center fw-bold  text-white ms-auto m-auto">{{ asignatura }}</h5>
            </div>
            <div class="d-flex justify-content-center pt-3">
              <h6 class="text-start fw-bold text-heading  text-dark  text-center m-0" style="font-size:0.9rem;">Evaluación Finalizada, Gracias 👍</h6>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3 gap-2">
            <button type="button" class="btn rounded-5 w-50" style="font-weight: 80; font-size: 0.8rem; background-color: #F47B20; color: #FFF;" (click)="finishEvaluation()">Finalizar</button>
          </div>
        </mat-step>
        </mat-horizontal-stepper>
          </div>
      </form>
    </div>

  `,
})


export class ModalContentComponent implements OnInit {

  @Input() nombre?: string;
  @Input() apellido?: string;
  @Input() email?: string;
  @Input() programa?: string;
  @Input() asignatura?: string;
  @Input() modulo?: string;
  @Input() periodo?: string;
  @Input() coordinador?: string;
  @Input() profesor?: string;
  @Input() clave?: string;

  content: any = [];
  evaluation: evaluation_db;
  currentStep: number = 1; 

  @ViewChild('stepper')
  stepperIndex = 0
  stepsTotal = 5
  stepsCompleted = 0
  isStepperCompleted():boolean{
    return this.stepsCompleted === this.stepsTotal
  }



  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    public bsModalRef: BsModalRef
  ) {
    this.evaluation = {
      fname: '',
      lname: '',
      email: '',
      programa: '',
      asignatura: '',
      clave: '',
      modulo: '',
      periodo: '',
      coordinador: '',
      profesor: '',
      fecha: this.getFormattedDate(),
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0,
      p7: 0,
      p8: 0,
      p9: 0,
      p10: 0,
      p11: 0,
      p12: 0,
      p13: 0,
      p14: 0,
      p15: 0,
      p16: 0,
      p17: 0,
      p18: 0,
      p19: 0
    };
  }

  ngOnInit(): void { }

  closeModal() {
    this.bsModalRef.hide();
  }

  getFormattedDate(): string {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
    return `${day}/${month}/${year}`;
  }

  nextStep(): void {
    if (this.currentStep < 19) { 
      this.currentStep++;
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

finishEvaluation(): void {
  if (!this.authService.isLoggedIn()) {
    this.router.navigate(['/']);
  } else {

    this.evaluation.fname = this.nombre;
    this.evaluation.lname = this.apellido;
    this.evaluation.email = this.email;
    this.evaluation.programa = this.programa;
    this.evaluation.asignatura = this.asignatura;
    this.evaluation.clave = this.clave;
    this.evaluation.modulo = this.modulo;
    this.evaluation.periodo = this.periodo;
    this.evaluation.coordinador = this.coordinador;
    this.evaluation.profesor = this.profesor;

    this.userService.getEvaluationBoardP(this.evaluation).subscribe(
      res => {
        this.router.navigate(['/u/user-calificacion']);
        this.closeModal();
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
  }
}
}
