
<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 inicio text-dark">
      <div class="card border-0 px-2 rounded-3">
        <div class="card-header bg-white border-0 d-flex justify-content-center">
          <h3 class="text-start pt-3">Solicitud de Admisión</h3>
        </div>
        <div class="card-body p-0">
          <form class="mx-auto" [formGroup]="formAdmisionGroup" *ngIf="!isFormSuccess"   name="form"   #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
            <div class="itemAdmision">
              <mat-horizontal-stepper linear="true" #stepper [selectedIndex]="stepperIndex" [class.d-flex.flex-column.rounded-3]>
                <mat-step [stepControl]="basicInfoProgram" editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_program">
                    <div class="d-flex mt-2 mb-4">
                      <span class="badge rounded-pill w-100 text-white bg-primary p-3 rounded-4 justify-content-center">Seleccionar Programa y Horario</span>
                    </div>
                    <div class="input-group input-group-static">
                                                                         
                      <select id="programa" class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1"  name="programa"   formControlName="ctrlInputProgram" [(ngModel)]="admision.programa"  onclick="selectAdmision();" required>
                         <option class="ms-2" value="" disabled>Seleccionar Maestría</option>
                        
                    </select> 
                    <!-- <mat-hint class="text-danger" style="font-size: 0.7 rem;" align="end" *ngIf="ctrlInputProgram.errors && f.submitted">
                      <div *ngIf="ctrlInputProgram?.errors"><strong>*Se requiere que selecciones maestría.</strong></div>
                    </mat-hint> -->
                    </div>
                    
                    <div class="input-group input-group-static">
                                                                         
                      <select id="horario" class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="tanda" formControlName="ctrlInputTanda" [(ngModel)]="admision.tanda" required>
                        <option class="ms-2" value="" disabled>Seleccionar Horario</option>
                                  
                      </select>
                    </div>
                    <div class="col-md-6 mx-auto">
                      <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                    </div>
                  </div>
                </mat-step>
                <mat-step [stepControl]="basicInfo" editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info">
                    <div class="d-flex mt-2">
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información Personal</span>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctrlInputNombre" formControlName="ctrlInputNombre" [(ngModel)]="admision.fname" class="form-control rounded-4 border border-dark border-1" placeholder="Nombres" required>
                     
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctrlInputApellido" formControlName="ctrlInputApellido" [(ngModel)]="admision.lname" class="form-control rounded-4 border border-dark border-1" placeholder="Apellidos" required>
                    </div>

                    <div class="mb-3">
                      <input matInput type="tel" name="ctrlInputCedula" formControlName="ctrlInputCedula" [(ngModel)]="admision.cedula" class="form-control rounded-4 border border-dark border-1" pattern="[\.\+\-\(\)0-9 ]+" placeholder="No. Identificacion" required>
                    </div>

                    <div class="d-flex justify-items-center gap-5 mb-3 bg-light rounded-3 border border-1 p-2">
                      <div class="d-block">
                        <p class="text-sm fw-bold">Sexo</p>
                        <div class="d-flex d-inline pb-3">
                        <mat-radio-group style="display: inline-grid;" name="ctlrInputSexo"  [(ngModel)]="admision.sexo" formControlName="ctlrInputSexo">
                          <mat-radio-button value="Femenino">Femenino</mat-radio-button>
                          <mat-radio-button value="Masculino">Masculino</mat-radio-button>
                        </mat-radio-group>
                        </div>
                      </div>
                      <div class="d-block">
                        <p class="text-sm fw-bold">Estado Civil</p>
                        <div class="d-flex d-inline pb-3">
                          <mat-radio-group style="display: inline-grid;" name="ctlrInputEstado"  [(ngModel)]="admision.estado_civil" formControlName="ctlrInputEstado">
                            <mat-radio-button value="Soltero">Soltero</mat-radio-button>
                            <mat-radio-button value="Casado">Casado</mat-radio-button>
                          
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                    
                    <div class="d-block mb-3">
                      <label class="text-sm fw-bold" for="ctrlInputFecNa">Fecha de Nacimiento:</label>
                      <input matInput id="ctrlInputFecNa" type="date" name="ctrlInputFecNac" formControlName="ctrlInputFecNac" [(ngModel)]="admision.fecha_nac" class="form-control rounded-4 border border-dark border-1 w-100" placeholder="Fecha de Nacimiento" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctrlInputLugNac" formControlName="ctrlInputLugNac" [(ngModel)]="admision.lugar_nac" class="form-control rounded-4 border border-dark border-1" placeholder="Lugar de Nacimiento" required>
                    </div>
                    <div class="mb-3">
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlInputDependientes" formControlName="ctrlInputDependientes" [(ngModel)]="admision.dependientes" required>
                        <option class="ms-2" value="" disabled>Cantidad Dependientes</option>
                        <option class="ms-2" value="0">0</option>
                        <option class="ms-2" value="1">1</option>
                        <option class="ms-2" value="2">2</option>
                        <option class="ms-2" value="3">3</option>
                        <option class="ms-2" value="4">4</option>
                        <option class="ms-2" value="5">5</option>
                        <option class="ms-2" value="6">6</option>
                        <option class="ms-2" value="7">7</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <input matInput type="tel" name="ctrlInputCelular" formControlName="ctrlInputCelular" [(ngModel)]="admision.cel" class="form-control rounded-4 border border-dark border-1" pattern="[\.\+\-\(\)0-9 ]+" placeholder="Celular" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="email" class="form-control rounded-4 border border-dark border-1" name="email" formControlName="ctrlInputEmail" [(ngModel)]="admision.email" placeholder="Email" placeholder="Correo Electrónico" required>
                    </div>




                    <div class="col-md-6 mx-auto">
                      <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                    </div>
                  
                  </div>
                </mat-step>
                <mat-step [stepControl]="basicAcademy" editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_academy">
                    <div class="d-flex mt-2">
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información académica</span>
                    </div>

                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputProfesion" formControlName="ctlrInputProfesion" [(ngModel)]="admision.profesion" class="form-control rounded-4 border border-dark border-1" placeholder="Profesion" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputGradoAcademico" formControlName="ctlrInputGradoAcademico" [(ngModel)]="admision.grado_academico" class="form-control rounded-4 border border-dark border-1" placeholder="Grado Académico" required>
                    </div>
                    <div class="mb-3">
                      <select  class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 0.9rem;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctlrInputUniversidad" formControlName="ctlrInputUniversidad" [(ngModel)]="admision.uni_procedencia" placeholder="Universidad de procedencia" required>
                        <option class="ms-2" value="" disabled>Universidad de procedencia</option>
                        <option class="ms-2" value="Universidad Autónoma de Santo Domingo">Universidad Autónoma de Santo Domingo</option>
                        <option class="ms-2" value="Pontificia Universidad Católica Madre y Maestra">Pontificia Universidad Católica Madre y Maestra</option>
                        <option class="ms-2" value="Universidad Nacional Pedro Henríquez Ureña">Universidad Nacional Pedro Henríquez Ureña</option>
                        <option class="ms-2" value="Universidad APEC">Universidad APEC</option>
                        <option class="ms-2" value="Universidad Central del Este">Universidad Central del Este</option>
                        <option class="ms-2" value="Instituto Tecnológico de Santo Domingo">Instituto Tecnológico de Santo Domingo</option>
                        <option class="ms-2" value="Instituto Nacional de Ciencias Exactas ">Instituto Nacional de Ciencias Exactas </option>
                        <option class="ms-2" value="Universidad Dominicana, O&M">Universidad Dominicana, O & M</option>
                        <option class="ms-2" value="Universidad Tecnológica de Santiago">Universidad Tecnológica de Santiago</option>
                        <option class="ms-2" value="Universidad Nordestana">Universidad Nordestana</option>
                        <option class="ms-2" value="Universidad Iberoamericana">Universidad Iberoamericana</option>
                        <option class="ms-2" value="Universidad Adventista Dominicana">Universidad Adventista Dominicana</option>
                        <option class="ms-2" value="Universidad Interamericana">Universidad Interamericana</option>
                        <option class="ms-2" value="Instituto Tecnológico del Cibao Oriental ">Instituto Tecnológico del Cibao Oriental</option>
                        <option class="ms-2" value="Universidad Tecnológica del Sur">Universidad Tecnológica del Sur</option>
                        <option class="ms-2" value="Universidad Católica de Santo Domingo">Universidad Católica de Santo Domingo</option>
                        <option class="ms-2" value="Universidad Eugenio Maria de Hostos">Universidad Eugenio Maria de Hostos</option>
                        <option class="ms-2" value="Universidad Colegio de Estudios Profesionales">Universidad Colegio de Estudios Profesionales</option>
                        <option class="ms-2" value="Universidad Odontológica Dominicana">Universidad Odontológica Dominicana</option>
                        <option class="ms-2" value="Instituto Superior de Agricultura">Instituto Superior de Agricultura</option>
                        <option class="ms-2" value="Universidad Nacional Evangélica">Universidad Nacional Evangélica</option>
                        <option class="ms-2" value="Universidad Tecnológica del Cibao">Universidad Tecnológica del Cibao</option>
                        <option class="ms-2" value="Universidad Federico Henriquez y Carvajal">Universidad Federico Henriquez y Carvajal</option>
                        <option class="ms-2" value="Universidad de la Tercera Edad">Universidad de la Tercera Edad</option>
                        <option class="ms-2" value="Universidad Abierta para Adultos">Universidad Abierta para Adultos</option>
                        <option class="ms-2" value="Universidad del Caribe">Universidad del Caribe</option>
                        <option class="ms-2" value="Universidad Agroforestal F. Arturo de Meriño">Universidad Agroforestal F. Arturo de Meriño</option>
                        <option class="ms-2" value="Universidad Félix Adam">Universidad Félix Adam</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputIdiomas" formControlName="ctlrInputIdiomas" [(ngModel)]="admision.idiomas" class="form-control rounded-4 border border-dark border-1" placeholder="Idiomas que domina" required>
                    </div>
                    <div class="d-inline mb-3">
                          <p class="text-sm fw-bold">¿Cómo se enteró del programa?</p>
                        <div class="d-flex d-inline pb-3">
                          <mat-radio-group name="ctlrInputMedios"  [(ngModel)]="admision.medios" formControlName="ctlrInputMedios">
                            <mat-radio-button value="Redes Sociales">Redes Sociales</mat-radio-button>
                            <mat-radio-button value="Prensa">Prensa</mat-radio-button>
                            <mat-radio-button value="Un amigo">Un amigo</mat-radio-button>
                            <mat-radio-button value="Otro">Otro</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    <div class="col-md-6 mx-auto">
                      <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                    </div>
                  </div>
                </mat-step>
                <mat-step [stepControl]="basicJob" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_job">
                    <div class="d-flex mt-2">
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información Laboral</span>
                    </div>
                  <div class="mb-3">
                      <input matInput type="text" name="ctlrInputTrabajo" formControlName="ctlrInputTrabajo" [(ngModel)]="admision.lugar_trabajo" class="form-control rounded-4 border border-dark border-1" placeholder="Lugar de trabajo" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputDireccion" formControlName="ctlrInputDireccion" [(ngModel)]="admision.direccion" class="form-control rounded-4 border border-dark border-1" placeholder="Dirección" required>
                    </div>
                    <div class="mb-3">
                      <!-- <input matInput type="text" name="ingresos" formControlName="ctlrInputIngresos"  ingresos #ingresos="ngModel" [(ngModel)]="admision.ingresos" class="form-control rounded-4 border border-dark border-1" placeholder="Ingresos mensuales" required> -->
                      <input matInput type="text" name="ingresos"  formControlName="ctlrInputIngresos" [(ngModel)]="admision.ingresos"  class="form-control rounded-4 border border-dark border-1"   placeholder="Ingresos mensuales" required>
                      <!-- <mat-hint class="text-danger" style="font-size: 0.7 rem;" align="end" *ngIf="ingresos.errors && f.submitted">
                        <div *ngIf="ingresos?.errors?.['required']"><p>*Se requiere Ingresos.</p></div>
                      </mat-hint> -->
                    </div>
                    
                    <div class="mb-3">
                      <input matInput type="number" name="ctlrInputOtrosIngresos" formControlName="ctlrInputOtrosIngresos" [(ngModel)]="admision.otros_ingresos" class="form-control rounded-4 border border-dark border-1" placeholder="Otros ingresos" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputOcupacion" formControlName="ctlrInputOcupacion" [(ngModel)]="admision.ocupacion" class="form-control rounded-4 border border-dark border-1" placeholder="Ocupación"  required>
                    </div>
                  </div>
                   <div class="col-md-6 mx-auto">
                      <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                  </div>
                </mat-step>
                <mat-step [stepControl]="basicRef" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_refPersonal1">
                    <div class="d-flex mt-2">
                   
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Primera Referencia Personal</span>
                    </div>
      
                  <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefNombre1" formControlName="ctlrInputrefNombre1" [(ngModel)]="admision.ref_nom_1" class="form-control rounded-4 border border-dark border-1" placeholder="Nombre" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefDireccion1" formControlName="ctlrInputrefDireccion1" [(ngModel)]="admision.ref_addr_1" class="form-control rounded-4 border border-dark border-1" placeholder="Dirección" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="number" name="ctlrInputrefTelefono1" formControlName="ctlrInputrefTelefono1" [(ngModel)]="admision.ref_tel_1" class="form-control rounded-4 border border-dark border-1" placeholder="Telefono" required>
                    </div>
                  </div>
                   <div class="col-md-6 mx-auto">
                    <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="basicRef" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_refPersonal2">
                    <div class="d-flex mt-2">
                   
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Segunda Referencia Personal</span>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefNombre2" formControlName="ctlrInputrefNombre2" [(ngModel)]="admision.ref_nom_2" class="form-control rounded-4 border border-dark border-1" placeholder="Nombre" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefDireccion2" formControlName="ctlrInputrefDireccion2" [(ngModel)]="admision.ref_addr_2" class="form-control rounded-4 border border-dark border-1" placeholder="Dirección" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="number" name="ctlrInputrefTelefono2" formControlName="ctlrInputrefTelefono2" [(ngModel)]="admision.ref_tel_2" class="form-control rounded-4 border border-dark border-1" placeholder="Telefono" required>
                    </div>
                </div>
                   <div class="col-md-6 mx-auto">
                      <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="basicRef" (completedChange)="stepsCompleted = stepsCompleted + 1">
                  <div formGroupName="info_refPersonal3">
                    <div class="d-flex mt-2">
                   
                      <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Tercera Referencia Personal</span>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefNombre3" formControlName="ctlrInputrefNombre3" [(ngModel)]="admision.ref_nom_3" class="form-control rounded-4 border border-dark border-1" placeholder="Nombre" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="text" name="ctlrInputrefDireccion3" formControlName="ctlrInputrefDireccion3" [(ngModel)]="admision.ref_addr_3" class="form-control rounded-4 border border-dark border-1" placeholder="Dirección" required>
                    </div>
                    <div class="mb-3">
                      <input matInput type="number" name="ctlrInputrefTelefono3" formControlName="ctlrInputrefTelefono3" [(ngModel)]="admision.ref_tel_3" class="form-control rounded-4 border border-dark border-1" placeholder="Telefono" required>
                    </div>
                  </div>
                   <div class="col-md-6 mx-auto">
                      <button type="submit" (click)="onSubmit();f.form.valid;" class="btn btn-primary">Finalizar</button>
                    </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>      
          </form>
          <div class="alert alert-danger text-center text-sm fw-bold text-white"  *ngIf="isFormFailed">{{errorMessage}}</div>
          <div class="alert alert-success text-center text-sm fw-bold  text-dark" *ngIf="isFormSuccess">{{successMessage}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-8 bg-hide-col  px-0">   -->
    <div class="col-md-8 pt-3">
      <div class="row">
        <div class="col d-flex flex-column align-items-start gap-2">
          <h3 class="fw-semibold fs-6" style="color:#F47B20">¡Tu próximo paso al siguiente nivel!</h3>
          <h2 class="text-dark text-start fw-bold">Admisión</h2>
          <p class="text-body-secondary">Estamos felices de que estés interesado en formar parte de nuestra comunidad académica. A continuación, encontrarás todo lo que necesitas para completar tu solicitud de admisión de manera exitosa:</p>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex flex-column align-items-start gap-2 p-5">
          <div class="m-0 border-0">
    
        <ul class="list-unstyled">
          <li class="text-muted">Instrucciones para completar la solicitud:
            <ul class="mt-5">
              <li> Lee cuidadosamente antes de comenzar a llenar el formulario.</li>
              <li> Completa todos los campos  proporciona la información solicitada de manera precisa y completa.</li>
              <li> Una vez completada la solicitud, revisa minuciosamente todos los detalles antes de enviarla para asegurarte de que esté correcta.</li>
              <li> Luego de enviarla nos estaremos poniendo en contacto contigo para indicarte los siguientes pasos.</li>
            </ul>
          </li>
        </ul>
        </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="content pt-0 mt-0">
    <div class="row">
      <div class="px-4 py-5 my-5 text-center rounded-3" style="background-color: #F47B20;">
        <h1 class="display-6 fw-bold text-white">Suscríbete a nuestro newsletter</h1>
        <div class="col-lg-6 mx-auto">
          <p class="text-white mb-4">Obtén todas las ofertas de maestrías nuevas y fechas de convocatorias a través del correo electrónico.</p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <div class="input-group">
              <input matInput type="text" class="form-control" placeholder="Ingresa tu correo electrónico">
              <a href="#" class="input-group-text p-3 rounded-end-5 bg-primary text-white">Subscribe</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
      <div class="col">
        <div class="row">
          <div class="col">
            <img
              src="/assets/img/admision/img-admision-model-3.webp"
              data-mdb-img="/assets/img/admision/img-admision-model-3.webp"
              alt="Estudiantes"
              class="w-100 shadow-1-strong rounded"
            />
            <img
              src="/assets/img/admision/img-admision-model-2.webp"
              data-mdb-img="/assets/img/admision/img-admision-model-2.webp"
              alt="Estudiantes"
              class="w-75 shadow-1-strong mt-2 float-end rounded"
            />
          </div>
          <div class="col">
            <img
              src="/assets/img/admision/img-admision-model-1.webp"
              data-mdb-img="/assets/img/admision/img-admision-model-1.webp"
              alt="Estudiantes"
              class="w-100 mb-2 mb-md-4 shadow-1-strong rounded"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="d-block">
          <p class="fs-6 text-start" style="color: #F47B20;">Requisitos de admisión</p>
          <h3 class="fw-bold text-start">Estudiantes de postgrado</h3>
        </div>
        <p class="text-sm text-start p-2 text-body-secondary">Para ser considerado como candidato, debes cumplir con los siguientes requisitos:</p>
        
        <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
          <div class="list-group text-white gap-2 w-100">
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Record Notas Legalizado MESCYT.</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Carta de Grado Legalizado MESCYT.</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Acta de Nacimiento Original.</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">2 Fotos 2x2.</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Fotocopia de la Cédula a color.</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Hoja de Vida (Curriculum Vitae).</p>
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-start rounded-4 ps-4 p-2" style="background-color:#F99E00; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
              <div>
                  <p class="mb-0">Certificado Médico UASD.</p>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
      <div class="col m-0 p-0">
        <div class="d-flex justify-content-start">
          <button class="btn btn-light bg-light px-2" style="color: #F47B20;">Beneficios</button>
        </div>
        <h2 class="fw-bold text-start p-2"><p style="color: #F47B20;">Modelo Educativo Actualizado</p></h2>
        <div class="list-group">
                  <div class="d-flex text-start  mb-0 mt-2 p-2">
                    <svg width="80" height="80" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_1688_488)">
              <circle cx="74" cy="59" r="30" fill="#FBFBFB"/>
              </g>
              <rect x="61" y="46" width="11.8182" height="11.8182" rx="2" fill="#F09A00"/>
              <rect x="61" y="60.1819" width="11.8182" height="11.8182" rx="2" fill="#F09A00"/>
              <rect x="75.7729" y="46" width="11.8182" height="11.8182" rx="2" fill="#F09A00"/>
              <rect x="75.7729" y="60.1819" width="11.8182" height="11.8182" rx="2" fill="#FD661F"/>
              <defs>
              <filter id="filter0_d_1688_488" x="0" y="0" width="148" height="148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="15"/>
              <feGaussianBlur stdDeviation="22"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0588235 0 0 0 0 0.109804 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1688_488"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1688_488" result="shape"/>
              </filter>
              </defs>
                    </svg>
                  <div class="px-2">
                    <p class="text-sm text-dark text-start mt-2 mb-0">Desarrollo integral para abordar estratégicamente y resolver desafíos globales.</p>
                  </div>
                  </div>
                  <div class="d-flex text-start  mb-0 mt-2 p-2">
                    <svg width="80" height="80" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_1688_497)">
                      <circle cx="74" cy="59" r="30" fill="#FBFBFB"/>
                      </g>
                      <rect x="68" y="52" width="20" height="20" rx="2" fill="#F09A00"/>
                      <rect x="60" y="46" width="21.2245" height="21.2245" rx="2" fill="#FD661F"/>
                      <defs>
                      <filter id="filter0_d_1688_497" x="0" y="0" width="148" height="148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="15"/>
                      <feGaussianBlur stdDeviation="22"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0588235 0 0 0 0 0.109804 0 0 0 0.12 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1688_497"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1688_497" result="shape"/>
                      </filter>
                      </defs>
                    </svg>
                  <div>
                    <p class="text-sm text-dark text-start mt-2 mb-0">Plataforma de apoyo disponible las 24 horas, los 7 días de la semana; compatible con dispositivos móviles.</p>
                  </div>
                  </div>
                  <div class="d-flex text-start  mb-0 mt-2 p-2">
                    <svg width="80" height="80" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_1688_504)">
                      <circle cx="74" cy="59" r="30" fill="#FBFBFB"/>
                      </g>
                      <path d="M63.5 57.375C65.1547 57.375 66.5 55.9176 66.5 54.125C66.5 52.3324 65.1547 50.875 63.5 50.875C61.8453 50.875 60.5 52.3324 60.5 54.125C60.5 55.9176 61.8453 57.375 63.5 57.375ZM84.5 57.375C86.1547 57.375 87.5 55.9176 87.5 54.125C87.5 52.3324 86.1547 50.875 84.5 50.875C82.8453 50.875 81.5 52.3324 81.5 54.125C81.5 55.9176 82.8453 57.375 84.5 57.375ZM86 59H83C82.175 59 81.4297 59.3605 80.8859 59.9445C82.775 61.0668 84.1156 63.093 84.4062 65.5H87.5C88.3297 65.5 89 64.7738 89 63.875V62.25C89 60.4574 87.6547 59 86 59ZM74 59C76.9016 59 79.25 56.4559 79.25 53.3125C79.25 50.1691 76.9016 47.625 74 47.625C71.0984 47.625 68.75 50.1691 68.75 53.3125C68.75 56.4559 71.0984 59 74 59ZM77.6 60.625H77.2109C76.2359 61.1328 75.1531 61.4375 74 61.4375C72.8469 61.4375 71.7688 61.1328 70.7891 60.625H70.4C67.4187 60.625 65 63.2453 65 66.475V67.9375C65 69.2832 66.0078 70.375 67.25 70.375H80.75C81.9922 70.375 83 69.2832 83 67.9375V66.475C83 63.2453 80.5812 60.625 77.6 60.625ZM67.1141 59.9445C66.5703 59.3605 65.825 59 65 59H62C60.3453 59 59 60.4574 59 62.25V63.875C59 64.7738 59.6703 65.5 60.5 65.5H63.5891C63.8844 63.093 65.225 61.0668 67.1141 59.9445Z" fill="#F09A00"/>
                      <defs>
                      <filter id="filter0_d_1688_504" x="0" y="0" width="148" height="148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="15"/>
                      <feGaussianBlur stdDeviation="22"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0588235 0 0 0 0 0.109804 0 0 0 0.12 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1688_504"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1688_504" result="shape"/>
                      </filter>
                      </defs>
                    </svg>
                    <div>
                      <p class="text-sm text-dark text-start mt-2 mb-0">Apoyo constante de tutores, docentes y asesores académicos con amplia experiencia.</p>
                    </div>
                  </div>
        </div>
      </div>
  
      <div class="col">
        <img class="img-fluid" src="/assets/img/img-admision-model-0.png" >
      </div>
    </div>
  </div>
</div>