import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';
import { ViewEvalModal } from './viewEvalModal';
// import { PdfEvalModal } from './pdfEvalModal';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-coor-eval',
  templateUrl: './coor-eval.component.html',
  styleUrl: './coor-eval.component.css'
})
export class CoorEvalComponent implements OnInit{
  @HostBinding('class') classes = 'container-fluid';
	
	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()

	formattedDate = `${this.day}/${this.month}/${this.year}`
	student_db:database={
		student_id: '', 
    fname: '',
    lname: '',
    slname: '',
    nid: '',
    pwd: '',
    roles: '',
    email:	'',
    date: '',
    sex: '',
    coor: '',
    codigo: 0,
    cr: 0,
    token: '',
    prefix: '',
    periodo: '', 
    escuela: '',
    campus: '', 
    nivel: '', 
    dept: '', 
    permission: false,
    is_online: false
	}


  content: any = { user: {}, JsonData: { asignaturas: [] }, dataE: 0, totalEE: [] };
	modalRef?: BsModalRef;

	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;

	pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 10; 
  autoHide: boolean = false;
  totalItems!: number; 
  totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;

  constructor(private tokenStorageService: TokenStorageService,
    private http: HttpClient,
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);}

    ngOnInit(): void {
      this.getAllStudentService();
          
    }
    
    getAllStudentService():void{
      
      if (!this.authService.isLoggedIn()) {
        // this.openModal();
        this.router.navigate(['/']);
      } else {
        
        this.userService.getCoorStudentBoard().subscribe(
        (res: any[]) => {
        this.content = res;
          this.totalItems = this.content.dataA.length;
          this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.pageChanged(1);
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
        );
      }
     }
     
     previousPage(): void {
      if (this.currentPage > 1) {
        this.pageChanged(this.currentPage - 1);
      }
    }
      
    nextPage(): void {
        if (this.currentPage < this.totalPages) {
          this.pageChanged(this.currentPage + 1);
        }
      
    }
    
    pageChanged(pageNumber: number): void {
        const startIndex = (pageNumber - 1) * this.itemsPerPage;
        let endIndex = startIndex + this.itemsPerPage;
      
        
        endIndex = Math.min(endIndex, this.totalItems);
      
        this.pagedData = this.content.dataA.slice(startIndex, endIndex);
        this.currentPage = pageNumber;
        this.showPreviousButton = this.currentPage > 1;
        this.showNextButton = this.currentPage < this.totalPages;
    }

    
    downloadPDF(filename: string) {
    
        
      //  const [coor, asignatura] = combinedData.split('-');
      
      //  const url = `https://uasd.postgradofces.com/download/${encodeURIComponent(coor)}-${encodeURIComponent(filename)}.pdf`;
      const url = `https://uasd.postgradofces.com/download/${encodeURIComponent(filename)}.pdf`;
      this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          a.download = `${filename}.pdf`;
          a.click();
          URL.revokeObjectURL(objectUrl);
      }, error => {
          console.error('Error ', error);
      });
    }
    

    getpdfDataEval(filename:string){
      if (!this.authService.isLoggedIn()) {
        // this.openModal();
        this.router.navigate(['/']);
      } else {
        
        this.userService.getResultCoorStudentPDF(filename).subscribe(
        (res: any[]) => {
        this.content = res;
          
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
        );
      }
    }

  
    openViewEvalModal(materiax:string, clavex:string){
      const initialState= {
        materia: materiax,
        clave:clavex,
        
      };
      this.modalRef = this.modalService.show(ViewEvalModal,{initialState,  class: 'modal-md overflow-auto'})
    }
    
    // downloadEvalModal(filename:string){
    //   const initialState= {
    //     filename:filename 
        
    //   };
    //   this.modalRef = this.modalService.show(PdfEvalModal,{initialState,  class: 'modal-md overflow-auto'})
    // }
  }
