import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { evaluation_db } from '../models/evaluation_db';
import {admision_db} from '../models/admision_db';
import { database } from '../models/database';
import {registro_db} from '../models/registro_db';

const API_URL = 'https://uasd.postgradofces.com/';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  


  getUserBoard(): Observable<any> {
    // return this.http.get(API_URL + 'user', { responseType: 'text' });
    return this.http.get(`${API_URL}user`);
  }

  getAuditorBoard(): Observable<any> {
    return this.http.get(API_URL + 'auditor', { responseType: 'text' });
  }

  getCoorBoard(): Observable<any> {
    // return this.http.get(API_URL + 'admin', { responseType: 'text' });
    
      return this.http.get(`${API_URL}coor`);
  }

  getCoorStudentBoard(): Observable<any> {
    // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
    return this.http.get(`${API_URL}coorstudent`);
  }

  getResultCoorStudentById(id:string): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.get(`${API_URL}coorstudent/${id}`);
  }
  getResultCoorStudentPDF(filename:string): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.get(`${API_URL}coorstudent/download/${filename}`);
  }
  createCoorRStudent(registro:registro_db): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.post(`${API_URL}coorstudent`, registro);
  }


  getAdminBoard(): Observable<any> {
    // return this.http.get(API_URL + 'admin', { responseType: 'text' });
    
      return this.http.get(`${API_URL}admin`);
  }
  getNotyBoard(): Observable<any> {
    // return this.http.get(API_URL + 'notify', { responseType: 'text' });
    return this.http.get(`${API_URL}resultsnotify`);
  }
  getTeamBoard(): Observable<any> {
    // return this.http.get(API_URL + 'equipo', { responseType: 'text' });
    return this.http.get(`${API_URL}equipo`);
  }
  getEvalBoard(): Observable<any> {
    // return this.http.get(API_URL + 'eval', { responseType: 'text' });
    return this.http.get(`${API_URL}eval`);
  }
  getProgramBoard(): Observable<any> {
    // return this.http.get(API_URL + 'program', { responseType: 'text' });
    return this.http.get(`${API_URL}program`);
  }
  getResultAdmisionBoard(): Observable<any> {
    // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
    return this.http.get(`${API_URL}resultsadmision`);
  }
  getInscricionBoard(): Observable<any> {
    // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
    return this.http.get(`${API_URL}resultinscrip`);
  }
  /*
  getResultAdmisionById(id:number): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.get(`${API_URL}resultsadmision/${id}`);
  }
  */
  putResultAdmisionById(id:string): Observable<any> { 
    return this.http.post(`${API_URL}resultsadmision/${id}`,{id});
  }
  putInscripAdmisionById(id:string): Observable<any> { 
    return this.http.post(`${API_URL}resultsadmision/inscripcion/${id}`,{id});
  }
  /*
  delResultAdmisionById(id:string): Observable<any> { 
    return this.http.delete(`${API_URL}resultsadmision/${id}`);
  }
  */
  getResultStudentBoard(): Observable<any> {
    // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
    return this.http.get(`${API_URL}resultsstudent`);
  }
  getResultStudentById(id:number): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.get(`${API_URL}resultsstudent/${id}`);
  }

  getResultStudentByPeriodo(escuela:string,codigo:string,coor:string,periodo:string): Observable<any> {
    
    return this.http.get(`${API_URL}resultsstudent/${escuela}/${codigo}/${coor}/${periodo}`);
  }



  createStudent(student_db:database): Observable<any> {
    // return this.http.get(`${API_URL}resultsadmision/${id}`);
    return this.http.post(`${API_URL}resultsstudent`, student_db);
  }

  // addRecordStrudent(student_id:string,modulo:string,clave:string,periodo:string,asignatura:string,pp:number,p:number,ifp:number,ap:number): Observable<any> { 
  //   return this.http.post(`${API_URL}resultsstudent/${student_id}/${modulo}/${clave}/${periodo}/${asignatura}/${pp}/${p}/${ifp}/${ap}`);
  // }
  

  getResultServiceBoard(): Observable<any> {
    // return this.http.get(API_URL + 'resultsservice', { responseType: 'text' });
    return this.http.get(`${API_URL}resultsservice`);
  }
  getEvaluationBoard(): Observable<any> {
    // return this.http.get(API_URL + 'user-evaluacion', { responseType: 'text' });
    return this.http.get(`${API_URL}user-evaluacion`);
  }
  getEvaluationBoardP(evaluacion: evaluation_db): Observable<any> {
    // return this.http.post(API_URL + 'user-evaluacion', { });
    return this.http.post(`${API_URL}user-evaluacion`, evaluacion);
  }
 /*
  getEvaluationBoardP(evaluacion: evaluation_db): Observable<any> {
    const queryParams = this.serialize(evaluacion); 
    const url = `${API_URL}user-evaluacion?${queryParams}`; 
    return this.http.post(url);
  }
  
  private serialize(obj: any): string {
    const params = Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
    return params;
  }
*/
  getCalificacionBoard(): Observable<any> {
    // return this.http.get(API_URL + 'user-calificacion', { responseType: 'text' });
    return this.http.get(`${API_URL}user-calificacion`);
  }
  getResultRecordBoard(): Observable<any> {
    // return this.http.get(API_URL + 'user-evaluacion', { responseType: 'text' });
    return this.http.get(`${API_URL}resultsregistro`);
  }
 
/** Coordinador */

getResultCoorBoard(): Observable<any> {
  // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
  return this.http.get(`${API_URL}resultscoor`);
}

// sendEvaluationPDF(): Observable<any> {
//   // return this.http.get(API_URL + 'resultsadmision', { responseType: 'text' });
//   return this.http.post(`${API_URL}cooreval`,null);
// }


}