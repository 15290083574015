import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { database } from  '../../models/database';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {registro_db} from '../../models/registro_db';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-viewCoorStudentModal',
    template: `
    <div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title fs-5"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="card border-0 align-items-center">
            <div class="text-white avatar bg-primary me-2 rounded-circle">{{ prefix }}</div>
            <div class="card-body">
                <h5 class="card-title text-center">{{ nombre }} {{ apellido }}</h5>
                <p class="card-text text-center" style="font-size:0.9rem;">{{ xstudent_id }}</p>
                <p class="card-text text-center" style="font-size:0.6rem;">{{ xperiodo }}</p>
            </div>

            <div class="nav nav-tabs gap-2 w-100" style="flex-wrap: nowrap;align-items: center;justify-content: center;flex-direction: row;" id="nav-tab" role="tablist">
                <button class="btn btn-primary border-0 active" id="calific-tab" data-bs-toggle="tab" data-bs-target="#calific" type="button" role="tab" aria-controls="calific" aria-selected="true"><span class="card-link" style="font-size:0.8rem;">Agregar Calificación</span></button>
                <button class="btn btn-primary border-0 text-white" id="nav-info-tab" data-bs-toggle="tab" data-bs-target="#nav-info" type="button" role="tab" aria-controls="nav-info" aria-selected="false"><span class="card-link" style="font-size:0.8rem;">Información de Calificación</span></button>
               
            </div>
            <div class="tab-content w-100" id="nav-tabContent">
              <div class="tab-pane fade show active" id="calific" role="tabpanel" aria-labelledby="calific-tab" tabindex="0">
                  <div class="d-block mt-3 mb-3">
                      <p style="font-size:0.9rem;">Seleccionar Asignatura:</p>
                      <select name="nPrograma" class="form-control form-select border border-dark form-select border-1 bg-light text-dark mt-3" style="padding: 10px 0px 10px 0px; padding: 10px 0px 10px 50px; font-size: 17px; line-height: 30px; display: block;outline: 0; border-radius: 30px; color: #2a3341;" [(ngModel)]="registro_db.clave">
                          <option value="" disabled selected>Seleccionar Asignatura</option>
                          <option *ngFor="let m of content.JsonData.asignaturas" [value]="m.clave">{{ m.asignatura }}</option>
                      </select>
                  </div>
                  <div class="d-block mb-3">
                      <p style="font-size:0.9rem;">Nombre de Profesor:</p>
                      <input type="text" id="profesor" [(ngModel)]="registro_db.profesor" class="form-control border border-1 border-dark  w-100" style="font-size: 0.9rem;">
                  </div>
                  <div class="d-flex">
                      <div class="d-flex justify-content-center flex-column text-start mb-3">
                          <p style="font-size:0.9rem;">Asistencia y Participación:</p>
                          <input type="text" id="ap" [(ngModel)]="registro_db.ap" class="form-control border border-1 border-dark w-75" style="font-size: 1.9rem;" (input)="calculateTotal()">
                      </div>
                      <div class="d-flex justify-content-center flex-column text-start mb-3">
                          <p style="font-size:0.9rem;">Prácticas y Presentaciones:</p>
                          <input type="text" id="pp" [(ngModel)]="registro_db.pp" class="form-control border border-1 border-dark w-75" style="font-size: 1.9rem;" (input)="calculateTotal()">
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="d-flex justify-content-center flex-column text-start mb-3">
                          <p style="font-size:0.9rem;">Pruebas:</p>
                          <input type="text" id="p" [(ngModel)]="registro_db.p" class="form-control border border-1 border-dark w-75" style="font-size: 1.9rem;" (input)="calculateTotal()">
                      </div>
                      <div class="d-flex justify-content-center flex-column text-start mb-3">
                          <p style="font-size:0.9rem;">Informe Final:</p>
                          <input type="text" id="ifp" [(ngModel)]="registro_db.ifp" class="form-control border border-1 border-dark w-75" style="font-size: 1.9rem;" (input)="calculateTotal()">
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="d-flex justify-content-center flex-column text-start mb-3">
                          <h3 id="resultTotal" class="fw-bold text-dark">Total: {{ total }}</h3>
                      </div>
                  </div>
              </div>
          


                <div class="tab-pane fade" id="nav-info"  role="tabpanel" aria-labelledby="nav-info-tab" tabindex="0">
              
                        
                    <table class="table table-responsive-sm table-bordered table-striped table-hover" style="font-family:sans-serif;">
                        <thead class="bg-light" style="background-color: #f8fefe;border-radius: 10px;">
                            <tr>
                            <th scope="col"><p class="text-start" style="font-size: 0.8rem;">Profesor</p></th>
                             <th scope="col"><p class="text-start" style="font-size: 0.8rem;">Asignatura</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">Clave</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">AP</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">PP</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">P</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">IF</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">Total</p></th>
                                <th scope="col"><p class="text-start" style="font-size: 0.8rem;">Evaluado?</p></th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr class="border border-light" *ngFor="let item of pagedData; let i = index">
                            <th scope="row">
                            <p class="text-start" style="font-size: 0.8rem;">{{ item.profesor }}</p>
                        </th>
                        <th scope="row"><p class="text-start" style="font-size: 0.8rem;">{{ item.asignatura }}</p></th>
                                <th scope="row"><p class="text-start" style="font-size: 0.8rem;">{{ item.clave }}</p></th>
                                <td><p class="text-start" style="font-size: 0.8rem;">{{ item.ap }}</p></td>
                                <td><p class="text-start" style="font-size: 0.8rem;">{{ item.pp }}</p></td>
                                <td><p class="text-start" style="font-size: 0.8rem;">{{ item.p }}</p></td>
                                <td><p class="text-start" style="font-size: 0.8rem;">{{ item.ifp }}</p></td>
                                <td><p class="text-start" style="font-size: 0.8rem;">{{ item.total }}</p></td>
                                <td><p class="text-start font-semibold text-heading text-muted text-xs">
                                  <div *ngIf="item.eval;else falseCase">
                                    <span class="badge rounded-pill bg-success mt-0" style="font-size: 0.6rem;">Si</span>
                                  </div>
                                  <ng-template #falseCase>
                                    <span class="badge rounded-pill bg-danger mt-0" style="font-size: 0.6rem;">No</span>
                                  </ng-template>
                                </td>




                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center gap-2">
                        <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="previousPage()" [hidden]="!showPreviousButton"><i class="bi bi-arrow-left-circle"></i> Anterior</button>
                        <button class="btn btn-sm rounded-3" style="background-color: #f8ece3; color: #f47b20;" (click)="nextPage()" [hidden]="!showNextButton"><i class="bi bi-arrow-right-circle"></i> Siguiente</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer border-1">
      <div class="d-flex justify-content-center gap-2">
        <button class="btn btn-sm mb-3 rounded-3 w-50" (click)="setCoorRStudentService();" style="background-color: #f8ece3;color:#f47b20;">Guardar</button>
        <button class="btn btn-sm mb-3 rounded-3 w-50" style="background-color: #f8ece3;color:#f47b20;" (click)="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill me-1" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
            </svg>Cerrar
        </button>
      </div>
    </div>


</div>


    `,
    styleUrls: ['./coor-students.component.css'],
     
  
  })

  export class ViewCoorStudentModal  implements OnInit {
    @Input() prefix?: string;
    @Input() nombre?: string;
    @Input() apellido?: string;
    @Input() xstudent_id?: string;
    @Input() xperiodo?: string;
    @Input() xcoordinador?: string;
   
  
    isEditable = false;
  
    content:  any = [];

    now = new Date()
      month = String(this.now.getMonth() + 1).padStart(2, '0')
      day = String(this.now.getDate()).padStart(2, '0')
      year = this.now.getFullYear()
      
      formattedDate = `${this.day}/${this.month}/${this.year}`
      student_db:database={
          student_id: '', 
          fname: '',
          lname: '',
          slname: '',
          nid: '',
          pwd: '',
          roles: '',
          email:	'',
          date: '',
          sex: '',
          coor: '',
          codigo: 0,
          cr: 0,
          token: '',
          prefix: '',
          periodo: '', 
          escuela: '',
          campus: '', 
          nivel: '', 
          dept: '', 
          permission: false,
          is_online: false
      }

      registro_db:registro_db={
        student_id: '',
        coordinador: `${this.xcoordinador}`,
        modulo: '',
        clave: '',
        periodo: '',
        profesor:'',
        asignatura:''
      } 
    //   messageSuccess?: any;
    pagedData: any[] = [];

	currentPage = 1;
	itemsPerPage = 4; 
    autoHide: boolean = false;
    totalItems!: number; 
    totalPages!: number; 
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;
  total: number = 0;
  
    constructor( public bsModalRef: BsModalRef,private userService: UserService, private router: Router,private authService: AuthService) {}
        
    ngOnInit(): void {
      this.getInfoRecordStudent();
    }
  
   

      getInfoRecordStudent():void{
       
        if (!this.authService.isLoggedIn()) {
            // this.openModal();
            this.router.navigate(['/']);
          } else {
            
            this.userService.getResultCoorStudentById(`${this.xstudent_id}`).subscribe(
            (res: any[]) => {
            this.content = res;
              this.registro_db.student_id = this.xstudent_id;
              this.registro_db.periodo = this.xperiodo;

              this.totalItems = this.content.dataA.length;
              this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
              this.pageChanged(1);
            },
            err => {
              this.content = JSON.parse(err.error).message;
            }
            );
          }
      }

      setCoorRStudentService():void{
      
        if (!this.authService.isLoggedIn()) {
          // this.openModal();
          this.router.navigate(['/']);
        } else {
          this.userService.createCoorRStudent(this.registro_db).subscribe(
          (res: any[]) => {
            this.content = res;
            this.bsModalRef.hide();
            
          },
          err => {
            this.content = JSON.parse(err.error).message;
          }
          );
        }
        
       }
       
       calculateTotal() {
        const ap = parseFloat(String(this.registro_db.ap ?? '')) || 0;
        const pp = parseFloat(String(this.registro_db.pp ?? '')) || 0;
        const p = parseFloat(String(this.registro_db.p ?? '')) || 0;
        const ifp = parseFloat(String(this.registro_db.ifp ?? '')) || 0;
        this.total = ap + pp + p + ifp;
      }

      previousPage(): void {
        if (this.currentPage > 1) {
          this.pageChanged(this.currentPage - 1);
        }
      }
        
      nextPage(): void {
          if (this.currentPage < this.totalPages) {
            this.pageChanged(this.currentPage + 1);
          }
        
      }
      
      pageChanged(pageNumber: number): void {
          const startIndex = (pageNumber - 1) * this.itemsPerPage;
          let endIndex = startIndex + this.itemsPerPage;
        
          
          endIndex = Math.min(endIndex, this.totalItems);
        
          this.pagedData = this.content.dataA.slice(startIndex, endIndex);
          this.currentPage = pageNumber;
          this.showPreviousButton = this.currentPage > 1;
          this.showNextButton = this.currentPage < this.totalPages;
      }
    
      closeModal() {
          this.bsModalRef.hide();
      }
   
    }

    
