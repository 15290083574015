import { Component } from '@angular/core';

@Component({
  selector: 'app-estrategia-cibermarketing',
  templateUrl: './estrategia-cibermarketing.component.html',
  styleUrls: ['./estrategia-cibermarketing.component.css']
})
export class EstrategiaCibermarketingComponent {

}
