<header class="bg-transparent" role="banner">
  <div class="row mx-auto">
    <div class="col">
      <nav class="navbar navbar-expand-lg mt-3" style="height: 7rem;">
          <div class="d-flex">
            <a href="https://postgradofces.com" class="align-items-center pb-3 mb-3 text-decoration-none  mx-0" style="display: flex;">
              <div style="float: left;">
                <img  src="/assets/img/logo.png" class="bi pe-none me-1" width="65" alt="Facultad de Ciencias & Sociales (FCES)" height="75">
              </div>
              <div class="d-flex" style="flex-direction: column;">
                <p class="fw-semibold text-start"  style="text-decoration: none; color: #022e57; width:9rem;font-size: 0.7rem;">Facultad de Ciencias Económicas y Sociales</p>
              </div>
            </a>
          </div>  
          <button class="dropdown navbar-toggler border-0" style="margin-top: -30px;" type="button" data-bs-toggle="offcanvas" data-bs-target="#modalMenubar" aria-controls="modalMenubar" aria-expanded="false" aria-label="Toggle navigation">
            <i class="bi bi-grid-3x3-gap-fill"></i>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="menu list-unstyled ps-0 d-flex navbar-nav border-0 dropdown-menu cntrlColor" style="margin-left: 5rem;">
              <li class="nav-item mb-1 dropdown-item">
                    <a mat-button routerLink="/admisiones" class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" data-bs-toggle="collapse" data-bs-target="#notification-collapse" aria-expanded="false">
                      Admisiones
                    </a>
              </li>            
              <li class="nav-item dropdown">
                <!-- <button class="nav-item mb-1 dropdown-item"> -->
                    <a mat-button class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2">
                      Ofertas
                    </a>
                <!-- </button> -->
                <ul class="dropdown-menu">
                  <li>
                    <a mat-button routerLink="/ofertas-academica" class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" style="width: 11rem;"  aria-expanded="false">
                      Oferta académica
                    </a>
                  </li>
                  <li>
                    <a mat-button routerLink="/plan-estudios" class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" data-bs-toggle="collapse" data-bs-target="#profile-collapse" aria-expanded="false">
                    Oferta grado
                    </a>
                  </li>
                </ul>
              </li>          
              <li class="nav-item mb-1 dropdown-item">
                    <a mat-button routerLink="/estudiantes"  class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
                      Estudiantes
                    </a>               
              </li>
              <li class="nav-item mb-1 dropdown-item">
                    <a mat-button routerLink="/servicios"  class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
                      Servicios
                    </a>               
              </li>
              <li class="nav-item mb-1 dropdown-item">
                  <a mat-button routerLink="/investigaciones"  class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
                    Investigaciones
                  </a>               
              </li>
              <li class="nav-item mb-1 dropdown-item">
                <a mat-button routerLink="/educacion-continuada"  class="nav-link text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" aria-expanded="true">
                  Educación continuada
                </a>               
              </li>            
              <li class="nav-item mb-1 dropdown-item"  *ngIf="!isLoggedIn">
                  
                <a mat-button class="text-dark  btn-lg btn-block btn-outline-light border-0 text-start collapsed p-2" data-bs-toggle="offcanvas" data-bs-target="#modalLogin" aria-controls="modalLogin">
                  <span class="d-flex align-items-end justify-content-center fs-6" style=" width: 9rem;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle mx-2 my-1" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                    </svg>
                    Iniciar sesión
                  </span>
                </a>
                
              </li>
              <li class="nav-item mb-1 dropdown-item">
                  <div class="dropdown-center dropdown-item">
                    <!-- <li class="nav-item mb-1 dropdown-item"  *ngIf="isLoggedIn"> -->
                        <a class="btn btn-primary border-0 text-white" *ngIf="isLoggedIn"  data-bs-toggle="dropdown" aria-labelledby="login" ><span class="d-flex align-items-end justify-content-center fs-6 text-sm text-wrap" style=" width: 9rem; margin-top: 0px;"><i class="fs-5 bi bi-person-check-circle me-1"></i> {{username}}</span></a>
                      <ul class="dropdown-menu">
                          <li class="d-block"><a class="dropdown-item" style="cursor: pointer;" href="{{redirectURL}}">Dashboard</a></li>
                          <!-- <li class="d-block"><a class="dropdown-item" href="/logout" (click)="logout()">Cerrar sesión</a></li> -->
                          <li class="d-block"><a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Cerrar sesión</a></li>
                      </ul>
                    <!-- </li> -->
                  </div>
              </li>
            </ul>
          </div>
      </nav>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
<section class="content-fluid p-0 m-0">
  <img src="/assets/img/img-rectangle.png" class="img-fluid" alt="imagen rectangle">
</section >
<section class="content-fluid px-0 mx-0" style="background-color: #eaeaea;">
  <footer class="d-flex" style="font-size: 0.7rem;"> 
    <div class="row row-cols-1 row-cols-md-5 mb-3">
      <div class="col d-flex justify-content-start">
        <div class="d-flex">
          <a href="https://postgradofces.com" class="align-items-center pb-3 mb-3 text-decoration-none  mx-0" style="display: flex; justify-content: end;">
            <div style="float: left;">
              <img  src="/assets/img/logo.png" class="bi pe-none me-1" width="65" alt="Facultad de Ciencias & Sociales (FCES)" height="75">
            </div>
          </a>
          <div class="d-flex mt-4" style="flex-direction: column;">
            <p class="fw-semibold text-start"  style="text-decoration: none; color: #022e57; width:9rem;font-size: 0.7rem;">Facultad de Ciencias Económicas y Sociales</p>
          </div>
        </div>
      </div>
      <div class="col">
        <p class="fw-semibold mb-4" style="width: max-content;">Dirección</p>
        <ul class="list-unstyled d-flex gap-2" style="flex-direction: column;">
          <li style="font-size: 0.7rem;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79427 10.1948C10.1618 11.1008 11.0293 11.6918 11.9917 11.6918C12.6222 11.6964 13.2283 11.4438 13.6749 10.9905C14.1215 10.5371 14.3716 9.92063 14.3694 9.27838C14.3727 8.29803 13.7956 7.41231 12.9074 7.03477C12.0192 6.65723 10.9952 6.86234 10.3134 7.55434C9.6317 8.24634 9.42675 9.28872 9.79427 10.1948Z" fill="#656565"/>
              <ellipse opacity="0.4" cx="12" cy="21" rx="5" ry="1" fill="#656565"/>
              </svg>  6to piso, Torre Administrativa, UASD Sede.
          </li>
          <li style="font-size: 0.7rem;">
            <div class="d-flex">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.03174 9.97239C13.0208 13.9604 13.9258 9.34672 16.4656 11.8848C18.9143 14.3328 20.3216 14.8232 17.2192 17.9247C16.8306 18.237 14.3616 21.9943 5.6846 13.3197C-2.99348 4.644 0.761585 2.17244 1.07397 1.78395C4.18387 -1.32615 4.66586 0.0893829 7.11449 2.53733C9.65436 5.0765 5.04266 5.98441 9.03174 9.97239Z" fill="#656565"/>
                </svg>(809)-754-6985
            </div>
          </li>
          <li style="font-size: 0.7rem;">
              <div class="d-flex fw-semibold">
                <p>Horario de respuesta:</p>
              </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z" fill="#656565"/>
                <path d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z" fill="#656565"/>
                </svg> de 8 a.m. a 6 p.m.
          </li>
          <li style="font-size: 0.7rem;">
            <div class="d-flex fw-semibold">
              <p>Email:</p>
            </div>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M20 12.94C20 15.73 17.76 17.99 14.97 18H14.96H5.05C2.27 18 0 15.75 0 12.96V12.95C0 12.95 0.006 8.524 0.014 6.298C0.015 5.88 0.495 5.646 0.822 5.906C3.198 7.791 7.447 11.228 7.5 11.273C8.21 11.842 9.11 12.163 10.03 12.163C10.95 12.163 11.85 11.842 12.56 11.262C12.613 11.227 16.767 7.893 19.179 5.977C19.507 5.716 19.989 5.95 19.99 6.367C20 8.576 20 12.94 20 12.94Z" fill="#656565"/>
                <path d="M19.4759 2.67351C18.6099 1.04151 16.9059 -0.000488281 15.0299 -0.000488281H5.04988C3.17388 -0.000488281 1.46988 1.04151 0.603884 2.67351C0.409884 3.03851 0.501884 3.49351 0.824884 3.75151L8.24988 9.69051C8.76988 10.1105 9.39988 10.3195 10.0299 10.3195C10.0339 10.3195 10.0369 10.3195 10.0399 10.3195C10.0429 10.3195 10.0469 10.3195 10.0499 10.3195C10.6799 10.3195 11.3099 10.1105 11.8299 9.69051L19.2549 3.75151C19.5779 3.49351 19.6699 3.03851 19.4759 2.67351Z" fill="#656565"/>
                </svg>postgradofces&#64;uasd.edu.do
          </li>
        </ul>
      </div>
      <div class="col">
        <p class="fw-semibold mb-4" style="width: max-content;">Enlaces utiles</p>
        <ul class="list-unstyled d-flex gap-2" style="flex-direction: column;">
          <li>
            <a class="text-dark" href="#">UCOTESIS</a>
          </li>
          <li>
            <a class="text-dark" href="#">Aplicación de pago</a>
          </li>
          <li>
            <a class="text-dark" href="https://forms.gle/s2DXW7dH4vF9xNuX7">Solicitud de factura</a>
          </li>
          <li>
            <a class="text-dark" href="/admisiones">Formulario de admisiones</a>
          </li>
        </ul>
      </div>
      <div class="col">
        <p class="fw-semibold mb-4" style="width: max-content;">Más opciones</p>
        <ul class="list-unstyled gap-2 d-flex" style="flex-direction: column;">
          <li><a class="text-dark" href="/estudiantes"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle mx-2 my-1" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>Iniciar Sesión</a>
          </li>
          <li><a class="text-dark" href="/servicios"><i class="bi bi-headset fs-6 mx-2"></i>Solicitud Servicios</a></li>
        </ul>
      </div>
      <div class="col d-flex" style="align-items: end;">
        <ul class="list-unstyled d-flex gap-2">
          <li>
            <a href="https://www.facebook.com/Economiauasd?mibextid=LQQJ4d" class="btn btn-primary p-3 rounded-4 text-sm fw-bold" aria-label="Facebook FCES">
              <div class="bg-transparent border-0 rounded-3 d-flex">
                  
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                </svg>
                  
              </div>
              </a>
          </li>
          <li>
            <a href="https://wa.link/4g5evg" class="btn btn-primary p-3 rounded-4 text-sm fw-bold" aria-label="Whatsapp FCES">
              <div class="bg-transparent border-0 rounded-3 d-flex">
                  
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                </svg>
                  
              </div>
              </a>
          </li>
          <li>
            <a href="https://www.instagram.com/economiauasd?igsh=MXd1a2U4dTR4enppdg%3D%3D&utm_source=qr" class="btn btn-primary p-3 rounded-4 text-sm fw-bold" aria-label="Instagram FCES">
              <div class="bg-transparent border-0 rounded-3 d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                </svg>
              </div>
              </a>
          </li>
          <li>
            <a href="https://m.youtube.com/@FCESUASD" class="btn btn-primary p-3 rounded-4 text-sm fw-bold" aria-label="Youtube FCES">
                <div class="bg-transparent border-0 rounded-3 d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                </div>
            </a>
        </li>
        </ul>
      </div>
    </div>
  </footer>
</section>
<app-login></app-login>
<app-menubar></app-menubar>
