import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {service_db} from '../models/service_db';
import {Observable} from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({'Content-Type' : 'application/json'})
}

@Injectable({
  providedIn: 'root'
})

export class ServicioService {

  API_URI = 'https://uasd.postgradofces.com'

  constructor(private http:HttpClient) { }

  service(service:service_db): Observable<any>{
  	return this.http.post(`${this.API_URI}/services`,{service}, httpOptions);
  }
}
