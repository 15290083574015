import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { admision_db } from '../../models/admision_db';
import { DialogAdmision} from './dialogAdmision';


@Component({
  selector: 'app-result-admision',
  templateUrl: './result-admision.component.html',
  styleUrls: ['./result-admision.component.css']
})
export class ResultAdmisionComponent implements OnInit {
	@HostBinding('class') classes = 'container-fluid';
	
	now = new Date()
	month = String(this.now.getMonth() + 1).padStart(2, '0')
	day = String(this.now.getDate()).padStart(2, '0')
	year = this.now.getFullYear()

	fuser: string = '';
	luser: string = '';

	formattedDate = `${this.day}/${this.month}/${this.year}`
	admision:admision_db={
		fname: '',
		lname: '',
		cedula:'',
		estado_civil:'',
		sexo:'',
		fecha_nac:'',
		lugar_nac: '',
		dependientes:'',
		cel: '',
		email: '',
		programa: '',
		profesion: '',
		tanda: '',
		grado_academico: '',
		uni_procedencia: '',
		idiomas: '',
		medios: '',
		lugar_trabajo: '',
		direccion: '',
		ingresos: '',
		otros_ingresos: '',
		ocupacion: '',
		fecha: `${this.formattedDate}`,
		recibido: 0,
		inscripto:0
	  }


	content:  any = [];
	modalRef?: BsModalRef;
  
	today: Date = new Date();
	options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	dateTime: string;
	
	
	selectedValue: string = '';
	selectedTab: string = 'total';
  
	pagedData: any[] = [];
	pagedDataNuevos: any[] = [];
	pagedDataRecibidos: any[] = [];
  
	currentPageNuevos = 1;
	currentPageRecibidos = 1;
  
	totalPagesRecibidos!: number;
	totalPagesNuevos!: number;
  
	currentPage = 1;
	itemsPerPage = 10;
	itemsPerPageN = 10;
	itemsPerPageR = 10;
	
  
	totalItems!: number;
	totalPages!: number;
	totalItemsN!: number;
	totalPagesN!: number;
	totalItemsR!: number;
	totalPagesR!: number;
	showPreviousButton: boolean = false;
	showNextButton: boolean = true;
	 
    autoHide: boolean = false;

	

	constructor(
		private userService: UserService,
		private authService: AuthService,
		private router: Router,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private activatedRoute: ActivatedRoute
	  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	  this.dateTime = this.today.toLocaleString('es-ES', this.options);  }
	
	  openModal() {
		this.modalRef = this.modalService.show('modal-content'); 
	  }

	  
	  openViewAdmisionModal(programa:string,fecha:string, tanda:string, fname:string, lname:string, cedula:string, email:string, cel:string, estado_civil:string, dependientes:string, sexo:string, fecha_nac:string, lugar_nac:string, lugar_trabajo:string, ingresos:string, otros_ingresos:string,  direccion:string, ocupacion:string,  profesion:string, uni_procedencia:string,  grado_academico:string, idiomas:string, ref_nom_1:string, ref_addr_1:string, ref_tel_1:string, ref_nom_2:string, ref_addr_2:string, ref_tel_2:string,  ref_nom_3:string,  ref_addr_3:string, ref_tel_3:string){
		const initialState= {
			programa: programa,
			fecha:fecha,
			tanda:tanda,
			fname:fname, 
			lname:lname, 
			cedula:cedula, 
			email:email,
			cel:cel,
			estado_civil:estado_civil,
			dependientes:dependientes,
			sexo:sexo,
			fecha_nac:fecha_nac,
			lugar_nac:lugar_nac,
			lugar_trabajo:lugar_trabajo,
			ingresos:ingresos,
			otros_ingresos:otros_ingresos,
			direccion:direccion,
			ocupacion:ocupacion,
			profesion:profesion,
			uni_procedencia:uni_procedencia,
			grado_academico:grado_academico,
			idiomas:idiomas,
			ref_nom_1:ref_nom_1,
			ref_addr_1:ref_addr_1, 
			ref_tel_1:ref_tel_1, 
			ref_nom_2:ref_nom_2, 
			ref_addr_2:ref_addr_2, 
			ref_tel_2:ref_tel_2,  
			ref_nom_3:ref_nom_3,  
			ref_addr_3:ref_addr_3,   
			ref_tel_3:ref_tel_3
		};
		this.modalRef = this.modalService.show(DialogAdmision,{initialState,  class: 'modal-fullscreen overflow-auto'})
	  }
	  closeModal() {
		if (this.modalRef) {
		  this.modalRef.hide();
		}
	  }
	
	ngOnInit(): void {
		this.getAllAdmisionService();
	}
	
	getAllAdmisionService(): void {
		if (!this.authService.isLoggedIn()) {
		  this.openModal();
		  this.router.navigate(['/']);
		} else {
		  this.userService.getResultAdmisionBoard().subscribe(
			(res: any[]) => {
			  this.content = res;
			  this.totalItems = this.content.dataA.length;
			  this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
/*
			  this.totalItemsN = this.content.dataA.length;
			  this.totalPagesN = Math.ceil(this.totalItemsR / this.itemsPerPageN);
			  
			  this.totalItemsR = this.content.dataA.length;
			  this.totalPagesR = Math.ceil(this.totalItemsR / this.itemsPerPageR); */
			  
			  this.filterData();
			  this.pageChanged(1);
			  this.fuser = this.content.user.fname;
			  this.luser = this.content.user.lname;
			},
			err => {
			  this.content = JSON.parse(err.error).message;
			}
		  );
		}
	  }
	
	  filterData(): void {
		this.pagedDataNuevos = this.content.dataA.filter((item: any) => item.recibido === 0);
		this.pagedDataRecibidos = this.content.dataA.filter((item: any) => item.recibido === 1);
		this.totalPagesNuevos = Math.ceil(this.pagedDataNuevos.length / this.itemsPerPageN);
		this.totalPagesRecibidos = Math.ceil(this.pagedDataRecibidos.length / this.itemsPerPageR);
		this.updatePagedData();
	  }
	
	  pageChanged(pageNumber: number): void {
		this.currentPage = pageNumber;
		this.showPreviousButton = this.currentPage > 1;
		this.showNextButton = this.currentPage < this.totalPages;
		this.updatePagedData();
	  }
	
	  updatePagedData(): void {
		let startIndex: number, endIndex: number;
	  
		if (this.selectedTab === 'total') {
		  startIndex = (this.currentPage - 1) * this.itemsPerPage;
		  endIndex = startIndex + this.itemsPerPage;
		  this.pagedData = this.content.dataA.slice(startIndex, endIndex);
		} else if (this.selectedTab === 'nuevos') {
		  startIndex = (this.currentPageNuevos - 1) * this.itemsPerPageN;
		  endIndex = startIndex + this.itemsPerPageN;
		  this.pagedDataNuevos = this.content.dataA.slice(startIndex, endIndex);
		} else if (this.selectedTab === 'recibidos') {
		  startIndex = (this.currentPageRecibidos - 1) * this.itemsPerPageR;
		  endIndex = startIndex + this.itemsPerPageR;
		  this.pagedDataRecibidos = this.content.dataA.slice(startIndex, endIndex);
		}
	  }
	
	  changeTab(tab: string): void {
		this.selectedTab = tab;
		this.currentPage = 1;
		this.currentPageNuevos = 1;
		this.currentPageRecibidos = 1;
		this.totalPages = this.calculateTotalPages();
		this.updatePagedData();
	  }
	
	  calculateTotalPages(): number {
		if (this.selectedTab === 'total') {
		  return Math.ceil(this.content.dataA.length / this.itemsPerPage);
		} else if (this.selectedTab === 'nuevos') {
		  return Math.ceil(this.pagedDataNuevos.length / this.itemsPerPageN);
		} else if (this.selectedTab === 'recibidos') {
		  return Math.ceil(this.pagedDataRecibidos.length / this.itemsPerPageR);
		}
		return 0;
	  }
	
	  previousPage(): void {
		if (this.currentPage > 1) {
		  this.pageChanged(this.currentPage - 1);
		}
	  }
	
	  nextPage(): void {
		if (this.currentPage < this.totalPages) {
		  this.pageChanged(this.currentPage + 1);
		}
	  }
	
	  pageChangedNuevos(pageNumber: number): void {
		this.currentPageNuevos = pageNumber;
		if(pageNumber < this.totalPagesN){
			this.pageChangedNuevos(this.currentPageNuevos)
		}
		this.updatePagedData();
	  }
	
	  pageChangedRecibidos(pageNumber: number): void {
		this.currentPageRecibidos = pageNumber;
		this.updatePagedData();
	  }

	 putResultAdmisionById(strCedula:string): void {
		
		
		this.userService.putResultAdmisionById(`${strCedula}`).subscribe(
		  res => {			
		 
		   this.content = res;
		 window.location.href = '/resultsadmision';
		},
		err => {
		  this.content = JSON.parse(err.error).message;
		}
		);
	  
	 }

	 putInscripAdmisionById(id_admision:string){
		
		this.userService.putInscripAdmisionById(`${id_admision}`).subscribe(
		  res => {			
		 
		   this.content = res;
		   window.location.reload()
		   window.location.href = '/resultsadmision';
		},
		err => {
		  this.content = JSON.parse(err.error).message;
		}
		);
	  
	 }
	
	  onFormSubmit() {
		console.log("Formulario enviado");
		
	  }


}