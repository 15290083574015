import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { ModalContentMesyt } from './ModalContentMesyt.component';

@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.css']
})
export class UserBoardComponent implements OnInit {
  @HostBinding('class') classes = 'container-fluid';
  content:  any = [];
  modalRef?: BsModalRef;

  today: Date = new Date();
  options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  dateTime: string;

  constructor( private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private tokenStorage: TokenStorageService) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    this.dateTime = this.today.toLocaleString('es-ES', this.options);}

    
  // openModal():void {
  //   this.modalRef = this.modalService.show('modal-content'); 
  // }


  
  


  openModal(nombre: string, nid:string, campus: string, escuela: string, periodo: string, codigo: string, maestria: string, sexo: string) {
		const initialState = {
		  nombre: nombre,
      nid: nid,
      campus: campus,
      escuela: escuela,
      periodo: periodo,
      codigo: codigo,
      maestria: maestria,
      sexo:sexo
		  
		};
		this.modalRef = this.modalService.show(ModalContentMesyt, { initialState });
	  }

  closeModal():void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  ngOnInit(): void {
    this.getDataUserService();
  }


  getDataUserService():void{
    
    if (!this.authService.isLoggedIn()) {
      // this.openModal();
      this.router.navigate(['/']);
    } else {
      
      this.userService.getUserBoard().subscribe(
        res => {
          
         
          this.content = res;
         
      
      },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }
  }


}
