<div class="container-fluid">
    <div class="row" style="display: flex; justify-content: center;">
        <div class="col-md-4 inicio text-dark">
            <div class="card border-0">
                <div class="card-header bg-white border-0" style="display: flex;">
                    <h1><span style="color: yellow;">&#9888;</span></h1>
                    <!-- <h1><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16" style="background-color: yellow;">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                      </svg>
                    </h1> -->
                    <h2 class="pt-3" style="text-align: center;">Pagina no encontrada!</h2>
                </div>
                <div class="card-body d-flex justify-content-center">
                    <a routerLink="/" class="btn btn-danger d-block w-50 text-white text-sm">Volver a el Inicio</a>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-8 bg-hide-col px-0">  
            <div class="bg-image"  style="background-image: url('https://postgradofces.com/assets/img/page-not-found.png');height: 100vh;background-size: contain; background-repeat:no-repeat; background-position: right;"></div>
        </div> -->
    </div>
</div>