import { Component,OnInit,HostBinding } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '../../services/token-storage.service';
import { database } from '../../models/database';

@Component({
  selector: 'app-coor-board',
  standalone: true,
  imports: [],
  templateUrl: './coor-board.component.html',
  styleUrl: './coor-board.component.css'
})
export class CoorBoardComponent {
  @HostBinding('class') classes = 'container-fluid';
  content: any = { user: { fname: '', lname: '' }, JsonData: { asignaturas: [] }, dataE: 0, totalEE: [] };
 
  // modalRef?: BsModalRef;

  today: Date = new Date();
  options: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  dateTime: string;




  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private tokenStorage: TokenStorageService
  ) { this.options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  this.dateTime = this.today.toLocaleString('es-ES', this.options);  }



 

  ngOnInit(): void {
    this.getDataCoorService();
  }

  getDataCoorService():void{
    
    if (!this.authService.isLoggedIn()) {
      
      this.router.navigate(['/']);
    } else {
      
      this.userService.getCoorBoard().subscribe(
        res => {
         this.content = res;
          
        
      },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }
  }
}
