import { Component, Input, OnInit,ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';


import { mesyt_db} from '../../models/mesyt_db';
import { UserService } from '../../services/user.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-proestadistico',
  templateUrl: './proestadistico.component.html',
  styleUrl: './proestadistico.component.css'
})
export class ProestadisticoComponent implements OnInit{


  content: any = [];
  currentStep: number = 1; 
  mesyct: mesyt_db;

  @ViewChild('stepper')
  stepperIndex = 0
  stepsTotal = 5
  stepsCompleted = 0
  isStepperCompleted():boolean{
    return this.stepsCompleted === this.stepsTotal
  }

  now = new Date()
  month = String(this.now.getMonth() + 1).padStart(2, '0')
  day = String(this.now.getDate()).padStart(2, '0')
  year = this.now.getFullYear()

  formattedDate = `${this.day}/${this.month}/${this.year}`


  // formMesyctGroup!:FormGroup
  errorMessage = ''
  successMessage = ''
  isFormSuccess = false
  isFormFailed = false
  isEditable = false
  
  


  constructor(
    private userService: UserService,
    private router: Router,
      
  ) {
    this.mesyct = {
      CAMPUS: '',
      TIPO_DOCUMENTO: '',
      NID: '',
      NO_MATRICULA: 'N/A',
      NOMBRES: '',
      PRIMER_APELLIDO: '',
      SEGUNDO_APELLIDO: '',
      SEXO: '',
      FECHA_NAC: '',
      EDAD: '',
      FECHA_BACHILLERATO: '', 
      MUNICIPIO_NACIMIENTO: '',
      MUNICIPIO_RESIDENCIA: '', 
      PAIS_ORIGEN: '', 
      PAIS_PREVIO: '', 
      FACULTAD: 'FACULTAD DE CIENCIAS ECONÓMICAS Y SOCIALES', 
      ESCUELA: '', 
      CARRERA: '', 
      CODIGO: '',
      MODALIDAD: '',
      PERIODO_ACADEMICO: '',
      NIVEL_ACADEMICO: '',
      NUEVO_INGRESO: '',
      DIFICULTAD_VER: '',
      DIFICULTAD_OIR: '',
      DIFICULTAD_CAMINAR: '',
      DIFICULTAD_RECORDAR: '',
      DIFICULTAD_REALIZAR: '',
      DIFICULTAD_COMUNICARSE: '',
      FECHA: `${this.formattedDate}`,      
    };
    
    // this.initForms()
    
  }

  ngOnInit(): void { }

  reloadPage(): void {
    window.location.reload()
  }
  


  
  initForms(){
		
	}

 
	


  

  nextStep(): void {
    if (this.currentStep < 19) { 
      this.currentStep++;
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  // onSubmit():void{
		
      


  //     this.userService.getMesyctBoard(this.mesyct).subscribe(
  //       res => {
  //         this.router.navigate(['/']);
        
  //       },
  //       err => {
  //         this.content = JSON.parse(err.error).message;
  //       }
  //     );
   
	// }

  onSubmit():void{
		this.userService.getMesyctBoard(this.mesyct).subscribe(
		res => {
			this.mesyct = res
        	this.isFormFailed = false
        	this.successMessage = '*Formulario Proceso Estadísticos Completado. Gracias!'		
        	this.isFormSuccess = true
        
        	setTimeout(()=>{
				this.isFormFailed = false
				this.isFormSuccess = false
				this.reloadPage()
          	},5000)
			
		},
		err => {
			this.content = JSON.parse(err.error).message;
		   
			
			
		})
	}

}

