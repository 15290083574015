import { Component } from '@angular/core';

@Component({
  selector: 'app-master-globalizacion',
  templateUrl: './master-globalizacion.component.html',
  styleUrls: ['./master-globalizacion.component.css']
})
export class MasterGlobalizacionComponent {

}
