<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 inicio text-dark">
            <div class="card border-0 px-2 rounded-3 shadow">
                <div class="card-header bg-white border-0 d-flex justify-content-center">
                    <h3 class="pt-3" style="text-align: center;">Solicitud de Servicios</h3>
                </div>
                <div class="card-body p-0">
                    <form class="mx-auto" [formGroup]="formServiceGroup" *ngIf="!isFormSuccess"   name="Serviceform" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
                        <div class="itemService">
                           <mat-horizontal-stepper linear="true" #stepper [selectedIndex]="stepperIndex" [class.d-flex.flex-column.rounded-3]>
                                <mat-step [stepControl]="personInfo" editable="false" (completedChange)="stepsCompleted = stepsCompleted + 1">
                                        <div formGroupName="info_service">
                                            <div class="d-flex mt-2 mb-4">
                                                <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información Personal</span>
                                            </div>
                                        <div class="mb-3">
                                            <input matInput mat-input type="text" name="ctrlServiceNombre" formControlName="ctrlServiceNombre" [(ngModel)]="service.fname" class="form-control rounded-4 border border-dark border-1" placeholder="Nombres" required>
                                        </div>
                                        <div class="mb-3">
                                            <input matInput type="text" name="ctrlServiceApellido" formControlName="ctrlServiceApellido" [(ngModel)]="service.lname" class="form-control rounded-4 border border-dark border-1" placeholder="Apellidos" required>
                                        </div>
                                        <div class="mb-3">
                                            <input matInput type="tel" name="ctrlServiceNid" formControlName="ctrlServiceNid" [(ngModel)]="service.cedula" class="form-control rounded-4 border border-dark border-1" pattern="[\.\+\-\(\)0-9 ]+" placeholder="Cedula" required>
                                        </div>
                                        <div class="mb-3">
                                            <input matInput type="tel" name="ctrlServiceCelular" formControlName="ctrlServiceCelular" [(ngModel)]="service.phone" class="form-control rounded-4 border border-dark border-1" pattern="[\.\+\-\(\)0-9 ]+" placeholder="Telefono" required>
                                        </div>
                                        <div class="mb-3">
                                            <input matInput type="email" class="form-control rounded-4 border border-dark border-1" name="ctrlServiceEmail" formControlName="ctrlServiceEmail" [(ngModel)]="service.email" placeholder="Email" placeholder="Correo Electrónico" required>
                                        </div>
                                        
                                        <div class="col-md-6 mx-auto">
                                           
                                            <button type="button" (click)="stepper.next()" class="btn btn-primary">Siguente</button>
                                        </div>
                                        </div>
                                </mat-step>
                                <mat-step [stepControl]="serviceInfo" (completedChange)="stepsCompleted = stepsCompleted + 1">
                                        <div formGroupName="info2_service">
                                            <div class="d-flex mt-2 mb-4">
                                                <span class="badge rounded-pill w-100 text-bg-primary p-3 rounded-4 justify-content-center">Información de Servicios</span>
                                            </div>
                                            <div class="mb-2">
                                                <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlServiceTipo" formControlName="ctrlServiceTipo" [(ngModel)]="service.tipo"   required>
                                                    <option class="ms-2" value="" disabled selected>Tipo de Servicio</option>
                                                    <option value="constancia de estudio">Constancia de Estudio</option>
                                                    <option value="Cotización">Cotización</option>
                                                    <option value="estado de cuenta">Estado de Cuenta</option>
                                                    <option value="record de notas">Record de Notas</option>
                                                    <option value="Otro">Otro</option>
                                                </select>
                                            </div>
                                            <div class="mb-2">
                                                <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlServiceProgram" formControlName="ctrlServiceProgram" [(ngModel)]="service.programa"   required>
                                                    <option class="ms-2" value="" disabled selected>Seleccionar programa</option>
                                                    <optgroup label="Escuela de Administración">
                                                        <option value="Administración de Empresas Cooperativas">Maestría en Administración de Empresas Cooperativas</option>
                                                        <option value="Alta Gerencia">Maestría en Alta Gerencia</option>
                                                        <option value="Dirección Estratégica y Gestión de la Innovación">Maestría en Dirección Estratégica y Gestión de la Innovación</option>
                                                        <option value="Gerencia Financiera">Maestría en Gerencia Financiera</option>
                                                        <option value="Gestion de Recursos Humanos">Maestría en Gestión de Recursos Humanos</option>
                                                        <option value="Gestión Pública">Maestría en Gestión Pública</option>
                                                        <option value="Seguros y Finanzas">Maestría en Seguros y Finanzas</option>
                                                        <option value="Gestión Logística de las Aduanas y Puertos">Maestría en Gestión Logística de las Aduanas y Puertos</option>
                                                        <option value="Gestión en Finanzas Coorporativas">Maestría Gestión en Finanzas Coorporativas</option>
                                                        <option value="Gestión Pública y Gobernanza Mención Gestión de Recursos Humanos">Maestría Gestión Pública y Gobernanza Mención Gestión de Recursos Humanos</option>
                                                        <option value="Gestión Pública y Gobernanza Mención Innovación y Transformación Digital">Maestría Gestión Pública y Gobernanza Mención Innovación y Transformación Digital</option>
                                                        <option value="Gestión Pública y Gobernanza Mención Gestión y Evaluación Proyectos Públicos">Maestría Gestión Pública y Gobernanza Mención Gestión y Evaluación Proyectos Públicos</option>
                                                        <option value="Gestión Pública y Gobernanza Mención Finanzas Públicas">Maestría Gestión Pública y Gobernanza Mención Finanzas Públicas</option>

                                                    </optgroup>
                                            
                                                    <optgroup label="Escuela de Economía">
                                                        <option value="Finanzas y Mercado de Capitales">Maestría en Finanzas y Mercado de Capitales</option>
                                                        <option value="Negocios y Relaciones Económicas Internacionales">Maestría en Negocios y Relaciones Económicas Internacionales</option>
                                                        <option value="Master Globalización">Maestría en Master Globalización</option>
                                                    </optgroup>
                                            
                                                    <optgroup label="Escuela de Contabilidad">
                                                        <option value="Auditoria Interna">Maestría en Auditoria Interna</option>
                                                        <option value="Contabilidad Gubernamental">Maestría en Contabilidad Gubernamental</option>
                                                        <option value="Contabilidad Tributaria">Maestría en Contabilidad Tributaria</option>
                                                    </optgroup>
                                            
                                                    <optgroup label="Escuela de Mercadotecnia">
                                                        <option value="Marketing Estratégico">Maestría en Marketing Estratégico</option>
                                                        <option value="Estrategias de CirbeMarketing">Maestría en Estrategias de CiberMarketing</option>
                                                    </optgroup>
                                            
                                                    <optgroup label="Escuela de Sociología y Trabajo Social">
                                                        <option value="Sociología de la Educación">Maestría en Sociología de la Educación</option>
                                                        <option value="Trabajo Social">Maestría en Trabajo Social</option>
                                                    </optgroup>
                                            
                                                    <optgroup label="Escuela de Estadística Especializada">
                                                        <option value="Estadística Especializada">Maestría en Estadística Especializada</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        <div class="mb-2">
                                            <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlServiceCoordinador" formControlName="ctrlServiceCoordinador" [(ngModel)]="service.coordinador"   required>
                                            <option class="ms-2" value="" disabled selected>Seleccionar coordinador</option>
                                            <option value="Patria Fernandez">Patria Fernandez</option>
                                            <option value="Mariel García">Mariel García</option>
                                            <option value="María de Lourdes Sanchez">María de Lourdes Sanchez</option>
                                            <option value="Cesar Díaz">Cesar Díaz</option>
                                            <option value="Amin Martinez">Amin Martinez</option>
                                            <option value="Carmen Luisa Santana">Carmen Luisa Santana</option>
                                            <option value="Nancy Alonso">Nancy Alonso</option>
                                            <option value="Giselle Duverge">Giselle Duverge</option>
                                            <option value="Jose Crespo">Jose Crespo</option>
                                            <option value="Joaquin de la Rosa">Joaquin de la Rosa</option>
                                            <option value="Karina Abad">Karina Abad</option>
                                            <option value="Aurea Pelletier">Aurea Pelletier</option>
                                            <option value="Nancy Sanchez">Nancy Sanchez</option>
                                            <option value="Jossira Santana Cedano">Jossira Santana Cedano</option>
                                            <option value="Manuel Mercedes">Manuel Mercedes</option>
                                            <option value="Giovanni Matos Subervi">Giovanni Matos Subervi</option>
                                            <option value="Guarionaex Gomez">Guarionaex Gomez</option>
                                            <option value="Marianela Fernandez">Marianela Fernandez</option>
                                            <option value="Wagner Gomera">Wagner Gomera</option>
                                            <option value="Xiomara Rodriguez">Xiomara Rodriguez</option>
                                            <option value="Danilo Montero">Danilo Montero</option>
                                            <option value="Sorivel Cristina Perez B.">Sorivel Cristina Perez B.</option>
                                            <option value="Ydania Figuereo">Ydania Figuereo</option>
                                            <option value="Leonte Castillo">Leonte Castillo</option>
                                            <option value="Andry Garcia">Andry Garcia</option>
                                            <option value="Marcia Yaquelin Andujar">Marcia Yaquelin Andujar</option>
                                            <option value="Franklin Fernandez">Cesar Díaz</option>
                                            <option value="Franklin Fernandez">Maria Lourdes Sanchez</option>
                                            <option value="Franklin Fernandez">Sagrario Matos</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                        </div>
                                        <div class="mb-2">
                                            <select class="form-control form-select mb-4 rounded-4 border border-dark border-1" style="padding: 10px 0px 10px 0px;padding: 10px 0px 10px 50px;font-size: 17px;line-height: 30px;display: block;/*! width: 112%; */outline: 0;border-radius: 30px;color: #2a3341;"  tabindex="-1" name="ctrlServicePromocion" formControlName="ctrlServicePromocion" [(ngModel)]="service.promocion"   required>
                                                <option class="ms-2" value="" disabled selected>Seleccionar promoción</option>
                                                <option value="2023-2024">2024-2026</option>
                                                <option value="2023-2024">2023-2025</option>
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <textarea id="observaciones" class="rounded-3" name="ctrlServiceObserv" formControlName="ctrlServiceObserv"  [(ngModel)]="service.observ" rows="4" cols="30" placeholder="  Observaciones"></textarea>
                                        </div>
                                        <div class="col-md-6 mx-auto">
                                            
                                            <button type="submit" (click)="onSubmit();f.form.valid;"  [disabled]="isSubmitting" class="btn btn-primary">Finalizar</button>
                                        </div>    
                                        </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </form>
                    <div class="alert alert-danger text-center text-sm fw-bold text-white"  *ngIf="isFormFailed">{{errorMessage}}</div>
                    <div class="alert alert-success text-center text-sm fw-bold  text-dark" *ngIf="isFormSuccess">{{successMessage}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-8 px-0 pt-3">  
            <div class="row mx-0">
                <div class="col d-flex flex-column align-items-start gap-2">
                  <h3 class="fw-semibold fs-6" style="color:#F47B20">Conoce todo lo que tenemos a tu disposición </h3>
                  <h2 class="text-dark text-start fw-bold">Servicios</h2>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col d-flex flex-column align-items-start gap-2 p-3">
                  <div class="m-0 border-0">
            
                <ul class="list-unstyled">
                  <li class="text-muted">Instrucciones para completar la solicitud de servicio:
                    <ul class="mt-5">
                      <li> Lee cuidadosamente antes de comenzar a llenar el formulario.</li>
                      <li> Completa todos los campos  proporciona la información solicitada de manera precisa y completa.</li>
                      <li> Una vez completada la solicitud, revisa minuciosamente todos los detalles antes de enviarla para asegurarte de que esté correcta.</li>
                      <li> Luego de enviarla te llegara un correo de confirmación  que te indicara tu numero de solicitud, con ese numero, podrás darle seguimiento a la misma.</li>
                      <li> Tan pronto este listo, te estaremos informando via correo para indicarle donde puede retirar en físico, en caso de que asi lo requiera. En caso contrario, estarás recibiendo el servicio en el correo indicado en el formulario. </li>
                    </ul>
                  </li>
                </ul>
                </div>
                </div>
              </div>
        </div>
    </div>
    <div class="content pt-0 mt-0">
        <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
            <div class="col d-flex flex-column align-items-start gap-2 p-5">
                <h3 class="fw-semibold fs-6" style="color:#F47B20">Todo lo que necesitas a tu alcance</h3>
                  <h4 class="text-dark text-start fw-bold">Otros servicios</h4>
                  <div class="row row-cols-1 row-cols-md-2 text-center">
                    <div class="col list-group">
                        <a href="/admisiones">
                            <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem;">
                                <svg width="30" height="30" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0625 22.6875C31.6452 22.6875 28.875 25.4577 28.875 28.875C28.875 32.2923 31.6452 35.0625 35.0625 35.0625C38.4798 35.0625 41.25 32.2923 41.25 28.875C41.25 25.4577 38.4798 22.6875 35.0625 22.6875ZM24.75 28.875C24.75 23.1796 29.3671 18.5625 35.0625 18.5625C40.7579 18.5625 45.375 23.1796 45.375 28.875C45.375 34.5704 40.7579 39.1875 35.0625 39.1875C29.3671 39.1875 24.75 34.5704 24.75 28.875Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 27.8438C6.1875 26.7047 7.11091 25.7812 8.25 25.7812H14.4375C15.5766 25.7812 16.5 26.7047 16.5 27.8438C16.5 28.9828 15.5766 29.9062 14.4375 29.9062H8.25C7.11091 29.9062 6.1875 28.9828 6.1875 27.8438Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 17.5312C6.1875 16.3922 7.11091 15.4688 8.25 15.4688H14.4375C15.5766 15.4688 16.5 16.3922 16.5 17.5312C16.5 18.6703 15.5766 19.5938 14.4375 19.5938H8.25C7.11091 19.5938 6.1875 18.6703 6.1875 17.5312Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 38.1562C6.1875 37.0172 7.11091 36.0938 8.25 36.0938H14.4375C15.5766 36.0938 16.5 37.0172 16.5 38.1562C16.5 39.2953 15.5766 40.2188 14.4375 40.2188H8.25C7.11091 40.2188 6.1875 39.2953 6.1875 38.1562Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 48.4688C6.1875 47.3297 7.11091 46.4062 8.25 46.4062H14.4375C15.5766 46.4062 16.5 47.3297 16.5 48.4688C16.5 49.6078 15.5766 50.5312 14.4375 50.5312H8.25C7.11091 50.5312 6.1875 49.6078 6.1875 48.4688Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0626 39.1875C32.9814 39.1875 30.9287 39.6721 29.0672 40.6028C27.2056 41.5336 25.5864 42.885 24.3376 44.55C23.6542 45.4613 22.3614 45.646 21.4501 44.9625C20.5389 44.279 20.3542 42.9863 21.0376 42.075C22.6706 39.8977 24.7881 38.1305 27.2224 36.9133C29.6567 35.6962 32.341 35.0625 35.0626 35.0625C37.7843 35.0625 40.4685 35.6962 42.9028 36.9133C45.3371 38.1305 47.4546 39.8977 49.0876 42.075C49.7711 42.9863 49.5864 44.279 48.6751 44.9625C47.7639 45.646 46.4711 45.4613 45.7876 44.55C44.5389 42.885 42.9196 41.5336 41.0581 40.6028C39.1965 39.6721 37.1439 39.1875 35.0626 39.1875Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.625 6.1875C55.9032 6.1875 57.75 8.03433 57.75 10.3125V55.6875C57.75 57.9657 55.9032 59.8125 53.625 59.8125H16.5C14.2218 59.8125 12.375 57.9657 12.375 55.6875V10.3125C12.375 8.03433 14.2218 6.1875 16.5 6.1875L53.625 6.1875ZM53.625 55.6875V10.3125L16.5 10.3125V55.6875H53.625Z" fill="white"/>
                                </svg>   
                                <div>
                                    <p class="text-sm text-white mb-0 ">Formulario de Admisión</p>
                                </div>
                            </div>
                        </a>
                        <a href="/servicios">
                            <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                                <svg width="30" height="30" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1562 15.25C17.1562 14.1972 18.0097 13.3438 19.0625 13.3438H41.9375C42.9903 13.3438 43.8438 14.1972 43.8438 15.25V26.6875C43.8438 27.7403 42.9903 28.5938 41.9375 28.5938H19.0625C18.0097 28.5938 17.1562 27.7403 17.1562 26.6875V15.25ZM20.9688 17.1562V24.7812H40.0312V17.1562H20.9688Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6562 5.71875C49.7618 5.71875 51.4688 7.42566 51.4688 9.53125V51.4688C51.4688 53.5743 49.7618 55.2812 47.6562 55.2812H13.3438C11.2382 55.2812 9.53125 53.5743 9.53125 51.4688V9.53125C9.53125 7.42566 11.2382 5.71875 13.3438 5.71875L47.6562 5.71875ZM47.6562 51.4688V9.53125L13.3438 9.53125V51.4688H47.6562Z" fill="white"/>
                                    <path d="M20.9688 38.125C22.5479 38.125 23.8281 36.8448 23.8281 35.2656C23.8281 33.6864 22.5479 32.4062 20.9688 32.4062C19.3896 32.4062 18.1094 33.6864 18.1094 35.2656C18.1094 36.8448 19.3896 38.125 20.9688 38.125Z" fill="white"/>
                                    <path d="M30.5 38.125C32.0792 38.125 33.3594 36.8448 33.3594 35.2656C33.3594 33.6864 32.0792 32.4062 30.5 32.4062C28.9208 32.4062 27.6406 33.6864 27.6406 35.2656C27.6406 36.8448 28.9208 38.125 30.5 38.125Z" fill="white"/>
                                    <path d="M40.0312 38.125C41.6104 38.125 42.8906 36.8448 42.8906 35.2656C42.8906 33.6864 41.6104 32.4062 40.0312 32.4062C38.4521 32.4062 37.1719 33.6864 37.1719 35.2656C37.1719 36.8448 38.4521 38.125 40.0312 38.125Z" fill="white"/>
                                    <path d="M20.9688 47.6562C22.5479 47.6562 23.8281 46.3761 23.8281 44.7969C23.8281 43.2177 22.5479 41.9375 20.9688 41.9375C19.3896 41.9375 18.1094 43.2177 18.1094 44.7969C18.1094 46.3761 19.3896 47.6562 20.9688 47.6562Z" fill="white"/>
                                    <path d="M30.5 47.6562C32.0792 47.6562 33.3594 46.3761 33.3594 44.7969C33.3594 43.2177 32.0792 41.9375 30.5 41.9375C28.9208 41.9375 27.6406 43.2177 27.6406 44.7969C27.6406 46.3761 28.9208 47.6562 30.5 47.6562Z" fill="white"/>
                                    <path d="M40.0312 47.6562C41.6104 47.6562 42.8906 46.3761 42.8906 44.7969C42.8906 43.2177 41.6104 41.9375 40.0312 41.9375C38.4521 41.9375 37.1719 43.2177 37.1719 44.7969C37.1719 46.3761 38.4521 47.6562 40.0312 47.6562Z" fill="white"/>
                                </svg>
                                <div>
                                    <p class="text-sm text-white mb-0 ">Solicitud de cotización</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col list-group">
                        <a href="https://forms.gle/s2DXW7dH4vF9xNuX7">
                            <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #FE7948;flex-direction: column;align-items: center; height: 10rem;">
                                <svg width="30" height="30" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0625 22.6875C31.6452 22.6875 28.875 25.4577 28.875 28.875C28.875 32.2923 31.6452 35.0625 35.0625 35.0625C38.4798 35.0625 41.25 32.2923 41.25 28.875C41.25 25.4577 38.4798 22.6875 35.0625 22.6875ZM24.75 28.875C24.75 23.1796 29.3671 18.5625 35.0625 18.5625C40.7579 18.5625 45.375 23.1796 45.375 28.875C45.375 34.5704 40.7579 39.1875 35.0625 39.1875C29.3671 39.1875 24.75 34.5704 24.75 28.875Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 27.8438C6.1875 26.7047 7.11091 25.7812 8.25 25.7812H14.4375C15.5766 25.7812 16.5 26.7047 16.5 27.8438C16.5 28.9828 15.5766 29.9062 14.4375 29.9062H8.25C7.11091 29.9062 6.1875 28.9828 6.1875 27.8438Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 17.5312C6.1875 16.3922 7.11091 15.4688 8.25 15.4688H14.4375C15.5766 15.4688 16.5 16.3922 16.5 17.5312C16.5 18.6703 15.5766 19.5938 14.4375 19.5938H8.25C7.11091 19.5938 6.1875 18.6703 6.1875 17.5312Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 38.1562C6.1875 37.0172 7.11091 36.0938 8.25 36.0938H14.4375C15.5766 36.0938 16.5 37.0172 16.5 38.1562C16.5 39.2953 15.5766 40.2188 14.4375 40.2188H8.25C7.11091 40.2188 6.1875 39.2953 6.1875 38.1562Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1875 48.4688C6.1875 47.3297 7.11091 46.4062 8.25 46.4062H14.4375C15.5766 46.4062 16.5 47.3297 16.5 48.4688C16.5 49.6078 15.5766 50.5312 14.4375 50.5312H8.25C7.11091 50.5312 6.1875 49.6078 6.1875 48.4688Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0626 39.1875C32.9814 39.1875 30.9287 39.6721 29.0672 40.6028C27.2056 41.5336 25.5864 42.885 24.3376 44.55C23.6542 45.4613 22.3614 45.646 21.4501 44.9625C20.5389 44.279 20.3542 42.9863 21.0376 42.075C22.6706 39.8977 24.7881 38.1305 27.2224 36.9133C29.6567 35.6962 32.341 35.0625 35.0626 35.0625C37.7843 35.0625 40.4685 35.6962 42.9028 36.9133C45.3371 38.1305 47.4546 39.8977 49.0876 42.075C49.7711 42.9863 49.5864 44.279 48.6751 44.9625C47.7639 45.646 46.4711 45.4613 45.7876 44.55C44.5389 42.885 42.9196 41.5336 41.0581 40.6028C39.1965 39.6721 37.1439 39.1875 35.0626 39.1875Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.625 6.1875C55.9032 6.1875 57.75 8.03433 57.75 10.3125V55.6875C57.75 57.9657 55.9032 59.8125 53.625 59.8125H16.5C14.2218 59.8125 12.375 57.9657 12.375 55.6875V10.3125C12.375 8.03433 14.2218 6.1875 16.5 6.1875L53.625 6.1875ZM53.625 55.6875V10.3125L16.5 10.3125V55.6875H53.625Z" fill="white"/>
                                </svg>   
                                <div>
                                    <p class="text-sm text-white mb-0 ">Solicitud de Factura</p>
                                </div>
                            </div>
                        </a>
                        <a href="https://soft.uasd.edu.do/UASDVirtualGateway/">
                            <div class="d-flex gap-2 mb-0 mt-2 rounded-4 p-4" style="background-color: #F47B20;flex-direction: column;align-items: center; height: 10rem;">
                                <svg width="30" height="30" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0588 6.23529C31.6471 5.92157 32.3529 5.92157 32.9412 6.23529L62.9412 22.2353C63.5929 22.5829 64 23.2614 64 24C64 24.7386 63.5929 25.4171 62.9412 25.7647L32.9412 41.7647C32.3529 42.0784 31.6471 42.0784 31.0588 41.7647L1.05882 25.7647C0.407097 25.4171 0 24.7386 0 24C0 23.2614 0.407097 22.5829 1.05882 22.2353L31.0588 6.23529ZM6.25 24L32 37.7333L57.75 24L32 10.2667L6.25 24Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2357 23.0589C30.7555 22.0842 31.967 21.7155 32.9416 22.2353L47.9416 30.2353C48.5933 30.5829 49.0004 31.2614 49.0004 32V60C49.0004 61.1046 48.105 62 47.0004 62C45.8958 62 45.0004 61.1046 45.0004 60V33.2L31.0592 25.7647C30.0846 25.2449 29.7159 24.0335 30.2357 23.0589Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00002 25.725C10.1046 25.725 11 26.6204 11 27.725V41.3499L11.0044 41.3556L11.0043 41.3557C12.4875 43.3481 19.064 51 32 51C44.936 51 51.5125 43.3481 52.9958 41.3557L53 41.35V27.725C53 26.6204 53.8955 25.725 55 25.725C56.1046 25.725 57 26.6204 57 27.725V41.375L57 41.3834C56.9964 42.2393 56.7149 43.0707 56.198 43.7527C54.3205 46.2714 46.6976 55 32 55C17.3023 55 9.67943 46.2712 7.80197 43.7526C7.28515 43.0707 7.00365 42.2393 7.00004 41.3834L7 41.375H7.00002V27.725C7.00002 26.6204 7.89545 25.725 9.00002 25.725Z" fill="white"/>
                                </svg>
                                <div>
                                    <p class="text-sm text-white mb-0 ">UASD Virtual</p>
                                </div>
                            </div>
                        </a>
                    </div>
                  </div>
            </div>
            <div class="col">
              <img src="/assets/img/img-servicio-model-0.png" alt="" class="img-fluid">
            </div>
        </div>
        
        <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
            <div class="col d-flex flex-column align-items-start gap-2 p-5">
                  <div class="row row-cols-1 row-cols-md-2 text-center">
                    <div class="col list-group px-0">
                        <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-bottom" style="flex-direction: column;align-items: center; height: 15rem;">
                            <img src="/assets/icon/location.webp" width="52" height="52">
   
                        <div>
                        <p class="text-sm mb-0">Dirección</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">6to Piso, Torre Adm.</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">UASD, SEDE.</p>
                        </div>
                        </div>
                        <div class="d-flex gap-2 mb-0 p-4 border-dark border-end border-top" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                            <div class="d-flex gap-2 mb-0" style="flex-direction: column;align-items: center; height: 15rem; width: max-content; margin-right: 4rem;">
                                <img src="/assets/icon/reloj.webp" width="52" height="52"> 
                            <div>
                            <p class="text-sm mb-0">Horario de oficina</p>
                            <p class="text-sm mb-0" style="font-size: 0.7rem;">Días de semana: 8:00 – 7:00 p.m.</p>
                            <p class="text-sm mb-0" style="font-size: 0.7rem;">Sábados y Domingos: Cerrado</p>
                            </div> 
                            </div>
                        </div>
                    </div>
                    <div class="col list-group">
                        <div class="d-flex gap-2 mb-0 border-dark border-start border-bottom" style="flex-direction: column;align-items: center; height: 15rem; justify-content: center;">
                            <div class="d-flex gap-2 mb-0 p-4" style="flex-direction: column;align-items: center; height: 15rem;">
                            <img src="/assets/icon/mail.webp" width="52" height="52">                                                              
                            <div>
                            <p class="text-sm mb-0">Email</p>
                            <p class="text-sm mb-0" style="font-size: 0.7rem;">postgradofces&#64;uasd.edu.do</p>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex gap-2 mb-0 p-4 border-dark border-start border-top" style="flex-direction: column;align-items: center; height: 15rem;">
                            <img src="/assets/icon/phone.webp" width="52" height="52">
                        <div>
                        <p class="text-sm mb-0">Teléfono</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">(809) 233-3333</p>
                        <p class="text-sm mb-0" style="font-size: 0.7rem;">(809) 754-6985</p>
                        </div>
                        </div>
                    </div>
                  </div>
            </div>
            <div class="col">
                <h3 class="fw-semibold fs-6 text-start" style="color:#F47B20">Necesitas otra informacion</h3>
                <h3 class="text-dark text-start fw-bold">Contáctanos, ¡Estamos aquí!</h3>
                <form class="mx-auto" novalidate>
                    <div>
                       
                                <div>
                                <div class="mb-2 d-flex">
                                    <input matInput type="text"  class="me-1 p-2 fs-6 form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Nombre Completo" required>
                                    <input matInput type="text"  class="p-2 fs-6 form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Correo Electrónico" required>
                                </div>
                                <div class="mb-2 d-flex">
                                    <input matInput type="text"  style="font-size: 0.9rem;" class=" me-1 p-2 fs-6 form-control rounded-4 border border-dark border-1" placeholder="Numero de contacto" required>
                                    <input matInput type="text"  style="font-size: 0.9rem;" class="p-2 fs-6 form-control rounded-4 border border-dark border-1" placeholder="Tema" required>
                                </div>
                                <div class="mb-2">
                                    <textarea class="form-control rounded-4 border border-dark border-1" style="font-size: 0.9rem;" placeholder="Escribe tu mensaje aquí" id="floatingTextarea2" style="height: 100px"></textarea>
                                  </div>                              
                                <div class="col-md-6 mx-auto">
                                    <button type="button" class="btn btn-primary">Enviar</button>
                                    <div class="flex">
                                        <p class="text-danger" style="font-size: 0.6rem;">*No disponible</p>
                                    </div>
                                </div>
                                </div>
                     
                    </div>
                </form>

                
            </div>
        </div>


    </div>
    <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col-md-4 py-4 me-3 rounded-3 elColorCard cardEfect">
            <div class="d-flex" style="align-items: center;">
                <div class="rounded-circle" style="background-color: #AFAFAF; width: 10rem;height: 10rem;">
                </div>
                <div class="ms-4" style="text-align: left; font-size: 0.9rem;">
                    <h4>Karla Martinez</h4>
                    <p class="mb-2" style="font-size: 1.0rem;color: #F47B20;">Estudiante</p>
                    <p>Me encanto tomar clases,</p>
                    <p>aprendí mucho y me</p>
                    <p>sirvió en mi trabajo para</p>
                    <p>elevar de posición.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 py-4 me-3 rounded-3 elColorCard cardEfect">
            <div class="d-flex" style="align-items: center;">
                <div class="rounded-circle" style="background-color: #AFAFAF; width: 10rem;height: 10rem;">
                </div>
                <div class="ms-4" style="text-align: left;font-size: 0.9rem;">
                    <h4>Juan Pérez</h4>
                    <p class="mb-2" style="font-size: 1.0rem; color: #F47B20;">Estudiante</p>
                    <p>Me encanto tomar clases,</p>
                    <p>aprendí mucho y me</p>
                    <p>sirvió en mi trabajo para</p>
                    <p>elevar de posición.</p>
                </div>
            </div>
        </div>
    </div>
</div>
